import { Flex, GridItem, SimpleGrid, Image, Text, Spinner, Tabs, TabList, Tab, TabPanel, TabPanels, Stack, Tooltip, Icon } from "@chakra-ui/react";
import { SearchInput } from "../../../common";
import { useEffect, useState } from "react";
import { useDragBackground } from "../../../../states/useDragBackground";
import { useBackgroundStore } from "../../../../states/useBackgroundStore";
import { usePageStore } from "../../../../states/usePageStore";
import { UploadPhotoButton } from "../common";
import { usePhotoStore } from "../../../../states/usePhotoStore";
import { EmptyPhotosPlaceholder } from "../common";
import { DeletePhotoButton } from "../common/DeletePhotoButton";
import { MdOutlineWarningAmber } from "react-icons/md";

const SAMPLE_QUERIES = ['Mountain', 'Sunset', 'Ocean', 'Space', 'City', 'Nature', 'Family', 'People', 'Outdoor', 'Celebration', 'Birthday', 'Workplace'];

export const Backgrounds = ({ display }) => {
    const backgroundStore = useBackgroundStore((state) => state);
    const dragBackground = useDragBackground((state) => state);
    const pageStore = usePageStore((state) => state);
    const photoStore = usePhotoStore((state) => state);
    const [hoveringPhotoIndex, setHoveringPhotoIndex] = useState(-1);
    const [lowResPhotos, setLowResPhotos] = useState([]);

    const [failedSearch, setFailedSearch] = useState(false);

    const onSearch = (event) => backgroundStore.setQuery(event.target.value);

    const fetchBackgrounds = (query) => {
        let apiEndpoint = `${process.env.REACT_APP_PEXELS_URL}/search?per_page=10`;
        let apiQuery = backgroundStore.query;
        if (query) {
            apiQuery = query;
        }
        if (apiQuery) {
            apiEndpoint += `&query=${apiQuery}`;
            fetch(apiEndpoint, {
                method: 'GET',
                headers: {
                    'Authorization': process.env.REACT_APP_PEXELS_API_KEY
                }
            })
            .then((response) => response.json())
            .then((data) => {
                backgroundStore.setBackgrounds(data.photos.map((photo) => {
                    return {
                        thumbnail: photo.src.medium,
                        original: photo.src.large2x
                    };
                }));

                backgroundStore.setIsSearching(false);
                backgroundStore.setLastQuery(apiQuery)
            })
            .catch(() => {
                setFailedSearch(true);
                backgroundStore.setIsSearching(false);
            });
        } else {
            backgroundStore.setBackgrounds([]);
            backgroundStore.setIsSearching(false);
        }
    }

    const isLowRes = (src) => {
        return lowResPhotos.includes(src);
    }

    useEffect(() => {
        if (backgroundStore.query === '') {
            backgroundStore.setIsSearching(true);
            setFailedSearch(false);
            const randomQuery = SAMPLE_QUERIES[Math.floor(Math.random() * SAMPLE_QUERIES.length)];
            fetchBackgrounds(randomQuery);
        }
    }, []);

    useEffect(() => {
        if (backgroundStore.query !== backgroundStore.lastQuery) {


            const getData = setTimeout(() => {
                fetchBackgrounds();
            }, 250)
        
            return () => clearTimeout(getData);
        }
    }, [backgroundStore.query]);

    return (
        <Tabs 
            display={display === 'flex' ? 'block' : display}
            py="16px" 
            isFitted
            variant='unstyled'
            marginBottom={"250px"}
        >
            <TabList border={"1px solid #2F2F2F"} fontFamily={"Quicksand, sans-serif"}>
                <Tab _selected={{ color: 'white', bg: '#2F2F2F' }}>Search</Tab>
                <Tab _selected={{ color: 'white', bg: '#2F2F2F' }}>Upload</Tab>
            </TabList>
            <TabPanels>
                <TabPanel px="0px">
                    <Flex
                        w="full"
                        justify={"flex-start"}
                        flexDirection={"column"}
                        gap="10px"
                        display={display}
                    >

                        {pageStore.layoutType === 'photobook' && (
                            <Flex
                                w="full"
                                justify={"flex-start"}
                                borderColor={"wps.lightgray"}
                                flexDirection={"column"}
                                gap="16px"
                            >
                                <SearchInput placeholder="Search for Backgrounds" value={backgroundStore.query} onChange={onSearch} />

                                <Text
                                    fontSize={"16px"}
                                    fontWeight={"700"}
                                    color="wps.buttongray">
                                        Results
                                </Text>

                                {backgroundStore.isSearching ? (

                                    <Flex 
                                        style={{ height: '100%'}}
                                        alignItems={"center"}
                                        justify={"center"}
                                        >
                                        <Spinner size="xl" />
                                    </Flex>
                                ) : (
                                    backgroundStore.backgrounds.length > 0 ? (
                                        <SimpleGrid columns={2} gap={2}>
                                            {backgroundStore.backgrounds?.map((photo, i) => (
                                                <GridItem key={i}>
                                                    <button>
                                                        <Image
                                                            src={photo.thumbnail}
                                                            objectFit={"cover"}
                                                            objectPosition={"center"}
                                                            h="120px"
                                                            w="120px"
                                                            onMouseDown={() => {
                                                                dragBackground.setDraggedBackground(photo.original)
                                                            }}
                                                            onDoubleClick={() => {
                                                                if (pageStore.spreadSelectedIndex !== null) {
                                                                    pageStore.setBackground(photo.original);
                                                                }

                                                                dragBackground.setDraggedBackground(null);
                                                            }}
                                                            cursor="pointer"
                                                        />
                                                    </button>
                                                </GridItem>
                                            ))}
                                        </SimpleGrid>
                                    ) : (
                                        <Text color="wps.medgray" textAlign={"center"}>
                                            {failedSearch ? 'Server error while searching. Please try again later.' : 'No results yet. Input search query for backgrounds.'}
                                        </Text>
                                    )
                                )}
                            </Flex>
                        )}

                        {pageStore.layoutType !== 'photobook' && (
                            <Text color="wps.medgray" textAlign={"center"}>
                                Only available for Photo Safe
                            </Text>
                        )}
                    </Flex>
                </TabPanel>
                <TabPanel px="0px">
                <Flex
                    w="full"
                    justify={"flex-start"}
                    flexDirection={"column"}
                    gap="16px"
                    h="full"
                    display={display}
                >
                    <UploadPhotoButton />
                    {photoStore.visiblePhotos.length > 0 ? (
                        <SimpleGrid columns={2} gap={2}>
                            {photoStore.visiblePhotos?.map((photo, i) => (
                                <GridItem key={photo.src} position={"relative"}>
                                    <Stack position={"relative"}>
                                        <button>
                                            <Image
                                                src={photo.src}
                                                objectFit={"cover"}
                                                objectPosition={"center"}
                                                h="120px"
                                                w="120px"
                                                onMouseDown={() => {
                                                    dragBackground.setDraggedBackground(photo.originalSrc)
                                                }}
                                                onMouseEnter={() => {
                                                    setHoveringPhotoIndex(i);
                                                }}
                                                onMouseLeave={() => {
                                                    setHoveringPhotoIndex(-1)
                                                }}
                                                onLoad={(event) => {
                                                    const height = event.target.naturalHeight;
                                                    const width = event.target.naturalWidth;

                                                    if (width <= 250 || height <= 250) {
                                                        setLowResPhotos([...lowResPhotos, photo.src])
                                                    }
                                                }} 
                                            />
                                        </button>

                                        { hoveringPhotoIndex === i && (
                                            <DeletePhotoButton i={i} file={photo.s3FileName} setHoveringPhotoIndex={setHoveringPhotoIndex} />
                                        )}

                                        {isLowRes(photo.src) && (
                                            <Tooltip 
                                                label="This image has a low resolution. Please ensure to use high resolution photos for better printing quality."
                                                bg="wps.darkgray"
                                            >
                                                    
                                            <div
                                                style={{
                                                    width: `30px`,
                                                    height: `30px`,
                                                    borderRadius: '100%',
                                                    position: "absolute",
                                                    bottom: "5px",
                                                    right: "0px",
                                                    cursor: "help"
                                                }}
                                            >
                                                    <Icon 
                                                        as={MdOutlineWarningAmber} 
                                                        boxSize={"25px"} 
                                                        margin={"0"} 
                                                        color={"#DDDEDF"}
                                                        style={{
                                                            margin: "5px 0px 0px 0px"
                                                        }} 
                                                    />
                                            </div>
                                            </Tooltip>
                                        )}
                                    </Stack>
                                </GridItem>
                            ))}
                        </SimpleGrid>
                    ) : (
                        <EmptyPhotosPlaceholder />
                    )}
                </Flex>
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};
