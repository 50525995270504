import { create } from "zustand";

const INITIAL_ZOOM = {
    photobook: 40,
    decoPrint: 50,
    photoPrint: 50
};

export const useZoomStore = create((set) => {
    return {
        zoom: INITIAL_ZOOM.photobook,
        setInitialZoom: (layoutType) => set(() => ({ zoom: INITIAL_ZOOM[layoutType] })),
        incrementZoom: () =>
            set((state) => ({
                zoom: state.zoom === 200 ? state.zoom : state.zoom + 5,
            })),
        decrementZoom: () =>
            set((state) => ({ zoom: state.zoom > 5 ? state.zoom - 5 : 5 })),
    }
});
