import { Button, Tooltip } from "@chakra-ui/react";

export const DarkButton = ({ text, onClick, isDisabled, tooltip }) => {
    return (
        <Tooltip label={tooltip} >
            <Button
                bg="wps.darkgray"
                color="wps.white"
                w="full"
                fontSize={"13px"}
                fontWeight={"medium"}
                borderRadius={0}
                _hover={{}}
                _active={{}}
                px="30px"
                py="8px"
                onClick={onClick}
                isDisabled={isDisabled}
            >
                {text}
            </Button>
        </Tooltip>
    );
};
