import { create } from "zustand";

export const useBackgroundStore = create((set) => ({
    backgrounds: [],
    setBackgrounds: (backgrounds) => set(() => ({backgrounds: backgrounds})),
    query: '',
    setQuery: (query) => set(() => ({query: query})),
    lastQuery: '',
    setLastQuery: (lastQuery) => set(() => ({lastQuery: lastQuery})),
    isSearching: false,
    setIsSearching: (isSearching) => set(() => ({isSearching: isSearching})),
}));
