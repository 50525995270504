import { useEffect, useRef, useState } from "react";
import { useZoomStore } from "../../states/useZoomStore";
import { usePageStore } from "../../states/usePageStore";
import { CONSTANTS } from "../../configs/constants";
// import ContentEditable from 'react-contenteditable'
import ContentEditable from './content-editable';

export const EditableText = ({ text, margins, canvasHeight, spreadIndex, textIndex, outlineSize }) => {
    const zoomStore = useZoomStore((state) => state);
    const pageStore = usePageStore((state) => state);

    const [textData, setTextData] = useState(text.text);
    const [isFocused, setIsFocused] = useState(false);
    const [hasChangedFont, setHasChangedFont] = useState(false);
    const contentEditableRef = useRef();
    const editableTextRef = useRef();

    useEffect(() => {
        if (!hasChangedFont) {
            setHasChangedFont(true);
        }
    }, [pageStore.data.spreads[spreadIndex]?.texts[textIndex]?.fontSize])

    useEffect(() => {
        setTextData(pageStore.data.spreads[spreadIndex]?.texts[textIndex]?.text)
    }, [pageStore.data.spreads[spreadIndex]?.texts[textIndex]?.text])
  

    let saveTextTimeout;
    let timeout;

    const saveText = () => {
        if (saveTextTimeout) {
            clearTimeout(saveTextTimeout);
        }

        saveTextTimeout = setTimeout(() => {
            const element = contentEditableRef.current;
            if (element) {
                const text = pageStore.data.spreads[spreadIndex].texts[textIndex];

                const x = element.offsetLeft - margins.left;
                const y =
                    element.offsetTop -
                    margins.top -
                    (canvasHeight + CONSTANTS.CANVAS_SPACING) * spreadIndex;

                if (
                    text.x !== x ||
                    text.y !== y
                ) {
                    pageStore.setText(
                        spreadIndex,
                        textIndex,
                        x,
                        y
                    );
                }
            }
        }, 10);
    }

    const handleChange = evt => {
        setTextData(evt.target.value);
    };
    
    const handleBlur = () => {
        const data = contentEditableRef.current.innerHTML;
        pageStore.setTextValue(data, spreadIndex, textIndex);
        pageStore.setTextWidthHeight(spreadIndex, textIndex, contentEditableRef.current.offsetWidth, contentEditableRef.current.offsetHeight);
        setIsFocused(false);
    };


    const handleDrag = (event) => {
        event = event || window.event;
        event.preventDefault();

        let pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;

        pos3 = event.clientX;
        pos4 = event.clientY;

        const element = contentEditableRef.current;

        document.onmousemove = (e) => {
            e = e || window.event;
            e.preventDefault();

            const newPos1 = pos3 - e.clientX;
            const newPos2 = pos4 - e.clientY;
            const newPos3 = e.clientX;
            const newPos4 = e.clientY;

            const scaleFactor = 1 / (zoomStore.zoom / 100);

            pos2 = Math.round(newPos2 * scaleFactor);
            pos4 = newPos4;
            const newTop = (element.offsetTop - pos2);
            element.style.top = `${newTop}px`;
            
            pos1 = Math.round(newPos1 * scaleFactor);
            pos3 = newPos3;
            const newLeft = element.offsetLeft - pos1;
            element.style.left = `${newLeft}px`;
        }

        document.onmouseup = (e) => {
            document.onmouseup = null;
            document.onmousemove = null;

            saveText();
        };

        document.ontouchend = (e) => {
            document.ontouchend = null;
            document.ontouchmove = null;
            document.onmousemove = null;
            document.querySelector("#workspace").style.overflow = "scroll";
            document.body.style.overflow = "scroll";
        };
    };

    const handleBoxSelect = () => {
        pageStore.setBoxSelectedIndex(null);
        pageStore.setTextSelectedIndex(textIndex);
        pageStore.setTextSelectedRef(contentEditableRef);

        if (pageStore.layoutType !== 'photobook') {
            pageStore.setSpreadSelectedIndex(0);
        }
    };

    const isHighlighted = () => {
        return pageStore.spreadSelectedIndex === spreadIndex && pageStore.textSelectedIndex === textIndex;
    };

    return (
        <ContentEditable style={{
                position: 'absolute',
                left: `${text.x + margins.left}px`,
                top: `${(text.y + margins.top + ((canvasHeight + CONSTANTS.CANVAS_SPACING) * spreadIndex) )}px`,
                fontSize: `${text.fontSize || 1}px`,
                minWidth: `${text.width}px`,
                textAlign: text.textAlign,
                outline: `${ isHighlighted() ? outlineSize / 2 + 'px solid' : "0px solid"}`,
                outlineColor: `${ isHighlighted() ? "#DDCCB2" : "wps.lightgray"}`,
                cursor: 'move',
                color: text.fontColor,
                zIndex: text.zIndex,
                fontWeight: text.fontWeight,
                fontStyle: text.fontStyle,
                fontFamily: text.fontFamily,
            }}
            disabled={false}
            innerRef={contentEditableRef}
            ref={editableTextRef}
            html={textData}
            onBlur={handleBlur}
            onChange={handleChange} 
            onMouseDown={(event) => {
                if (handleDrag) {
                    handleDrag(event);
                }

                if (!isFocused) {
                    event.preventDefault();
                    event.target.focus();

                    setIsFocused(true);

                    handleBoxSelect();
                }
            }}
            onKeyDown={(event) => {
                if (event.keyCode === 13) {
                    event.preventDefault();
                }
            }}
        />
    );
};