import {
    Button,
    Flex,
    Icon,
    Text,
    IconButton,
    Image,
    Spinner,
    Input,
    InputGroup
} from "@chakra-ui/react";
import { AiOutlineRotateLeft, AiOutlineRotateRight, AiOutlineZoomIn, AiOutlineZoomOut, AiOutlineClose, AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { IoSwapVertical, IoSwapHorizontal } from "react-icons/io5";
import { usePageStore } from "../../states/usePageStore";
import { usePhotoEditor } from "../../states/usePhotoEditor";
import { PhotoEditorSlider } from "./PhotoEditor/PhotoEditorSlider";
import CloseRightSidebar from "../../images/RightSidebar/CloseRightSidebar.svg";
import OpenRightSidebar from "../../images/RightSidebar/OpenRightSidebar.svg";
import { DarkButton } from "../LeftSidebar/TabDetails/common";
import { ColorPicker } from "./PhotoEditor/ColorPicker";
import { MdFlipToFront, MdFlipToBack } from "react-icons/md";
import { TextFormatters } from "../LeftSidebar/TabDetails/Text/TextFormatters";
import { Select } from "../common";
import { useEffect, useState } from "react";

const fonts = [
    'Allura',
    'Amatic SC',
    'Arial',
    'Brush Script MT',
    'Catamaran',
    'Cinzel Decorative',
    'Cookie',
    'Copperplate',
    'Courier New',
    'Garamond',
    'Georgia',
    'Great Vibes',
    'Helvetica',
    'Lobster',
    'Lucida Console',
    'Lucida Handwriting',
    'Monaco',
    'Montserrat',
    'Nanum Myeongjo',
    'Open Sans',
    'Oswald',
    'Pacifico',
    'Papyrus',
    'PT Sans',
    'Roboto',
    'Times New Roman',
    'Verdana'

    // 'The New Elegant Font',
];

export const RightSidebar = ({ showRightSidebar, setShowRightSidebar }) => {
    const pageStore = usePageStore((state) => state);
    const photoEditor = usePhotoEditor((state) => state);

    if (photoEditor.isEditMode && showRightSidebar) {
        return <PhotoEditingRightSidebar photoEditor={photoEditor} pageStore={pageStore} setShowRightSidebar={setShowRightSidebar} />
    } else if ((pageStore.spreadSelectedIndex !== null || pageStore.layoutType !== 'photobook') && showRightSidebar) {
        if (pageStore.textSelectedIndex !== null) {
            return <TextSelectedRightSidebar pageStore={pageStore} setShowRightSidebar={setShowRightSidebar} /> 
        } else if (pageStore.boxSelectedIndex !== null) {
            return <PhotoBoxSelectedRightSidebar pageStore={pageStore} setShowRightSidebar={setShowRightSidebar} />
        } else if (pageStore.spreadSelectedIndex !== null) {
            return <BackgroundSelectedRightSidebar photoEditor={photoEditor} pageStore={pageStore} setShowRightSidebar={setShowRightSidebar} />
        }

    } else if (showRightSidebar) {
        return <EmptyRightSidebar setShowRightSidebar={setShowRightSidebar} />
    } else {
        return <MinimizedRightSidebar setShowRightSidebar={setShowRightSidebar} />
    }
};

const EmptyRightSidebar = ({setShowRightSidebar}) => {
    return (
        <Flex
            flexDirection={"column"}
            w="full"
            h="calc(100vh - 72px)"
            p="8px"
            bg="wps.smoke"
            borderLeft="1px solid"
            borderRight="1px solid"
            borderColor="wps.lightgray"
            zIndex="1000"
            position="fixed"
            top="73px"
            right="0"
            width="270px"

        >
            <Button
                position={"absolute"}
                right="100%"
                top="50%"
                p={0}
                bg="unset"
                m={0}
                h="auto"
                minW="17px"
                marginRight={"-2px"}
                onClick={() => {
                    setShowRightSidebar(false)
                }}
                _hover={{}}
                _active={{}}
                zIndex={1001}
            >
                <Image src={CloseRightSidebar} />
            </Button>
        </Flex>
    );
};

const MinimizedRightSidebar = ({ setShowRightSidebar }) => {
    return (
        <Flex
            flexDirection={"column"}
            w="full"
            h="full"
            p="8px"
            bg="wps.smoke"
            borderLeft="1px solid"
            borderRight="1px solid"
            borderColor="wps.lightgray"
            zIndex="1000"
            width="36px"
            position="fixed"
            right="0"
        >
            <Button
                position={"absolute"}
                right="100%"
                top="50%"
                p={0}
                bg="unset"
                transform={"translateY(-50%)"}
                m={0}
                h="auto"
                minW="17px"
                marginRight={"-2px"}
                onClick={() => {
                    setShowRightSidebar(true)
                }}
                _hover={{}}
                _active={{}}
                zIndex={1001}
            >
                <Image src={OpenRightSidebar} />
            </Button>
        </Flex>
    );
};

const PhotoEditingRightSidebar = ({ photoEditor, pageStore, setShowRightSidebar }) => {
    let colorTimeout1;
    let colorTimeout2;
    let filterTimeout;

    const setFilter = () => {
        if (filterTimeout) clearTimeout(filterTimeout);
        filterTimeout = setTimeout(() => {
            photoEditor.setFilter();
        }, 10);
    }

    return (
        <Flex
            flexDirection={"column"}
            w="full"
            h="calc(100vh - 72px)"
            p="8px"
            bg="wps.smoke"
            borderLeft="1px solid"
            borderRight="1px solid"
            borderColor="wps.lightgray"
            zIndex="1000"
            position="fixed"
            top="73px"
            right="0"
            width="270px"
            overflow={"scroll"}
        >
            <Button
                position={"absolute"}
                right="100%"
                top="50%"
                p={0}
                bg="unset"
                m={0}
                h="auto"
                minW="17px"
                marginRight={"-2px"}
                onClick={() => {
                    setShowRightSidebar(false)
                }}
                _hover={{}}
                _active={{}}
                zIndex={1001}
            >
                <Image src={CloseRightSidebar} />
            </Button>
            <Flex flexDirection={"column"}>
                <Flex 
                    alignItems={"center"} 
                    justify={"flex-end"} 
                    borderBottom="1px solid"
                    borderColor={"wps.lightgray"}
                    pb="12px"
                >
                    <Text flexGrow={1} fontWeight={"bold"}>Photo Editing</Text>
                    <Icon
                        as={AiOutlineClose}
                        boxSize="16px"
                        onClick={() => {
                            photoEditor.exitEditMode();
                            setShowRightSidebar(false);
                        }}
                        cursor={"pointer"}
                    />
                </Flex>

                {!photoEditor.cropper?.ready ? 
                    <Flex justifyContent={"center"} alignItems={"center"} height="400px">
                        <Spinner size="xl"/> 
                    </Flex> :

                    <>
                        <Flex 
                            flexDirection={"column"} 
                            py="16px"
                        >
                            <PhotoEditorSlider 
                                label={'Brightness'}
                                onChange={(v) => {
                                    photoEditor.setBrightness(v);
                                    setFilter();
                                }}
                                defaultValue={100}
                                value={photoEditor.brightness}
                                min={0}
                                max={200}
                            />

                            <PhotoEditorSlider 
                                label={'Saturation'}
                                onChange={(v) => {
                                    photoEditor.setSaturation(v);
                                    setFilter();
                                }}
                                defaultValue={100}
                                value={photoEditor.saturation}
                                min={0}
                                max={200}
                            />

                            <PhotoEditorSlider 
                                label={'Contrast'}
                                onChange={(v) => {
                                    photoEditor.setContrast(v);
                                    setFilter();
                                }}
                                defaultValue={100}
                                value={photoEditor.contrast}
                                min={0}
                                max={200}
                            />

                            <PhotoEditorSlider 
                                label={'Opacity'}
                                onChange={(v) => {
                                    photoEditor.setOpacity(v);
                                    setFilter();
                                }}
                                defaultValue={100}
                                value={photoEditor.opacity}
                                min={0}
                                max={100}
                            />

                            <PhotoEditorSlider 
                                label={'Sepia'}
                                onChange={(v) => {
                                    photoEditor.setSepia(v);
                                    setFilter();
                                }}
                                defaultValue={0}
                                value={photoEditor.sepia}
                                min={0}
                                max={100}
                            />

                            <PhotoEditorSlider 
                                label={'Grayscale'}
                                onChange={(v) => {
                                    photoEditor.setGrayscale(v);
                                    setFilter();
                                }}
                                defaultValue={0}
                                value={photoEditor.grayscale}
                                min={0}
                                max={100}
                            />

                            <Flex flexDirection={"row"} marginBottom={'8px'} gap={'8px'} justifyContent={'center'}>
                                <PhotoEditorSlider 
                                    label={'Border Width'}
                                    onChange={(v) => {
                                        photoEditor.setBorderWidth(v);
                                        setFilter();
                                    }}
                                    defaultValue={0}
                                    value={photoEditor.borderWidth}
                                    min={0}
                                    max={20}
                                    unit={'px'}
                                    width={270}
                                />

                                <ColorPicker 
                                    value={photoEditor.borderColor}
                                    label=''
                                    onChange={(color) => {
                                        if (colorTimeout1) {
                                            clearTimeout(colorTimeout1);
                                        }

                                        colorTimeout1 = setTimeout(() => {
                                            photoEditor.setBorderColor(color);
                                            photoEditor.setFilter();
                                        }, 10);
                                    }}
                                />
                            </Flex>

                            {pageStore.layoutType === 'photobook'  && (
                                <Flex flexDirection={"row"} marginBottom={'8px'} gap={'8px'} justifyContent={'center'}>
                                    <PhotoEditorSlider 
                                        label={'Box Shadow'}
                                        onChange={(v) => {
                                            photoEditor.setBoxShadow(v, v);
                                            setFilter();
                                        }}
                                        defaultValue={0}
                                        value={photoEditor.boxShadow}
                                        min={0}
                                        max={20}
                                        unit={'px'}
                                        width={270}
                                    />

                                    <ColorPicker 
                                        value={photoEditor.boxShadowColor}
                                        label=''
                                        onChange={(color) => {
                                            if (colorTimeout2) {
                                                clearTimeout(colorTimeout2);
                                            }

                                            colorTimeout2 = setTimeout(() => {
                                                photoEditor.setBoxShadowColor(color);
                                                photoEditor.setFilter();
                                            }, 10);
                                        }}
                                    />
                                </Flex>)
                            }

                            { pageStore.layoutType === 'photobook' && 
                                <DarkButton text={"Apply to All"} onClick={() => {
                                    const filter = {
                                        brightness: photoEditor.brightness || photoEditor.brightness === 0 ? photoEditor.brightness : 100,
                                        saturation: photoEditor.saturation || photoEditor.saturation === 0? photoEditor.saturation : 100,
                                        contrast: photoEditor.contrast || photoEditor.contrast === 0 ? photoEditor.contrast : 100,
                                        opacity: photoEditor.opacity || photoEditor.opacity === 0 ? photoEditor.opacity : 100,
                                        sepia: photoEditor.sepia ? photoEditor.sepia : 0,
                                        grayscale: photoEditor.grayscale ? photoEditor.grayscale : 0,
                                        borderWidth: photoEditor.borderWidth ? photoEditor.borderWidth : 0,
                                        borderColor: photoEditor.borderColor ? photoEditor.borderColor : '#FFFFFF'
                                    }
                                    pageStore.applyFiltersToAll(filter);
                                }} />
                            }
                        </Flex>

                        <Flex alignItems={"center"} justify={"flex-end"} marginBottom={'8px'}>
                            <Text flexGrow={1} fontWeight={"bold"}>Transformation</Text>
                        </Flex>

                        <Flex flexDirection={"row"} marginBottom={'4px'} gap={'4px'} justifyContent={'center'}>
                            <IconButton
                                icon={<AiOutlineRotateLeft />}
                                cursor={"pointer"}
                                width={'100%'}
                                bg={"wps.lightgray"}
                                sx={{
                                    svg: {
                                        fill: "unset",
                                    },
                                }}
                                fontSize={"20px"}
                                onClick={photoEditor.rotateLeft}
                                _hover={{ bg: "wps.gray", fill: "wps.white" }}
                            />

                            <IconButton
                                icon={<AiOutlineRotateRight />}
                                cursor={"pointer"}
                                width={'100%'}
                                bg={"wps.lightgray"}
                                sx={{
                                    svg: {
                                        fill: "unset",
                                    },
                                }}
                                fontSize={"20px"}
                                onClick={photoEditor.rotateRight}
                                _hover={{ bg: "wps.gray", fill: "wps.white" }}
                            />
                        </Flex>

                        <Flex flexDirection={"row"} marginBottom={'4px'} gap={'4px'} justifyContent={'center'}>
                            <IconButton
                                icon={<IoSwapHorizontal />}
                                cursor={"pointer"}
                                width={'100%'}
                                bg={"wps.lightgray"}
                                sx={{
                                    svg: {
                                        fill: "unset",
                                    },
                                }}
                                fontSize={"20px"}
                                onClick={photoEditor.transformHorizontal}
                                _hover={{ bg: "wps.gray", fill: "wps.white" }}
                            />

                            <IconButton
                                icon={<IoSwapVertical />}
                                cursor={"pointer"}
                                width={'100%'}
                                bg={"wps.lightgray"}
                                sx={{
                                    svg: {
                                        fill: "unset",
                                    },
                                }}
                                fontSize={"20px"}
                                onClick={photoEditor.transformVertical}
                                _hover={{ bg: "wps.gray", fill: "wps.white" }}
                            />
                        </Flex>

                        <Flex flexDirection={"row"} marginBottom={'8px'} gap={'4px'} justifyContent={'center'}>
                            <IconButton
                                icon={<AiOutlineZoomIn />}
                                cursor={"pointer"}
                                width={'100%'}
                                bg={"wps.lightgray"}
                                sx={{
                                    svg: {
                                        fill: "unset",
                                    },
                                }}
                                fontSize={"20px"}
                                onClick={photoEditor.zoomIn}
                                _hover={{ bg: "wps.gray", fill: "wps.white" }}
                            />

                            <IconButton
                                icon={<AiOutlineZoomOut />}
                                cursor={"pointer"}
                                width={'100%'}
                                bg={"wps.lightgray"}
                                sx={{
                                    svg: {
                                        fill: "unset",
                                    },
                                }}
                                fontSize={"20px"}
                                onClick={photoEditor.zoomOut}
                                _hover={{ bg: "wps.gray", fill: "wps.white" }}
                            />
                        </Flex>

                        <Flex marginBottom="250px" flexDirection={"column"} marginTop={'8px'} gap={'8px'} justifyContent={'center'}>
                            <DarkButton text={"Done Editing"} onClick={() => {
                                photoEditor.exitEditMode();
                                pageStore.setIsEditingBackground(false);
                            }} />
                        </Flex>
                    </>
                }
            </Flex>
        </Flex>
    );
};

const PhotoBoxSelectedRightSidebar = ({ pageStore, setShowRightSidebar }) => {
    const hasValidPhotoToEdit = () => {
        let spreadIndex = pageStore.spreadSelectedIndex;
        const boxIndex = pageStore.boxSelectedIndex;

        if (pageStore.layoutType === 'photoPrint' || pageStore.layoutType === 'decoPrint') {
            spreadIndex = 0;
        }

        if (spreadIndex === null) return false;
        if (boxIndex === null) return false;
        if (pageStore.data.spreads == undefined || pageStore.data.spreads.length === 0) return false;
        if (pageStore.data.spreads[spreadIndex] == undefined || pageStore.data.spreads[spreadIndex].boxes == undefined || pageStore.data.spreads[spreadIndex].boxes.length === 0) return false;
        if (pageStore.data.spreads[spreadIndex].boxes[boxIndex] == undefined || pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo == undefined) return false;
        if (pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo.isSticker) return false;

        return true
    }

    const isStickerInDecoPhotoPrint = () => {
        const spreadIndex = 0;
        const boxIndex = pageStore.boxSelectedIndex;

        if (pageStore.layoutType === 'photobook') return false;
        if (pageStore.data.spreads == undefined || pageStore.data.spreads.length === 0) return false;
        if (pageStore.data.spreads[spreadIndex] == undefined || pageStore.data.spreads[spreadIndex].boxes == undefined || pageStore.data.spreads[spreadIndex].boxes.length === 0) return false;
        if (pageStore.data.spreads[spreadIndex].boxes[boxIndex] == undefined || pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo == undefined) return false;
        if (!pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo.isSticker) return false;
        
        return true;
    }

    return (
        <Flex
            flexDirection={"column"}
            w="full"
            h="calc(100vh - 72px)"
            p="8px"
            bg="wps.smoke"
            borderLeft="1px solid"
            borderRight="1px solid"
            borderColor="wps.lightgray"
            zIndex="1000"
            position="fixed"
            top="73px"
            right="0"
            width="270px"
        >
            <Button
                position={"absolute"}
                right="100%"
                top="50%"
                p={0}
                bg="unset"
                m={0}
                h="auto"
                minW="17px"
                marginRight={"-2px"}
                onClick={() => {
                    setShowRightSidebar(false)
                }}
                _hover={{}}
                _active={{}}
                zIndex={1001}
            >
                <Image src={CloseRightSidebar} />
            </Button>
            <Flex flexDirection={"column"}>
                <Flex 
                    alignItems={"center"} 
                    justify={"flex-end"} 
                    borderBottom="1px solid"
                    borderColor={"wps.lightgray"}
                    pb="12px"
                >
                    <Text flexGrow={1} fontWeight={"bold"}>Photo Box Positioning</Text>
                    <Icon
                        as={AiOutlineClose}
                        boxSize="16px"
                        onClick={() => setShowRightSidebar(false)}
                        cursor={"pointer"}
                    />
                </Flex>

                <Flex 
                    py="16px"
                    flexDirection={"row"}
                    gap={'4px'} 
                    justifyContent={'center'}
                >
                    <IconButton
                        icon={<MdFlipToFront />}
                        cursor={"pointer"}
                        width={'100%'}
                        bg={"wps.lightgray"}
                        sx={{
                            svg: {
                                fill: "unset",
                            },
                        }}
                        fontSize={"20px"}
                        onClick={pageStore.bringSelectedBoxToFront}
                        _hover={{ bg: "wps.gray", fill: "wps.white" }}
                    />

                    <IconButton
                        icon={<MdFlipToBack />}
                        cursor={"pointer"}
                        width={'100%'}
                        bg={"wps.lightgray"}
                        sx={{
                            svg: {
                                fill: "unset",
                            },
                        }}
                        fontSize={"20px"}
                        onClick={pageStore.bringSelectedBoxToBack}
                        _hover={{ bg: "wps.gray", fill: "wps.white" }}
                    />
                </Flex>

                <Flex
                    w="full"
                    justify={"flex-start"}
                    borderTop="1px solid"
                    borderColor={"wps.lightgray"}
                    py="16px"
                    flexDirection={"column"}
                    gap={2}
                >  

                    { hasValidPhotoToEdit() && 
                        (<>
                            <DarkButton text={"Edit Photo"} onClick={() => {
                                pageStore.initSelectedImagePhotoEditor();
                            }} />

                            <DarkButton text={"Reset Photo Box"} onClick={() => {
                                if ((pageStore.layoutType === 'photoPrint' || pageStore.layoutType === 'decoPrint') && pageStore.boxSelectedIndex !== null) {
                                    pageStore.setPhoto(0, pageStore.boxSelectedIndex, undefined);
                                } else if (pageStore.spreadSelectedIndex !== null && pageStore.boxSelectedIndex !== null) {
                                    pageStore.setPhoto(pageStore.spreadSelectedIndex, pageStore.boxSelectedIndex, undefined);
                                }
                            }} />
                        </>)
                        }

                    { pageStore.layoutType === 'photobook' && (
                        <DarkButton text={"Delete Photo Box"} onClick={() => {
                            pageStore.deleteSelectedPhotoBox();
                        }} />
                    )}

                    { isStickerInDecoPhotoPrint() && (
                        <DarkButton text={"Delete Photo Box"} onClick={() => {
                            pageStore.deleteSelectedPhotoBox();
                        }} />
                    )}
                </Flex>

            </Flex>
        </Flex>
    );
};

const BackgroundSelectedRightSidebar = ({ photoEditor, pageStore, setShowRightSidebar }) => {
    let backgroundColorTimeout;

    return (
        <Flex
            flexDirection={"column"}
            w="full"
            h="calc(100vh - 72px)"
            p="8px"
            bg="wps.smoke"
            borderLeft="1px solid"
            borderRight="1px solid"
            borderColor="wps.lightgray"
            zIndex="1000"
            position="fixed"
            top="73px"
            right="0"
            width="270px"
        >
            <Button
                position={"absolute"}
                right="100%"
                top="50%"
                p={0}
                bg="unset"
                m={0}
                h="auto"
                minW="17px"
                marginRight={"-2px"}
                onClick={() => {
                    setShowRightSidebar(false)
                }}
                _hover={{}}
                _active={{}}
                zIndex={1001}
            >
                <Image src={CloseRightSidebar} />
            </Button>
            <Flex flexDirection={"column"}>
                <Flex 
                    alignItems={"center"} 
                    justify={"flex-end"} 
                    borderBottom="1px solid"
                    borderColor={"wps.lightgray"}
                    pb="12px"
                >
                    <Text flexGrow={1} fontWeight={"bold"}>Background Editing</Text>
                    <Icon
                        as={AiOutlineClose}
                        boxSize="16px"
                        onClick={() => setShowRightSidebar(false)}
                        cursor={"pointer"}
                    />
                </Flex>

                <Flex
                    w="full"
                    justify={"flex-start"}
                    
                    py="16px"
                >
                    <ColorPicker 
                        value={pageStore.data.spreads[pageStore.spreadSelectedIndex].backgroundColor}
                        label="Background Color: "
                        onChange={(color) => {
                            if (backgroundColorTimeout) {
                                clearTimeout(backgroundColorTimeout);
                            }

                            backgroundColorTimeout = setTimeout(() => {
                                pageStore.setBackgroundColor(color)
                            }, 10);
                        }}
                    />
                </Flex>

                    {(pageStore.data.spreads[pageStore.spreadSelectedIndex].background
                        && pageStore.data.spreads[pageStore.spreadSelectedIndex].background.src    
                    ) && ( 
                    <Flex
                        w="full"
                        justify={"flex-start"}
                        borderTop="1px solid"
                        borderColor={"wps.lightgray"}
                        py="16px"
                        flexDirection={"column"}
                        gap={2}
                    >  
                        <DarkButton text={"Reset Background"} onClick={() => {
                            pageStore.setBackground(null);
                            pageStore.setBackgroundColor("#FFFFFF")
                        }} />

                        <DarkButton text={"Edit Background"} onClick={() => {
                            if (photoEditor.initBackgroundEdit) {
                                photoEditor.initBackgroundEdit();
                                pageStore.setIsEditingBackground(true);
                            }
                        }} />
                    </Flex>
                )}
               

            </Flex>
        </Flex>
    );
};

const TextSelectedRightSidebar = ({ pageStore, setShowRightSidebar }) => {
    const numericalRegexPattern = new RegExp('^[0-9]+$');
    let fontColorTimeout;

    return (
        <Flex
            flexDirection={"column"}
            w="full"
            h="calc(100vh - 72px)"
            p="8px"
            bg="wps.smoke"
            borderLeft="1px solid"
            borderRight="1px solid"
            borderColor="wps.lightgray"
            zIndex="1000"
            position="fixed"
            top="73px"
            right="0"
            width="270px"
        >
            <Button
                position={"absolute"}
                right="100%"
                top="50%"
                p={0}
                bg="unset"
                m={0}
                h="auto"
                minW="17px"
                marginRight={"-2px"}
                onClick={() => {
                    setShowRightSidebar(false)
                }}
                _hover={{}}
                _active={{}}
                zIndex={1001}
            >
                <Image src={CloseRightSidebar} />
            </Button>
            <Flex flexDirection={"column"}>
                <Flex 
                    alignItems={"center"} 
                    justify={"flex-end"} 
                    borderBottom="1px solid"
                    borderColor={"wps.lightgray"}
                    pb="12px"
                >
                    <Text flexGrow={1} fontWeight={"bold"}>Text Positioning</Text>
                    <Icon
                        as={AiOutlineClose}
                        boxSize="16px"
                        onClick={() => setShowRightSidebar(false)}
                        cursor={"pointer"}
                    />
                </Flex>

                <Flex 
                    py="16px"
                    flexDirection={"row"}
                    gap={'4px'} 
                    justifyContent={'center'}
                >
                    <IconButton
                        icon={<MdFlipToFront />}
                        cursor={"pointer"}
                        width={'100%'}
                        bg={"wps.lightgray"}
                        sx={{
                            svg: {
                                fill: "unset",
                            },
                        }}
                        fontSize={"20px"}
                        onClick={pageStore.bringSelectedTextToFront}
                        _hover={{ bg: "wps.gray", fill: "wps.white" }}
                    />

                    <IconButton
                        icon={<MdFlipToBack />}
                        cursor={"pointer"}
                        width={'100%'}
                        bg={"wps.lightgray"}
                        sx={{
                            svg: {
                                fill: "unset",
                            },
                        }}
                        fontSize={"20px"}
                        onClick={pageStore.bringSelectedTextToBack}
                        _hover={{ bg: "wps.gray", fill: "wps.white" }}
                    />
                </Flex>

                <Flex
                    w="full"
                    justify={"flex-start"}
                    borderTop="1px solid"
                    borderColor={"wps.lightgray"}
                    py="16px"
                    flexDirection={"column"}
                    gap={4}
                >
                    { pageStore.spreadSelectedIndex !== null && pageStore.textSelectedIndex !== null &&
                        <>
                            <TextFormatters pageStore={pageStore} />
                            <Flex>
                                <Select
                                    options={fonts}
                                    p={2}
                                    border="1px solid black"
                                    borderRadius={0}
                                    w="full"
                                    selectedValue={pageStore.data.spreads[pageStore.spreadSelectedIndex]?.texts[pageStore.textSelectedIndex]?.fontFamily}
                                    setSelectedValue={(value) => {
                                        pageStore.setFontFamily(value);
                                    }}
                                />
                            </Flex>
                            <Flex align="center" justify={"space-between"}>
                                <Flex
                                    border="1px solid"
                                    align="center"
                                    h="full"
                                    borderColor={"wps.bluegray"}
                                    maxW="250px"
                                    bg="wps.white"
                                >
                                    <Flex h="full" justify={"center"} align="center">
                                        <IconButton
                                            icon={<AiOutlineMinus />}
                                            bg="transparent"
                                            borderRadius={"0px"}
                                            h="full"
                                            p="8px"
                                            cursor={"pointer"}
                                            tabIndex={0}
                                            onClick={pageStore.decrementFontSize}
                                        />
                                    </Flex>
                                    <InputGroup>
                                        <Input
                                            width={"100px"}
                                            textAlign={"center"}
                                            value={pageStore.data.spreads[pageStore.spreadSelectedIndex]?.texts[pageStore.textSelectedIndex]?.fontSize}
                                            onChange={(event) => {
                                                let newValue = event.target.value;
                                                if (numericalRegexPattern.test(newValue) || newValue == "") {
                                                    if ((+newValue) > 1000) newValue = 999;
                                                    else if (+newValue < 0) newValue = 1;
                                                    pageStore.setFontSize(newValue);
                                                }
                                            }}
                                        />
                                    </InputGroup>
                                    <Flex h="full" justify={"center"} align="center">
                                        <IconButton
                                            icon={<AiOutlinePlus />}
                                            bg="transparent"
                                            borderRadius={"0px"}
                                            h="full"
                                            p="8px"
                                            cursor={"pointer"}
                                            tabIndex={0}
                                            onClick={pageStore.incrementFontSize}
                                        />
                                    </Flex>
                                </Flex>
                                <ColorPicker 
                                    value={pageStore.data.spreads[pageStore.spreadSelectedIndex]?.texts[pageStore.textSelectedIndex]?.fontColor}
                                    label=''
                                    onChange={(color) => {
                                        if (fontColorTimeout) {
                                            clearTimeout(fontColorTimeout);
                                        }

                                        fontColorTimeout = setTimeout(() => {
                                            pageStore.setFontColor(color)
                                        }, 10);
                                    }}
                                />
                            </Flex>
                        </>
                    }
                </Flex>

                <Flex
                    w="full"
                    justify={"flex-start"}
                    borderTop="1px solid"
                    borderColor={"wps.lightgray"}
                    py="16px"
                    flexDirection={"column"}
                    gap={2}
                >  

                    <DarkButton text={"Delete Text"} onClick={() => {
                        pageStore.deleteSelectedText();
                    }} />
                </Flex>

            </Flex>
        </Flex>
    );
};