import { Text, Flex, Icon, Image } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { useCartStore } from "../../../states/useCartStore";
import { ReactComponent as DeletePage } from "../../Canvas/images/DeletePage.svg";

export const Cart = ({ setShowCart }) => {
    const cartStore = useCartStore((state) => state);
    return (
        <Flex
            position={"absolute"}
            width={"300px"}
            height={"300px"}
            border="1px solid #DDDEDF"
            background="#fff"
            zIndex={2000}
            top={"72px"}
            right={"2px"}
            p={"16px"}
            flexDirection={"column"}
        >
            <Flex  
                flexDirection={"column"}           
                overflow={"scroll"}
                gap={"16px"}
            >
                <Flex
                    w="full"
                    justify={"space-between"}
                    align={"top"}
                    borderBottom="1px solid"
                    borderColor={"wps.lightgray"}
                    pb="12px"
                >
                    <Text fontWeight={"bold"}>Cart</Text>
                    <Icon
                        as={AiOutlineClose}
                        cursor={"pointer"}
                        onClick={() => {
                            setShowCart(false);
                        }}
                        tabIndex={0}
                    />
                </Flex>
                <Flex flexDirection={"column"} gap="8px">
                    {cartStore.items.length === 0 && (
                        <Text color="wps.medgray" textAlign={"center"}>
                            No items added to the cart yet                        
                        </Text>
                    )}

                    {cartStore.items.length > 0 && (
                        <>
                            {cartStore.items?.map((item, i) => (

                                <Flex flexDirection={"row"} 
                                    justify={"left"} 
                                    gap="16px"     
                                    borderBottom="1px solid"
                                    borderColor={"wps.lightgray"}
                                    pb="12px"
                                    flexGrow={1}
                                >
                                    <Flex
                                        height={"50px"}
                                        width={"50px"}
                                        backgroundSize={"cover"}
                                        backgroundPosition={"center"}
                                        backgroundImage={item.image}
                                    >

                                    </Flex>

                                    <Flex justify={"space-between"} grow={1}>
                                        <Flex flexDirection={"column"} gap="4px">
                                            <Text fontWeight={"bold"}> { item.projectName } </Text>
                                            <Text fontSize={"14px"}> Qty: { item.quantity } </Text>
                                        </Flex>

                                        <Flex flexDirection={"column"} gap="4px">
                                            <Icon
                                                marginLeft={"auto"}
                                                as={DeletePage}
                                                boxSize={"20px"}
                                                cursor={"pointer"}
                                                onClick={() => {
                                                    cartStore.deleteItem(item.projectId)
                                                }}
                                            />
                                            <Text> { item.price } </Text>
                                        </Flex>
                                    </Flex>

                                </Flex>
                            ))}
                        </>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
