import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(radioAnatomy.keys);

const baseStyle = definePartsStyle({
    control: {
        borderColor: "wps.darkgray",
        borderSize: "1px",
        width: "19px",
        height: "19px",
        _checked: {
            background: "white",
            color: "wps.darkgray",
            borderColor: "wps.darkgray",
        },
    },
    label: {
        color: "wps.bluegray",
        fontWeight: "medium",
    },
});

export const radioTheme = defineMultiStyleConfig({ baseStyle });
