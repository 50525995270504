export const DECO_PRINT_LAYOUTS = {
    'Acrylic 3mm': {
        '8.27x11.69': {
            variantId: '42344622063673',
            width: 8.27,
            height: 11.69,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 400
        },
    },
    'Canvas with Black Frame': {
        '8.27x11.69': {
            variantId: '42344621998137',
            width: 8.27,
            height: 11.69,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 1100
        },
        '12RW (12x18)': {
            variantId: '42344622030905',
            width: 12,
            height: 18,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 1700
        },
    },
    'Canvas Wrap Around': {
        '8.27x11.69': {
            variantId: '42344621768761',
            width: 8.27,
            height: 11.69,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 500
        },
        '12RW (12x18)': {
            variantId: '42344621801529',
            width: 12,
            height: 18,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 1250
        },
        '11R (11x14)': {
            variantId: '42344621834297',
            width: 11,
            height: 14,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 950
        },
        '10S (10x10)': {
            variantId: '42344621867065',
            width: 10,
            height: 10,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 500
        },
        '15x15': {
            variantId: '42344621899833',
            width: 15,
            height: 15,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 1200
        },
    },
    'Cork Board': {
        '8.27x11.69': {
            variantId: '42344621604921',
            width: 8.27,
            height: 11.69,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 375
        },
    },
    'Cotton Canvas': {
        '8.27x11.69': {
            variantId: '42344621572153',
            width: 8.27,
            height: 11.69,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 400
        },
    },
    'MDF Board 5mm': {
        '8.27x11.69': {
            variantId: '42344621506617',
            width: 8.27,
            height: 11.69,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 275
        },
    },
    'Plywood 5mm': {
        '8.27x11.69': {
            variantId: '42344621441081',
            width: 8.27,
            height: 11.69,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 300
        },
    },
    'Sintra Board 5mm': {
        '8.27x11.69': {
            variantId: '42344621310009',
            width: 8.27,
            height: 11.69,
            margins: {
                top: 0,
                left: 0,
                right: 0,
                bottom: 0
            },
            price: 275
        },
    }
};