import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { Area } from "./Page/Area";
import { usePageStore } from "../../states/usePageStore";
import { useRef, useState, useEffect } from "react";
import { useDragBackground } from "../../states/useDragBackground";
import { usePhotoEditor } from "../../states/usePhotoEditor";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { useZoomStore } from "../../states/useZoomStore";
import { EditableText } from "./EditableText";
import { CONSTANTS } from "../../configs/constants";
import { useDragPhotoStore } from "../../states/useDragPhoto";
import { uploadPhoto } from "../../utils/photo-uploader";
import { nanoid } from "nanoid";

export const Canvas = ({ spreadIndex, setShowRightSidebar, showGrid }) => {
    const pageStore = usePageStore((state) => state);
    const dragBackground = useDragBackground((state) => state);
    const dragPhoto = useDragPhotoStore((state) => state);
    const photoEditor = usePhotoEditor((state) => state)
    const zoomStore = useZoomStore((state) => state);

    const [isImageLoading, setIsImageLoading] = useState(false);
    const [hovering, setHovering] = useState(false);
    const [editingImage, setEditingImage] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [cropper, setCropper] = useState(null);
    const [outputImage, setOutputImage] = useState('');
    const [filter, setFilter] = useState({});
    const [outlineSize, setOutlineSize] = useState(16);
    const [cropperData, setCropperData] = useState(false);

    const imageRef = useRef();

    let canvasHeight = pageStore.template.height;
    let canvasWidth = pageStore.template.width;
    if (pageStore.template.spreads[spreadIndex]?.width) canvasWidth = pageStore.template.spreads[spreadIndex].width;
    if (pageStore.template.spreads[spreadIndex]?.height) canvasHeight = pageStore.template.spreads[spreadIndex].height;
    const boxes = pageStore.data.spreads[spreadIndex].boxes;
    const texts = pageStore.data.spreads[spreadIndex].texts;
    const margins = pageStore.template.margins;

    useEffect(() => {
        let dividend = 50;
        if (zoomStore.zoom <= 10) dividend = 30;

        setOutlineSize( 16 / (zoomStore.zoom / dividend));
    }, [zoomStore.zoom]);

    useEffect(() => {
        const photo = pageStore.data.spreads[spreadIndex].background;

        if (photo) {
            pageStore.data.spreads[spreadIndex].background = {
                originalSrc: photo.originalSrc || photo.src,
                editedOriginalSrc: photo.editedOriginalSrc,
                src: outputImage !== "" ? outputImage : (photo.src !== "" ? photo.src : photo.originalSrc),
                filter: photo.filter,
                cropperData: photo.cropperData
            };
        }

    }, [outputImage]);

    useEffect(() => {
        const photo = pageStore.data.spreads[spreadIndex].background;

        if (photo) {
            pageStore.data.spreads[spreadIndex].background.filter = filter;
        }

    }, [filter]);

    useEffect(() => {
        const photo = pageStore.data.spreads[spreadIndex].background;
        if (photo) {
            pageStore.data.spreads[spreadIndex].background.cropperData = cropperData;
        }
    }, [cropperData])


    const isHighlighted = () => {
        return hovering || pageStore.spreadSelectedIndex === spreadIndex
    };

    const handleBackgroundEdit = () => {
        const photo = pageStore.data.spreads[spreadIndex].background;
            
        if (photo) {
            setEditingImage(photo);  
            setIsEditMode(true);
            photoEditor.enterEditMode(photo, (cropper) => {
                setIsImageLoading(true);
                const canvas = cropper.getCroppedCanvas();
                setCropperData(cropper.getData());

                canvas.toBlob(function (blob) { 
                    const file = blob;
                    const clientId = localStorage.getItem('clientId');
                    const key = `edited/${clientId}/photos/${nanoid()}`;
                    const fileType = `webp`;

                    uploadPhoto(file, key, fileType).then((data) => {
                        const editedBackground = {
                            src: `${process.env.REACT_APP_S3_BUCKET_URL}/${data.s3Key}`,
                            editedOriginalSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/${data.originalS3Key}`,
                        };

                        pageStore.setEditedBackground(spreadIndex, editedBackground);
                        setOutputImage(editedBackground.src);
                        setIsImageLoading(false);
                    });
                });

                setEditingImage({});
                setIsEditMode(false);
                setCropper(null);
            }, (filter) => {
                pageStore.setUndoReset();
                setFilter(filter);
            });

            setTimeout(() => {
                const cropper = new Cropper(imageRef.current, {
                    viewMode: 1,
                    dragMode: "move",
                    autoCropArea: 1,
                    aspectRatio: canvasWidth/canvasHeight,
                    rotatable: true,
                    data: photo.cropperData ? photo.cropperData : {},
                    crop: () => {
                        photoEditor.setFilter();
                    },
                });

                setCropper(cropper);
                photoEditor.setCropper(cropper);
                setShowRightSidebar(true);
            }, 100);
        }
    };

    const getCSSFilter = () => {
        const photo = pageStore.data.spreads[spreadIndex].background;
        if (photo?.filter) {
           const filter = photo.filter;
            return `brightness(${filter.brightness}%) saturate(${filter.saturation}%) contrast(${filter.contrast}%) opacity(${filter.opacity}%) sepia(${filter.sepia}%) grayscale(${filter.grayscale}%)`;
        } else {
            return "auto";
        }
    };

    return (
        <>
            <div
                style={{
                    display: isEditMode ? "block" : "none",
                    height: `${canvasHeight}px`,
                    width: `${canvasWidth}px`
                }}
            >
                <Flex
                    style={{ 
                        height: `${canvasHeight}px`,
                        width: `${canvasWidth}px`,
                        position: 'absolute' 
                    }}
                    
                    alignItems={"center"}
                    justify={"center"}
                >
                    <Spinner
                        size="xl"
                        style={{
                            display: cropper?.ready
                                ? "none"
                                : "block",
                            
                        }}
                    />
                </Flex>
                <div
                    style={{
                        visibility: cropper?.ready
                            ? "visible"
                            : "hidden",
                        height: `${canvasHeight}px`,
                        width: `${canvasWidth}px`,
                        position: 'absolute'
                    }}
                >
                    <img
                        ref={imageRef}
                        style={{ height: `${canvasHeight}px`, width: `${canvasWidth}px` }}
                        src={
                            editingImage.originalSrc ||
                            editingImage.src
                        }
                    />
                    <img
                        style={{ visibility: "hidden", position: "absolute" }}
                        src={outputImage}
                    />

                    <div style={{ marginBottom: "12px" }}></div>
                </div>
            </div>
            
            {(!isEditMode && !isImageLoading) && (
                <>
                    { showGrid && (
                        <Rulers maxWidth={canvasWidth} maxHeight={canvasHeight} top={((canvasHeight + CONSTANTS.CANVAS_SPACING) * spreadIndex)}></Rulers>
                    )}
                    { pageStore.layoutType === 'photobook' && (<CropMarks maxWidth={canvasWidth} maxHeight={canvasHeight} top={((canvasHeight + CONSTANTS.CANVAS_SPACING) * spreadIndex)}></CropMarks>)}

                    <Box
                        id={`canvas-${spreadIndex}`}
                        position={"absolute"}
                        zIndex={-1}
                        h={`${canvasHeight}px`}
                        w={`${canvasWidth}px`}
                        top={(canvasHeight + CONSTANTS.CANVAS_SPACING) * spreadIndex}
                        boxSizing="border-box"
                        backgroundColor={pageStore.data.spreads[spreadIndex].backgroundColor}
                        overflow={"hidden"}
                    >
                    </Box>
                    <Box
                        position={"absolute"}
                        zIndex={-1}
                        h={`${canvasHeight}px`}
                        w={`${canvasWidth}px`}
                        top={(canvasHeight + CONSTANTS.CANVAS_SPACING) * spreadIndex}
                        filter={getCSSFilter()}
                        boxSizing="border-box"
                        {...((pageStore.data.spreads[spreadIndex].background?.src || pageStore.data.spreads[spreadIndex].background?.originalSrc)
                            ? {
                                backgroundImage: pageStore.data.spreads[spreadIndex].background.src || pageStore.data.spreads[spreadIndex].background?.originalSrc,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }
                            : {})
                        }
                        overflow={"hidden"}
                    >
                    </Box>
                    <Box
                        zIndex={0}
                        h={`${canvasHeight}px`}
                        w={`${canvasWidth}px`}
                        outline={ isHighlighted() ? `${outlineSize}px solid` : "2px solid"}
                        outlineColor={ isHighlighted() ? "wps.lightbeige" : "wps.lightgray"}
                        boxSizing="border-box"
                        onMouseEnter={() => {
                            if (pageStore.layoutType === 'photobook') {
                                setHovering(true);
                            }
                        }}
                        onMouseLeave={() => {
                            setHovering(false)
                        }}
                        onMouseDown={(event) => {
                            event = event || window.event;
                            event.stopPropagation();

                            pageStore.setSpreadSelectedIndex(spreadIndex);
                            if (pageStore.layoutType === 'photobook') {
                                photoEditor.setInitBackgroundEdit(handleBackgroundEdit);
                            }
                            setShowRightSidebar(true);
                        }}
                        onDragOver={(e) => e.preventDefault()}
                        onDrop={() => {
                            if (dragBackground.draggedBackgroundSrc && pageStore.layoutType === 'photobook') {
                                dragBackground.setDraggedBackground(null);
                                pageStore.setBackground(dragBackground.draggedBackgroundSrc, spreadIndex);
                            } else if (dragPhoto.draggedStickerSrc) {
                                pageStore.setSpreadSelectedIndex(spreadIndex);
                                pageStore.addBox({
                                    photo: {
                                        src: dragPhoto.draggedStickerSrc,
                                        originalSrc: undefined,
                                        filter: "auto",
                                        isSticker: true
                                    },
                                    width: 120,
                                    height: 120
                                });
                                dragPhoto.setDraggedSticker(null);
                            }
                        }}
                        overflow={"hidden"}
                    >
                        <>
                            {pageStore.layoutType === 'photobook' && (
                                <Flex
                                    height={canvasHeight}
                                    width={'1px'}
                                    top={((canvasHeight + CONSTANTS.CANVAS_SPACING) * spreadIndex) }
                                    left={(canvasWidth / 2)}
                                    borderLeft="1px solid gray"
                                    borderStyle="dotted"
                                    position={"absolute"}
                                ></Flex>
                            )}

                            
                            {boxes?.map((box, i) => (
                                <>
                                { !box.deleted && (
                                    <Area
                                        key={i}
                                        spreadIndex={spreadIndex}
                                        setShowRightSidebar={setShowRightSidebar}
                                        boxIndex={i}
                                        margins={margins}
                                        canvasHeight={canvasHeight}
                                        x={(box.x + margins.left)}
                                        y={(box.y + margins.top + ((canvasHeight + CONSTANTS.CANVAS_SPACING) * spreadIndex) )}
                                        height={box.height}
                                        width={box.width}
                                        showGrid={showGrid}
                                    />
                                )}
                                </>
                            ))}

                            {texts?.map((text, i) => (
                                <>
                                { !text.deleted && (
                                    <EditableText 
                                        key={i} 
                                        text={text} 
                                        margins={margins} 
                                        canvasHeight={canvasHeight} 
                                        spreadIndex={spreadIndex} 
                                        outlineSize={outlineSize}
                                        textIndex={i}

                                    />
                                )}
                                </>
                            ))}
                        </>
                    </Box>
                </>
            )}

            {(!isEditMode && isImageLoading) && (
                <Flex
                    style={{ 
                        height: `${canvasHeight}px`,
                        width: `${canvasWidth}px`,
                        position: 'absolute' 
                    }}
                    
                    alignItems={"center"}
                    justify={"center"}
                >
                    <Spinner
                        size="xl"
                        style={{
                            display: "block",
                        }}
                    />
                </Flex>
            )}
        </>
    );
};

const Rulers = ({maxWidth, maxHeight, top}) => {
    const verticalLines = [];
    const horizontalLines = [];
    for (let i = 0; i <= maxWidth; i += 48) {
        verticalLines.push(i);
    }

    for (let i = top; i <= maxHeight + top; i += 48) {
        horizontalLines.push(i);
    }

    return <>
        { verticalLines.map((left, i) => (
            <>
                <Flex
                    height={`${maxHeight}px`}
                    width={'1px'}
                    top={`${top}px`}
                    left={`${left}px`}
                    borderLeft="1px solid black"
                    borderStyle="dotted"
                    position={"absolute"}
                    zIndex={1000}
                ></Flex>
                {i !== verticalLines.length -1 && (
                    <Flex
                        top={`${top + maxHeight + 8}px`}
                        left={`${left}px`}
                        height="48px"
                        width="48px"
                        position={"absolute"}
                        zIndex={1000}
                    >
                        <Text marginTop={"3px"} marginLeft={"6px"}> {0.5 * i} </Text>
                    </Flex>
                )}
            </>
        ))}

        { horizontalLines.map((top, i) => (
            <>
                <Flex
                    height={'1px'}
                    width={`${maxWidth}px`}
                    top={`${top}px`}
                    left={`0px`}
                    borderTop="1px solid black"
                    borderStyle="dotted"
                    position={"absolute"}
                    zIndex={1000}
                ></Flex>

                {i !== horizontalLines.length -1 && (
                    <Flex
                        top={`${top}px`}
                        left={`${maxWidth + 8}px`}
                        height="48px"
                        width="48px"
                        position={"absolute"}
                        zIndex={1000}
                    >
                        <Text marginTop={"3px"} marginLeft={"6px"}> {0.5 * i} in </Text>
                    </Flex>
                )}
            </>
        ))}
    </>
};

const CropMarks = ({maxWidth, maxHeight, top}) => {
    const verticalLines = [47, maxWidth - 47];
    const horizontalLines = [top + 47, top + (maxHeight - 47)];

    return <>
        { verticalLines.map((left) => (
            <Flex
                height={`${maxHeight-94}px`}
                width={'1px'}
                top={`${top+47}px`}
                left={`${left}px`}
                borderLeft="1px solid black"
                borderStyle="dotted"
                position={"absolute"}
                zIndex={1000}
            ></Flex>
        ))}

        { horizontalLines.map((top) => (
            <Flex
                height={'1px'}
                width={`${maxWidth-94}px`}
                top={`${top}px`}
                left={`47px`}
                borderTop="1px solid black"
                borderStyle="dotted"
                position={"absolute"}
                zIndex={1000}
            ></Flex>
        ))}
    </>
};
