import { Flex, Input, Text } from "@chakra-ui/react";

export const ColorPicker = ({value, onChange, label}) => {
    return (
        <Flex w="full" margin={'0 auto'} gap={2} alignItems={"center"}>
            <Text fontWeight="medium" color="wps.buttongray">
                {label}
            </Text>
            <Input
                value={value}
                onChange={(event) => {
                    onChange(event.target.value);
                }}
                type="color"
                maxH="21px"
                maxW="41px" p={0}
            />
        </Flex>
    );
};
