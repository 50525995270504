import { Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { BiSearch } from "react-icons/bi";

export const SearchInput = ({value, placeholder, onChange}) => {
    return (
        <InputGroup>
            <Input
                value={value}
                onChange={onChange}
                placeholder={placeholder || "Search"} />
            <InputRightElement children={<BiSearch />} />
        </InputGroup>
    );
};
