import { Flex } from "@chakra-ui/react";
import { DarkButton } from "../common";
import { usePageStore } from "../../../../states/usePageStore";

export const Text = ({ display }) => {
    const pageStore = usePageStore((state) => state);

    return (
        <Flex 
            w="full" 
            flexDirection={"column"} 
            py="16px" 
            gap="16px"
            display={display}
        >

            <DarkButton 
                text="Add text" 
                isDisabled={pageStore.layoutType === 'photobook' ? pageStore.spreadSelectedIndex === null : false}
                tooltip={pageStore.spreadSelectedIndex === null ? "Select a page to add text" : ''}
                onClick={() => {
                    pageStore.addText();
                }}
            />
            
        </Flex>
    );
};
