import { create } from "zustand";

export const useProjectStore = create(
    (set, get) => ({
        projects: [],
        setProjects: (projects) => set(() => ({ projects: projects })),
        deleteProject: (key) => set((state) => {
            state.projects = state.projects.filter((project) => {
                return project.s3FileName != key;
            })
            return { projects: state.projects }
        }),
        setProjectProp: (projectId, prop, value) => set((state) => { 
            state.projects = state.projects.map((project) => {
                if (project.projectId === projectId) {
                    project[prop] = value;
                }

                return project;
            })
            return { projects: state.projects }
        }),
        postCreateProjectFunc: null,
        setPostCreateProjectFunc: (postCreateProjectFunc) => set(() => ({ postCreateProjectFunc: postCreateProjectFunc }))
    })
);
