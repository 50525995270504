import { create } from "zustand";

export const useStickerStore = create((set) => ({
    stickers: [],
    setStickers: (stickers) => set(() => ({stickers: stickers})),
    query: '',
    setQuery: (query) => set(() => ({query: query})),
    lastQuery: '',
    setLastQuery: (lastQuery) => set(() => ({lastQuery: lastQuery})),
    isSearching: false,
    setIsSearching: (isSearching) => set(() => ({isSearching: isSearching})),
}));
