import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Image
} from "@chakra-ui/react";
import walkthrough1 from './gifs/walkthrough1.gif';
import walkthrough2 from './gifs/walkthrough2.gif';
import walkthrough3 from './gifs/walkthrough3.gif';
import walkthrough4 from './gifs/walkthrough4.gif';
import walkthrough5 from './gifs/walkthrough5.gif';

export const Walkthrough = ({ isOpen, onClose }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent maxW="900px">
                <ModalHeader
                    fontFamily="Bebas Neue, sans-serif"
                    fontSize={"23px"}
                    fontWeight={"normal"}
                    borderBottom={"1px solid #DDDEDF"}
                >
                    {"WALKTHROUGH"}
                </ModalHeader>
                <ModalCloseButton />
                <ModalBody bg="#fff">
                    <Tabs isFitted defaultIndex={0}>
                    <TabList>
                        <Tab color="#2F2F2F" _selected={{ color: '#2F2F2F', borderBottom: '2px solid #2F2F2F' }}>Create Project</Tab>
                        <Tab color="#2F2F2F" _selected={{ color: '#2F2F2F', borderBottom: '2px solid #2F2F2F' }}>Select Layout</Tab>
                        <Tab color="#2F2F2F" _selected={{ color: '#2F2F2F', borderBottom: '2px solid #2F2F2F' }}>Upload Photos</Tab>
                        <Tab color="#2F2F2F" _selected={{ color: '#2F2F2F', borderBottom: '2px solid #2F2F2F' }}>Customization</Tab>
                        <Tab color="#2F2F2F" _selected={{ color: '#2F2F2F', borderBottom: '2px solid #2F2F2F' }}>Checkout</Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <Image src={walkthrough1} height="300px" margin={"0 auto"}/>
                            <Text mt="16px"> 
                            To begin laying out a design, start by creating a new project. You can work on multiple projects simultaneously, with each project saved at the bottom of the screen. Easily navigate between different projects by clicking on their respective thumbnails. Remember to always click the “Save” button to ensure that all your progress is saved.                        
                            </Text>
                            </TabPanel>
                        <TabPanel>
                            <Image src={walkthrough2} height="300px" margin={"0 auto"}/>
                            <Text mt="16px"> 
                            Choose a layout type from options (“Photo Print”, “Deco Print” or “Photo Safe”) and select the desired product variant for your order. The price will be revealed upon selecting the specific product variant. Keep in mind that certain variants may have a minimum order quantity requirement.
                            </Text>
                        </TabPanel>
                        <TabPanel>
                            <Image src={walkthrough3} height="300px" margin={"0 auto"}/>
                            <Text mt="16px"> 
                            To begin uploading photos, navigate to the “Photos” tab and click on “Upload photos”. Choose the photos you wish to upload. Then, to start editing these uploaded photos, simply drag them into the designated photo boxes on your canvas.
                            </Text>                        
                        </TabPanel>
                        <TabPanel>
                            <Image src={walkthrough4} height="300px" margin={"0 auto"}/>
                            <Text mt="16px"> 
                            You have the option to personalize your prints in various ways. Begin by editing your uploaded photos - click on the photos within your canvas and select “Edit Photo”. To further customize, consider adding stickers and text for a unique touch. Additionally, for Photo Safe products, you can enhance your prints by adding backgrounds from our image repository or by uploading your own.
                            </Text>
                        </TabPanel>
                        <TabPanel>
                            <Image src={walkthrough5} height="300px" margin={"0 auto"}/>
                            <Text mt="16px"> 
                            To proceed with your project, simply click on the “Add to Cart” button. A final preview of your project will then appear for your review. For Photo and Deco Prints, ensure to specify the quantity per photo before adding to the cart. Once you are content with the final preview and quantities, click the “Add to Cart” button on the preview modal. If you wish to checkout another project, switch to your desired project and repeat the process. When you have added all projects you wish to checkout, click the “Checkout” button. This will redirect you to the main website to complete the checkout process.
                            </Text>
                        </TabPanel>
                    </TabPanels>
                    
                    </Tabs>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};