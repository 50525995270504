export const colors = {
    colors: {
        wps: {
            white: "#FFFFFF",
            smoke: "#FCFCFC",
            buttongray: "#41464C",
            lightergray: '#F9F9F9',
            lightgray: "#DDDEDF",
            medgray: "#73777C",
            gray: "#41464C",
            darkgray: "#2F2F2F",
            bluegray: "#505558",
            black: "#020912",
            lightbeige: "#DDCCB2",
            orange: "#F6742B",
            red: "#af6a5a",
            yellow: "#FCF6A9"
        },
    },
};
