const AWS = require('aws-sdk');

export const uploadPhoto = async (file, key, fileType) => {
    const data = new FormData();
    data.append('photo', file);
    data.append('key', key);
    data.append('fileType', fileType);

    try {
        const res = await fetch(process.env.REACT_APP_PHOTO_RESIZER_URL, {
            method: 'POST',
            body: data
        });
        return res.json();
    } catch (ex) {

    }
};