import { Button, Flex, Grid, GridItem, Image, Show } from "@chakra-ui/react";
import { Tabs } from "./Tabs";
import { TabDetails } from "./TabDetails";
import { useRef, useState } from "react";
import CloseLeftSidebar from "../../images/LeftSidebar/CloseLeftSidebar.svg";
import OpenLeftSidebar from "../../images/LeftSidebar/OpenLeftSidebar.svg";

export const LeftSidebar = ({ showTabDetails, setShowTabDetails, activeTab, setActiveTab }) => {

    const previousActiveTab = useRef("Layouts");

    const handleHideTabDetails = () => {
        setShowTabDetails(false);
        setActiveTab("");
    };

    const handleShowTabDetails = () => {
        setShowTabDetails(true);
        setActiveTab(previousActiveTab.current);
    };

    return (
        <>
            <Show above="lg">
                <Grid
                    templateAreas={`
            'icons details'
        `}
                    templateColumns={"83px auto"}
                    h="100%"
                    position="fixed"
                    w={showTabDetails ? "350px" : "83px"}
                    zIndex="1001"
                    backgroundColor="wps.smoke"
                >
                    <GridItem area={"icons"} position="relative">
                        <Tabs
                            activeTab={activeTab}
                            setActiveTab={setActiveTab}
                            setShowTabDetails={setShowTabDetails}
                            previousActiveTab={previousActiveTab}
                        />
                        {!showTabDetails && (
                            <Button
                                position={"absolute"}
                                left="100%"
                                top="50%"
                                transform={"translateY(-50%)"}
                                p={0}
                                bg="unset"
                                m={0}
                                h="auto"
                                minW="17px"
                                marginLeft={"-3px"}
                                onClick={handleShowTabDetails}
                                _hover={{}}
                                _active={{}}
                                zIndex={1}
                            >
                                <Image src={OpenLeftSidebar} />
                            </Button>
                        )}
                    </GridItem>
                    <GridItem area="details" position="relative">
                        <TabDetails
                            activeTab={activeTab}
                            hideTabDetails={handleHideTabDetails}
                            display={`${showTabDetails ? "flex" : "none"}`}
                        />
                        {showTabDetails && (
                            <Button
                                position={"absolute"}
                                left="100%"
                                top="50%"
                                transform={"translateY(-50%)"}
                                p={0}
                                bg="unset"
                                m={0}
                                h="auto"
                                minW="17px"
                                marginLeft={"-2px"}
                                onClick={handleHideTabDetails}
                                _hover={{}}
                                _active={{}}
                                zIndex={1}
                            >
                                <Image src={CloseLeftSidebar} />
                            </Button>
                        )}
                    </GridItem>
                </Grid>
            </Show>

            <Show below="lg">
                <SmallScreen
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    showTabDetails={showTabDetails}
                    setShowTabDetails={setShowTabDetails}
                    previousActiveTab={previousActiveTab}
                    handleShowTabDetails={handleShowTabDetails}
                    handleHideTabDetails={handleHideTabDetails}
                />
            </Show>
        </>
    );
};

const SmallScreen = ({
    activeTab,
    setActiveTab,
    showTabDetails,
    setShowTabDetails,
    previousActiveTab,
    handleShowTabDetails,
    handleHideTabDetails,
}) => {
    return (
        <Flex h="100%" position="fixed" w="auto" zIndex={1}>
            <Button
                position={"absolute"}
                left="100%"
                top="50%"
                transform={"translateY(-50%)"}
                p={0}
                bg="unset"
                m={0}
                h="auto"
                minW="17px"
                marginLeft={"-3px"}
                onClick={() =>
                    showTabDetails
                        ? handleHideTabDetails()
                        : handleShowTabDetails()
                }
                _hover={{}}
                _active={{}}
                zIndex={1}
            >
                <Image
                    src={showTabDetails ? CloseLeftSidebar : OpenLeftSidebar}
                />
            </Button>

            {showTabDetails && (
                <Tabs
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    setShowTabDetails={setShowTabDetails}
                    previousActiveTab={previousActiveTab}
                />
            )}
            {/* use display conditional so that photos won't need to reload */}
            <TabDetails
                activeTab={activeTab}
                hideTabDetails={handleHideTabDetails}
                display={`${showTabDetails ? "flex" : "none"}`}
            />
        </Flex>
    );
};
