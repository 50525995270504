import {
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    MenuOptionGroup,
    MenuItemOption,
} from "@chakra-ui/react";
import { useState } from "react";
import { BsChevronDown } from "react-icons/bs";

export const Select = ({ placeholder, options, defaultValue, isDisabled, selectedValue, setSelectedValue, ...props }) => {
    return (
        <Menu bg="white">
            <MenuButton
                as={Button}
                bg="unset"
                _hover={{}}
                _active={{}}
                textAlign={"left"}
                rightIcon={<BsChevronDown />}
                p="0"
                w="fit-content"
                {...props}
                isDisabled={isDisabled}
                style={{overflow: 'hidden'}}
            >
                <Text style={{overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis'}}>{selectedValue || placeholder}</Text>
            </MenuButton>
            <MenuList
                border="2px solid"
                borderColor={"wps.black"}
                borderRadius={0}
                bg="white"
                overflow={'scroll'}
                maxHeight={'200px'}
                zIndex={1002}
                position={"absolute"}
            >
                {options.map((option, i) => (
                    <Option 
                        key={i}
                        onClick={() => setSelectedValue(option)}
                        value={option}
                        selectedValue={selectedValue}
                    >
                        {option}
                    </Option>
                ))}
            </MenuList>
        </Menu>
    );
};

const Option = ({ children, onClick, value, selectedValue }) => {
    return (
        <MenuItem background={value === selectedValue ? 'wps.lightgray': 'white'} value={value} onClick={onClick}>
            <Text
                fontSize={"17px"}
                fontWeight={"medium"}
                color="wps.buttongray"
            >
                {children}
            </Text>
        </MenuItem>
    );
};
