import { Flex, Icon, Text } from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { Layouts } from "./Layouts";
import { Photos } from "./Photos";
import { Backgrounds } from "./Backgrounds";
import { Stickers } from "./Stickers";
import { Text as TextTab } from "./Text";
import AWS from "aws-sdk";
import { useQuery } from "react-query";
import { usePhotoStore } from "../../../states/usePhotoStore";

export const TabDetails = ({ activeTab, hideTabDetails, ...props }) => {
    const clientId = localStorage.getItem('clientId');

    const fetchFilesInDirectory = async () => {
        AWS.config.update({
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const s3 = new AWS.S3();

        const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Prefix: `${clientId}/photos`,
        };

        try {
            const data = await s3.listObjectsV2(params).promise();
            // List of objects/files in the specified directory
            const fileList = data.Contents.sort(
                (a, b) => Number(b.LastModified) - Number(a.LastModified),
            ).map(
                (file) => ({
                    src: `${process.env.REACT_APP_S3_BUCKET_URL}/${file.Key}`,
                    originalSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/original-${file.Key.replace('.webp', '.jpeg')}`,
                    s3FileName: file.Key
                }),
            );

            return fileList;
        } catch (error) {
            console.error("Error fetching files:", error);
            return [];
        }
    };

    const pageStore = usePhotoStore((state) => state);

    useQuery({
        queryKey: ["photos", clientId],
        queryFn: fetchFilesInDirectory,
        onSuccess: (fetchedPhotos) => {
            pageStore.setAllPhotos(fetchedPhotos);
            pageStore.setVisiblePhotos();
        },
        initialData: [],
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });

    return (
        <Flex
            flexDirection={"column"}
            p="10px"
            borderRight="1px solid"
            borderColor={"wps.lightgray"}
            h={{ base: "calc(100vh - 60px)", lg: "calc(100vh - 72px)" }} // this might change for mobile responsiveness (ios)
            overflowY={"auto"}
            overflowX="hidden"
            bg="white"
            w="267px"
            zIndex={2}
            {...props}
        >
            <Flex
                w="full"
                justify={"space-between"}
                align={"center"}
                borderBottom="1px solid"
                borderColor={"wps.lightgray"}
                pb="12px"
            >
                <Text fontWeight={"bold"}>{activeTab}</Text>
                <Icon
                    as={AiOutlineClose}
                    cursor={"pointer"}
                    onClick={hideTabDetails}
                    tabIndex={0}
                />
            </Flex>

            <Layouts display={activeTab === "Layouts" ? 'flex' : 'none'} />
            <Photos display={activeTab === "Photos" ? 'flex' : 'none'} />
            <Backgrounds display={activeTab === "Backgrounds" ? 'flex' : 'none'} />
            <Stickers display={activeTab === "Stickers" ? 'flex' : 'none'} />
            <TextTab display={activeTab === "Text" ? 'flex' : 'none'} />
        </Flex>
    );
};
