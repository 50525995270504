import { create } from "zustand";
import cloneDeep from 'lodash.clonedeep';
import { useZoomStore } from "./useZoomStore";
import { saveProgress } from "../utils/progress-saver";

export const usePageStore = create(
    (set) => ({
        layoutTemplates: {},
        setLayoutTemplates: (layoutTemplates) => set(() => ({ layoutTemplates: layoutTemplates })),
        layoutType: 'photobook',
        template: {},
        loadTemplate: (template) => set(() => ({ template: template })),
        data: {},
        loadData: (data) => set(() => ({ data: data })),
        option1: null,
        setOption1: (option1) => set(() => ({ option1: option1 })),
        option2: null,
        setOption2: (option2) => set(() => ({ option2: option2 })),
        setLayoutType: (newLayoutType) =>
            set((state) => {
                useZoomStore.getState().setInitialZoom(newLayoutType);
                return {
                    template: {},
                    data: {},
                    layoutType: newLayoutType,
                    option1: null,
                    option2: null,
                    undoArr: [],
                    redoArr: [],
                    textSelectedIndex: null,
                    spreadSelectedIndex: null,
                    boxSelectedIndex: null,
                }
            }
        ),
        refreshLayout: () =>
            set((state) => {
                return {
                    data: cloneDeep(state.template)
                }
            }
        ),
        photobookLayoutIndex: 0,
        loadPhotobookLayoutIndex: (photobookLayoutIndex) => set(() => ({ photobookLayoutIndex: photobookLayoutIndex })),
        setPhotobookLayoutIndex: (photobookLayoutIndex, spreadIndex) =>
            set((state) => {
                if (state.layoutType === 'photobook' && state.option1) {
                    const newTemplate = state.layoutTemplates[state.layoutType][state.option1][photobookLayoutIndex];
                    const newData = cloneDeep(state.data);
                    const templateSpread = newTemplate.spreads[1];

                    if (spreadIndex === 'all') {
                        for (let i = 1; i < newData.spreads.length; i++) {
                            for (let j = 0; j < templateSpread.boxes.length; j++) {
                                const templateBox = templateSpread.boxes[j];
                                if (!newData.spreads[i].boxes[j]) {
                                    newData.spreads[i].boxes[j] = {}
                                }
                                newData.spreads[i].boxes[j].x = templateBox.x;
                                newData.spreads[i].boxes[j].y = templateBox.y;
                                newData.spreads[i].boxes[j].height = templateBox.height;
                                newData.spreads[i].boxes[j].width = templateBox.width;
                            }
                            newData.spreads[i].boxes = newData.spreads[i].boxes.splice(0, templateSpread.boxes.length);
                        }
                    } else if (spreadIndex) {
                        const i = spreadIndex;
                        for (let j = 0; j < templateSpread.boxes.length; j++) {
                            const templateBox = templateSpread.boxes[j];
                            if (!newData.spreads[i].boxes[j]) {
                                newData.spreads[i].boxes[j] = {}
                            }
                            newData.spreads[i].boxes[j].x = templateBox.x;
                            newData.spreads[i].boxes[j].y = templateBox.y;
                            newData.spreads[i].boxes[j].height = templateBox.height;
                            newData.spreads[i].boxes[j].width = templateBox.width;
                        }
                        newData.spreads[i].boxes = newData.spreads[i].boxes.splice(0, templateSpread.boxes.length);
                    }

                    return {
                        template: cloneDeep(newTemplate),
                        data: newData,
                        photobookLayoutIndex: photobookLayoutIndex
                    }
                }
                return {}
            }
        ),
        setPhotobookTemplate: () =>
            set((state) => {
                if (state.layoutType === 'photobook' && state.option1) {
                    const newTemplate = state.layoutTemplates[state.layoutType][state.option1][0];

                    return {
                        template: cloneDeep(newTemplate),
                        data: cloneDeep(newTemplate),
                        photobookLayoutIndex: 0
                    }
                }
                return {}
            }
        ),
        setTemplate: () =>
            set((state) => {
                if (state.option1 && state.option2) {
                    const newTemplate = state.layoutTemplates[state.layoutType][state.option1][state.option2];

                    return {
                        template: cloneDeep(newTemplate),
                        data: cloneDeep(newTemplate)
                    }
                }
                return {}
            }
        ),
        setPhoto: (spreadIndex, boxIndex, photo) => set((state) => {
            state.setUndo('boxes', spreadIndex, boxIndex, state.data.spreads[spreadIndex].boxes[boxIndex]);

            const newData = {...state.data};

            newData.spreads[spreadIndex].boxes[boxIndex].photo = photo

            if (state.onSetPhoto) {
                state.onSetPhoto();
            }

            return { data: newData }
        }),
        setEditedPhoto: (spreadIndex, boxIndex, editedPhoto) => set((state) => {
            const newData = {...state.data};
            if (newData.spreads[spreadIndex].boxes[boxIndex].photo) {
                newData.spreads[spreadIndex].boxes[boxIndex].photo.editedOriginalSrc = editedPhoto.editedOriginalSrc;
                newData.spreads[spreadIndex].boxes[boxIndex].photo.src = editedPhoto.src;
            }
            return { data: newData }
        }),
        onSetPhoto: null,
        setOnSetPhoto: (onSetPhoto) => set(() => ({ onSetPhoto: onSetPhoto })),
        setBox: (spreadIndex, boxIndex, x, y, width, height) => set((state) => {
            state.setUndo('boxes', spreadIndex, boxIndex, state.data.spreads[spreadIndex].boxes[boxIndex]);
            if (x !== null) {
                state.data.spreads[spreadIndex].boxes[boxIndex].x = x;
            }

            if (y !== null) {
                state.data.spreads[spreadIndex].boxes[boxIndex].y = y;
            }

            if (width !== null) {
                state.data.spreads[spreadIndex].boxes[boxIndex].width = width;
            }

            if (height !== null) {
                state.data.spreads[spreadIndex].boxes[boxIndex].height = height;
            }

            return { data: state.data }
        }),
        addSpread: () => set((state) => {
            state.setUndo('addSpread', 0, 0, {});
            state.data.spreads = [
                ...state.data.spreads,
                cloneDeep(state.layoutType === 'photobook' ? state.template.spreads[1] : state.template.spreads[0])
            ];
            return { data: state.data }
        }),
        addBox: (options) => set((state) => {
            if (state.spreadSelectedIndex !== null) {
                const canvasWidth = state.template.width;
                const canvasHeight = state.template.height;

                const minDimension = Math.min(canvasWidth, canvasHeight);

                const width = minDimension / 2;
                const height = minDimension / 2;
                const x = ((canvasWidth / 2) - width / 2) - state.template.margins.left;
                const y = ((canvasHeight / 2) - height / 2) - state.template.margins.top;

                const box = { x, y, width, height };

                if (options) {
                    if (options.width || options.height) {
                        if (options.width) box.width = options.width;
                        if (options.height) box.height = options.height;
                        box.x = ((canvasWidth / 2) - box.width / 2) - state.template.margins.left;
                        box.y = ((canvasHeight / 2) - box.height / 2) - state.template.margins.top;
                    }

                    if (options.photo) box.photo = options.photo;
                }

                state.data.spreads[state.spreadSelectedIndex].boxes.push(box);
                state.boxSelectedIndex = state.data.spreads[state.spreadSelectedIndex].boxes.length - 1;

                state.setUndo('addBox', state.spreadSelectedIndex, 0, { x, y, width, height });
            }

            return { data: state.data, boxSelectedIndex: state.boxSelectedIndex }
        }),
        deleteSpread: () => set((state) => {
            if (state.spreadSelectedIndex !== null) {
                state.setUndo('deleteSpread', state.spreadSelectedIndex, 0, state.data.spreads[state.spreadSelectedIndex]);
                state.data.spreads.splice(state.spreadSelectedIndex, 1);
            }

            return { data: state.data }
        }),
        setBackground: (background, spreadIndex) => set((state) => {
            if (spreadIndex !== undefined) {
                state.setUndo('background', spreadIndex, 0, state.data.spreads[spreadIndex].background);

                state.data.spreads[spreadIndex].background = {
                    src: background
                };
            } else {
                state.setUndo('background', state.spreadSelectedIndex, 0, state.data.spreads[state.spreadSelectedIndex].background);

                state.data.spreads[state.spreadSelectedIndex].background = {
                    src: background
                };
            }

            return { data: state.data }
        }),
        setEditedBackground: (spreadIndex, editedBackground) => set((state) => {
            if (state.data.spreads[spreadIndex].background) {
                state.data.spreads[spreadIndex].background.editedOriginalSrc = editedBackground.editedOriginalSrc;
                state.data.spreads[spreadIndex].background.src = editedBackground.src;
            }
            return { data: state.data }
        }),
        setBackgroundColor: (backgroundColor) => set((state) => {
            state.setUndo('backgroundColor', state.spreadSelectedIndex, 0, state.data.spreads[state.spreadSelectedIndex].backgroundColor);

            state.data.spreads[state.spreadSelectedIndex].backgroundColor = backgroundColor;
            return { data: state.data }
        }),
        spreadSelectedIndex: null,
        boxSelectedIndex: null,
        textSelectedIndex: null,
        textSelectedRef: null,
        setSpreadSelectedIndex: (spreadSelectedIndex) => set(() => ({ spreadSelectedIndex: spreadSelectedIndex })),
        setBoxSelectedIndex: (boxSelectedIndex) => set(() => ({ boxSelectedIndex: boxSelectedIndex })),
        setTextSelectedIndex: (textSelectedIndex) => set((state) => {
            if (state.textSelectedRef && state.textSelectedRef.current) {
                state.textSelectedRef.current.blur();
                state.textSelectedRef = null;
            }
            return { textSelectedIndex: textSelectedIndex, textSelectedRef: state.textSelectedRef }
        }),
        setTextSelectedRef: (textSelectedRef) => set(() => ({ textSelectedRef: textSelectedRef })),
        deleteSelectedPhotoBox: () => set((state) => {
            let spreadSelectedIndex = state.spreadSelectedIndex;
            if (state.layoutType !== 'photobook') {
                spreadSelectedIndex = 0;
            }

            if (spreadSelectedIndex !== null && state.boxSelectedIndex !== null) {
                state.setUndo('boxes', spreadSelectedIndex, state.boxSelectedIndex, state.data.spreads[spreadSelectedIndex].boxes[state.boxSelectedIndex]);
                state.data.spreads[spreadSelectedIndex].boxes[state.boxSelectedIndex].deleted = true;
            }

            return { data: state.data, spreadSelectedIndex: null, boxSelectedIndex: null }
        }),
        bringSelectedBoxToFront: () => set((state) => {
            if (state.spreadSelectedIndex !== null && state.boxSelectedIndex !== null) {
                state.setUndo('boxes', state.spreadSelectedIndex, state.boxSelectedIndex, state.data.spreads[state.spreadSelectedIndex].boxes[state.boxSelectedIndex]);
                if (state.data.spreads[state.spreadSelectedIndex].boxes[state.boxSelectedIndex].zIndex === undefined) {
                    state.data.spreads[state.spreadSelectedIndex].boxes[state.boxSelectedIndex].zIndex = 1;
                }

                if (state.data.spreads[state.spreadSelectedIndex].boxes[state.boxSelectedIndex].zIndex < 999) {
                    state.data.spreads[state.spreadSelectedIndex].boxes[state.boxSelectedIndex].zIndex += 1;
                }
            }

            return { data: state.data }
        }),
        bringSelectedBoxToBack: () => set((state) => {
            if (state.spreadSelectedIndex !== null && state.boxSelectedIndex !== null) {
                state.setUndo('boxes', state.spreadSelectedIndex, state.boxSelectedIndex, state.data.spreads[state.spreadSelectedIndex].boxes[state.boxSelectedIndex]);
                if (state.data.spreads[state.spreadSelectedIndex].boxes[state.boxSelectedIndex].zIndex === undefined) {
                    state.data.spreads[state.spreadSelectedIndex].boxes[state.boxSelectedIndex].zIndex = 1;
                }

                if (state.data.spreads[state.spreadSelectedIndex].boxes[state.boxSelectedIndex].zIndex > 0) {
                    state.data.spreads[state.spreadSelectedIndex].boxes[state.boxSelectedIndex].zIndex -= 1;
                }
            }
            return { data: state.data }
        }),
        transferContentToAnotherPage: (type, sourceSpreadIndex, destSpreadIndex, index, x, y) => set((state) => {
            const content = cloneDeep(state.data.spreads[sourceSpreadIndex][type][index])
            state.data.spreads[sourceSpreadIndex][type][index].deleted = true;
            content.x = x;
            content.y = y;
            state.data.spreads[destSpreadIndex][type].push(content);

            return { data: state.data, spreadSelectedIndex: destSpreadIndex, boxSelectedIndex: state.data.spreads[destSpreadIndex][type].length - 1 }
        }),
        initSelectedImagePhotoEditor: null,
        setInitSelectedImagePhotoEditor: (initSelectedImagePhotoEditor) => set((state) => ({ initSelectedImagePhotoEditor: () => {
            state.setUndo('reset', null, null, state.data);
            initSelectedImagePhotoEditor();
        } })),
        addText: () => set((state) => {
            let spreadSelectedIndex = 0;

            if (state.layoutType === 'photobook') {
                spreadSelectedIndex = state.spreadSelectedIndex;
            }

            const canvasWidth = state.template.width + (state.template.margins.left * 2);
            const canvasHeight = state.template.height;

            const width = Math.floor(canvasWidth / 2);
            const height = Math.floor(width / 10);
            const fontSize = height;

            const x = ((canvasWidth / 2) - (width / 2)) - state.template.margins.left;
            let y = ((canvasHeight / 2) - (height / 2)) - state.template.margins.top;

            if (state.layoutType !== 'photobook') {
                y += 2 * fontSize;
            }
            const text = 'Insert text here';

            if (!state.data.spreads[spreadSelectedIndex].texts) {
                state.data.spreads[spreadSelectedIndex].texts = [];
            }

            const fontColor = '#000';
            const textAlign = 'center'

            const fontFamily = 'Arial';
            const fontStyle = 'normal';
            const fontWeight = 'normal';

            const zIndex = state.data.spreads[spreadSelectedIndex].texts.length + 1;

            const textValue = { x, y, width, height, text, fontSize, fontColor, textAlign, fontFamily, fontStyle, fontWeight, zIndex };

            state.data.spreads[spreadSelectedIndex].texts.push(textValue);    
            
            state.setUndo('addText', spreadSelectedIndex, 0, textValue);

            return { data: state.data }
        }),
        setNumberOfPages: () => set((state) => {
            if (state.option1 && state.option2) {
                const pageCount = +state.option2
                const newSpreadCount = (pageCount / 2) + 1;
                const currSpreadCount = (state.data.spreads?.length || 0);

                if (newSpreadCount > currSpreadCount) {
                    const spreadsToAddCount = (newSpreadCount - currSpreadCount);
                    const spreadsToAdd = [];

                    for (let i = 0; i < spreadsToAddCount; i++) {
                        spreadsToAdd.push(cloneDeep(state.template.spreads[1]));
                    }

                    state.data.spreads = [
                        ...state.data.spreads,
                        ...spreadsToAdd
                    ];
                } else if (newSpreadCount < currSpreadCount) {
                    state.data.spreads = state.data.spreads.splice(0, newSpreadCount);
                }

                return { data: state.data }
            }
            return {};
        }),
        setFontSize: (fontSize) => set((state) => {
            if (state.spreadSelectedIndex !== null && state.textSelectedIndex !== null) {
                state.setUndo('texts', state.spreadSelectedIndex, state.textSelectedIndex, state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex]);
                state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontSize = fontSize;
            }
            return { data: state.data }
        }),
        incrementFontSize: () => set((state) => {
            if (state.spreadSelectedIndex !== null && state.textSelectedIndex !== null) {
                state.setUndo('texts', state.spreadSelectedIndex, state.textSelectedIndex, state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex]);
                state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontSize = (+state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontSize) + 2;

                if (state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontSize > 999) {
                    state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontSize = 999;
                }
            }
            return { data: state.data }
        }),
        decrementFontSize: () => set((state) => {
            if (state.spreadSelectedIndex !== null && state.textSelectedIndex !== null) {
                if (state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontSize >= 4) {
                    state.setUndo('texts', state.spreadSelectedIndex, state.textSelectedIndex, state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex]);
                    state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontSize = state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontSize - 2;
                }
            }
            return { data: state.data }
        }),
        setFontColor: (fontColor) => set((state) => {
            if (state.spreadSelectedIndex !== null && state.textSelectedIndex !== null) {
                state.setUndo('texts', state.spreadSelectedIndex, state.textSelectedIndex, state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex]);
                state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontColor = fontColor;
            }
            return { data: state.data }
        }),
        setFontFamily: (fontFamily) => set((state) => {
            if (state.spreadSelectedIndex !== null && state.textSelectedIndex !== null) {
                state.setUndo('texts', state.spreadSelectedIndex, state.textSelectedIndex, state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex]);
                state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontFamily = fontFamily;
            }
            return { data: state.data }
        }),
        setFontStyle: (fontStyle) => set((state) => {
            if (state.spreadSelectedIndex !== null && state.textSelectedIndex !== null) {
                state.setUndo('texts', state.spreadSelectedIndex, state.textSelectedIndex, state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex]);
                state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontStyle = fontStyle;
            }
            return { data: state.data }
        }),
        setFontWeight: (fontWeight) => set((state) => {
            if (state.spreadSelectedIndex !== null && state.textSelectedIndex !== null) {
                state.setUndo('texts', state.spreadSelectedIndex, state.textSelectedIndex, state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex]);
                state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].fontWeight = fontWeight;
            }
            return { data: state.data }
        }),
        setTextAlign: (textAlign) => set((state) => {
            if (state.spreadSelectedIndex !== null && state.textSelectedIndex !== null) {
                state.setUndo('texts', state.spreadSelectedIndex, state.textSelectedIndex, state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex]);
                state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].textAlign = textAlign;
            }
            return { data: state.data }
        }),
        setTextValue: (text, spreadIndex, textIndex) => set((state) => {
            state.setUndo('texts', spreadIndex, textIndex, state.data.spreads[spreadIndex].texts[textIndex]);
            state.data.spreads[spreadIndex].texts[textIndex].text = text;
            
            return { data: state.data }
        }),
        setTextWidthHeight: (spreadIndex, textIndex, width, height) => set((state) => {
            state.setUndo('texts', spreadIndex, textIndex, state.data.spreads[spreadIndex].texts[textIndex]);
            state.data.spreads[spreadIndex].texts[textIndex].width = width;
            state.data.spreads[spreadIndex].texts[textIndex].height = height;
            return { data: state.data }
        }),
        setText: (spreadIndex, textIndex, x, y) => set((state) => {
            state.setUndo('texts', spreadIndex, textIndex, state.data.spreads[spreadIndex].texts[textIndex]);

            if (x !== null) {
                state.data.spreads[spreadIndex].texts[textIndex].x = x;
            }

            if (y !== null) {
                state.data.spreads[spreadIndex].texts[textIndex].y = y;
            }

            return { data: state.data }
        }),
        bringSelectedTextToFront: () => set((state) => {
            if (state.spreadSelectedIndex !== null && state.textSelectedIndex !== null) {
                state.setUndo('texts', state.spreadSelectedIndex, state.textSelectedIndex, state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex]);

                if (state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].zIndex === undefined) {
                    state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].zIndex = 1;
                }

                if (state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].zIndex < 999) {
                    state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].zIndex += 1;
                }
            }

            return { data: state.data }
        }),
        bringSelectedTextToBack: () => set((state) => {
            if (state.spreadSelectedIndex !== null && state.textSelectedIndex !== null) {
                state.setUndo('texts', state.spreadSelectedIndex, state.textSelectedIndex, state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex]);

                if (state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].zIndex === undefined) {
                    state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].zIndex = 1;
                }

                if (state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].zIndex > 0) {
                    state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].zIndex -= 1;
                }
            }
            return { data: state.data }
        }),
        deleteSelectedText: () => set((state) => {
            if (state.spreadSelectedIndex !== null && state.textSelectedIndex !== null) {
                state.setUndo('texts', state.spreadSelectedIndex, state.textSelectedIndex, state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex]);

                state.data.spreads[state.spreadSelectedIndex].texts[state.textSelectedIndex].deleted = true;
            }

            return { data: state.data, spreadSelectedIndex: null, textSelectedIndex: null }
        }),
        undoArr: [],
        redoArr: [],
        setUndo: (type, spreadIndex, index, value) => set((state) => {
            if (state.undoArr.length >= 30) {
                state.undoArr.shift();
            }

            state.undoArr.push({
                type,
                spreadIndex,
                index,
                value: cloneDeep(value)
            });

            return { undoArr: state.undoArr, redoArr: [] }
        }),
        undo: () => set((state) => {
            if (state.undoArr.length > 0) {
                const data = state.undoArr.pop();

                if (data.type === 'addBox') {
                    const value = state.data.spreads[data.spreadIndex].boxes.pop();
                    const redoValue = cloneDeep(value);
                    state.redoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: redoValue
                    });
                } else if (data.type === 'addText') {
                    const value = state.data.spreads[data.spreadIndex].texts.pop();
                    const redoValue = cloneDeep(value);
                    state.redoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: redoValue
                    });
                } else if (data.type === 'addSpread') {
                    const value = state.data.spreads.pop();
                    const redoValue = cloneDeep(value);
                    state.redoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: redoValue
                    });
                } else if (data.type === 'deleteSpread') {
                    const redoValue = cloneDeep(data.value);
                    state.data.spreads.splice(data.spreadIndex, 0, redoValue);
                    state.redoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: redoValue
                    });
                } else if (data.type === 'background') {
                    const value = cloneDeep(data.value);
                    const redoValue = cloneDeep(state.data.spreads[data.spreadIndex].background);

                    state.data.spreads[data.spreadIndex].background = value;

                    state.redoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: redoValue
                    });
                } else if (data.type === 'backgroundColor') {
                    const value = cloneDeep(data.value);
                    const redoValue = cloneDeep(state.data.spreads[data.spreadIndex].backgroundColor);

                    state.data.spreads[data.spreadIndex].backgroundColor = value;

                    state.redoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: redoValue
                    });
                } else if (data.type === 'reset') {
                    const value = cloneDeep(data.value);
                    const redoValue = cloneDeep(state.data);

                    state.data = value;

                    state.redoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: redoValue
                    });
                } else {
                    const redoValue = cloneDeep(state.data.spreads[data.spreadIndex][data.type][data.index]);
                    state.data.spreads[data.spreadIndex][data.type][data.index] = data.value;
                    state.redoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: redoValue
                    });
                }
            }

            return { data: state.data, undoArr: state.undoArr, redoArr: state.redoArr }
        }),
        redo: () => set((state) => {
            if (state.redoArr.length > 0) {
                const data = state.redoArr.pop();
                if (data.type === 'addBox') {
                    const undoValue = cloneDeep(data.value);
                    state.data.spreads[data.spreadIndex].boxes.push(undoValue);
                    state.undoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: undoValue
                    });
                } else if (data.type === 'addText') {
                    const undoValue = cloneDeep(data.value);
                    state.data.spreads[data.spreadIndex].texts.push(undoValue);
                    state.undoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: undoValue
                    });
                } else if (data.type === 'addSpread') {
                    const undoValue = cloneDeep(data.value);
                    state.data.spreads.push(undoValue);
                    state.undoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: undoValue
                    });
                } else if (data.type === 'deleteSpread') {
                    state.data.spreads.splice(data.spreadIndex, 1);
                    const undoValue = cloneDeep(data.value);
                    state.undoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: undoValue
                    });
                } else if (data.type === 'background') {
                    const value = cloneDeep(data.value);
                    const undoValue = cloneDeep(state.data.spreads[data.spreadIndex].background);

                    state.data.spreads[data.spreadIndex].background = value;

                    state.undoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: undoValue
                    });
                } else if (data.type === 'backgroundColor') {
                    const value = cloneDeep(data.value);
                    const undoValue = cloneDeep(state.data.spreads[data.spreadIndex].backgroundColor);

                    state.data.spreads[data.spreadIndex].backgroundColor = value;

                    state.undoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: undoValue
                    });
                } else if (data.type === 'reset') {
                    const value = cloneDeep(data.value);
                    const undoValue = cloneDeep(state.data);

                    state.data = value;

                    state.undoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: undoValue
                    });
                } else { 
                    const undoValue = cloneDeep(state.data.spreads[data.spreadIndex][data.type][data.index]);
                    state.data.spreads[data.spreadIndex][data.type][data.index] = data.value;
                    state.undoArr.push({
                        type: data.type,
                        spreadIndex: data.spreadIndex,
                        index: data.index,
                        value: undoValue
                    });
                }
            }

            return { data: state.data, undoArr: state.undoArr, redoArr: state.redoArr }
        }),
        setUndoReset: () => set((state) => {
            state.setUndo('reset', null, null, state.data);
            return {};
        }),
        isEditingBackground: false,
        setIsEditingBackground: (isEditingBackground) => set(() => ({ isEditingBackground: isEditingBackground })),
        applyFiltersToAll: (filter) => set((state) => {
            const data = cloneDeep(state.data);

            if (state.layoutType === 'photobook') {
                const spreadCount = data.spreads.length;
                for (let i = 0; i < spreadCount; i++) {
                    if (state.isEditingBackground) {
                        const background = data.spreads[i].background;
                        if (background) {
                            data.spreads[i].background.filter = filter;
                        }
                    } else {
                        const boxCount = data.spreads[i].boxes.length;
                        for (let j = 0; j < boxCount; j++) {
                            const photo = data.spreads[i].boxes[j].photo;

                            if (photo) {
                                data.spreads[i].boxes[j].photo.filter = filter;
                            }
                        }   
                    }
                }
            }

            return { data: data }
        }),
        saveCurrentProgress: (clientId, projectId, projectName, callback) => set((state) => {
            saveProgress(clientId, state, projectId, projectName)
            .then(() => {
                if (callback) callback();
            });
            return {};
        })
    })
);
