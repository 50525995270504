import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const baseStyle = definePartsStyle({
    label: {
        color: "wps.bluegray",
        fontWeight: "medium",
    },
    control: {
        borderColor: "wps.darkgray",
        borderSize: "2px",
        width: "19px",
        height: "19px",
        _checked: {
            background: "white",
            color: "wps.darkgray",
            borderColor: "wps.darkgray",
        },
        _hover: {
            backgroundColor: "wps.white !important",
            borderColor: "wps.darkgray !important",
        },
    },
});

export const checkboxTheme = defineMultiStyleConfig({ baseStyle });
