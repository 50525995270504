import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
    createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = definePartsStyle({
    field: {
        outline: "1px solid #73777C",
        borderRadius: "0px",
    },
});

export const inputTheme = defineMultiStyleConfig({ baseStyle });
