import {
    Button,
    Flex,
    Icon,
    Image,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    Spinner,
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalBody,
    ModalFooter,
    ModalHeader
} from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { PreviewModal } from "./Preview";
import { usePageStore } from "../../states/usePageStore";
import { useCartStore } from "../../states/useCartStore";
import { Cart } from "./Cart/Cart";
import { useState, useRef } from "react";

export const Navbar = ({ isProjectSaving, setIsProjectSaving }) => {
    const {
        isOpen: isPreviewOpen,
        onOpen: onPreviewOpen,
        onClose: onPreviewClose,
    } = useDisclosure();

    const {
        isOpen: isAlertOpen,
        onOpen: onAlertOpen,
        onClose: onAlertClose,
    } = useDisclosure();

    const {
        isOpen: isDisclaimerOpen,
        onOpen: onDisclaimerOpen,
        onClose: onDisclaimerClose,
    } = useDisclosure();
    const warningModalCancelRef = useRef();
    const disclaimerModalCancelRef = useRef();

    const pageStore = usePageStore((state) => state);
    const cartStore = useCartStore((state) => state);
    const [showCart, setShowCart] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertType, setAlertType] = useState(null);

    return (
        <Flex
            as="nav"
            h="full"
            bg="wps.white"
            position="fixed"
            height={{ lg: "72px" }}
            justify="space-between"
            align="center"
            px="1rem"
            py="12px"
            borderBottom={"2px solid"}
            borderColor="wps.lightgray"
            zIndex="1002" // necessary to display dropdown on top of canvas actions
            width="full"
        >
            <Flex align="center" gap={{ base: "16px", md: "34px" }}>
                <Brand />
            </Flex>
            <Flex gap={"20px"} h={"full"} align="center">
           
                <NavbarButton onClick={() => {
                    window.location = `${process.env.REACT_APP_SHOPIFY_URL}`;
                }} bg="wps.white">
                    Go Back to Store
                </NavbarButton>
                            
                <NavbarButton isDisabled={isProjectSaving} onClick={() => {
                    const clientId = localStorage.getItem('clientId');
                    const projectId = localStorage.getItem('projectId');
                    const projectName = localStorage.getItem('projectName');

                    if (clientId && projectId && projectName) {
                        setIsProjectSaving(true);
                        pageStore.saveCurrentProgress(clientId, projectId, projectName, () => {
                            setIsProjectSaving(false);
                        });
                    }
                }} bg="wps.white">
                    {isProjectSaving && (<Spinner size="sm" />)}
                    {!isProjectSaving && (<Text>Save</Text>)}
                </NavbarButton>
                <NavbarButton bg="wps.lightbeige" onClick={() => {
                    setShowCart(!showCart);
                }}>
                    {showCart ? "Hide Cart" : "Show Cart"}
                </NavbarButton>
                <NavbarButton 
                    isDisabled={pageStore.option1 === null || pageStore.option2 === null} 
                    bg="wps.darkgray"
                    color="white"
                    onClick={() => {
                        let hasMissingPhotos = false;
                        const spreads = pageStore.data.spreads;
                        for (let i = 0; i < spreads.length; i++) {
                            if (hasMissingPhotos) break;
                            const boxes = spreads[i].boxes;
                            for (let j = 0; j < boxes.length; j++) {
                                const box = boxes[j];
                                if (!box.deleted && (!box.photo || box.photo.src === null)) {
                                    hasMissingPhotos = true;
                                    break;
                                } 
                            }
                        }

                        let hasMissingPages = false;
                        if (pageStore.layoutType === 'photobook' &&
                            (pageStore.option2 && pageStore.data.spreads?.length > 0 && (+pageStore.option2 / 2) >= pageStore.data.spreads.length)
                        ) {
                            hasMissingPages = true;
                        }

                        if (hasMissingPages) {
                            setAlertType('hasMissingPages');
                            setAlertMessage('Your photo safe has missing pages, do you want to proceed?')
                            onAlertOpen();
                        }
                        else if (hasMissingPhotos) {
                            setAlertType('hasMissingPhotos');
                            setAlertMessage('Your project has missing photos, do you want to proceed?')
                            onAlertOpen();
                        } else {
                            onPreviewOpen();
                        }
                    }}>
                    Add to Cart
                </NavbarButton>
                <NavbarButton
                    bg="wps.darkgray"
                    color="white"
                    onClick={() => {
                        if (cartStore.items.length > 0) {
                            let minOrder = 20;
                            let totalQty = 0;
                            for (let i = 0; i < cartStore.items.length; i++) {
                                const mo = cartStore.items[i].minOrder;
                                if (mo < minOrder) {
                                    minOrder = mo;
                                }

                                totalQty += cartStore.items[i].quantity;
                            }

                            if (totalQty < minOrder) {
                                setAlertType('quantity');
                                setAlertMessage(`You must have a order a minimum quantity of ${minOrder}. Current quantity in cart: ${totalQty}.`)
                                onAlertOpen();
                            } else {
                                onDisclaimerOpen();
                            }
                        }
                    }}
                    isDisabled={cartStore.items.length === 0}
                >
                    Checkout
                    <Icon as={FiChevronRight} boxSize={"20px"} ml="4px" />
                </NavbarButton>
            </Flex>
            {showCart && (<Cart setShowCart={setShowCart}></Cart>)}
            <PreviewModal setShowCart={setShowCart} isOpen={isPreviewOpen} onClose={onPreviewClose} />
            <AlertDialog
                isOpen={isAlertOpen}
                leastDestructiveRef={warningModalCancelRef}
                onClose={onAlertClose}
                closeOnEsc={false}
                closeOnOverlayClick={false}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        { alertType === 'hasMissingPages' && (<>Missing Pages Warning</>)}
                        { alertType === 'hasMissingPhotos' && (<>Missing Photos Warning</>)}
                        { alertType === 'quantity' && (<>Minimum Quantity Warning</>)}
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <Text> {alertMessage} </Text>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button ref={warningModalCancelRef} onClick={() => {
                            onAlertClose();
                        }}>
                            Cancel
                        </Button>
                        {alertType !== 'quantity' && (<Button 
                            bg={'wps.lightbeige'}
                            onClick={() => {
                                onAlertClose();
                                onPreviewOpen();
                            }}
                            ml={3}
                        >
                            Proceed
                        </Button>)}
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>

            {/* <AlertDialog
                isOpen={isDisclaimerOpen}
                leastDestructiveRef={disclaimerModalCancelRef}
                onClose={onDisclaimerClose}
                closeOnEsc={false}
                closeOnOverlayClick={false}
            > */}
            <Modal isOpen={isDisclaimerOpen} onClose={onDisclaimerClose}>
                <ModalOverlay />
                <ModalContent maxW="900px">
                    <ModalHeader  
                    fontFamily="Bebas Neue, sans-serif"
                    fontSize={"23px"}
                    fontWeight={"normal"}
                    borderBottom={"1px solid #DDDEDF"}>
                        DISCLAIMER
                    </ModalHeader>

                    <ModalBody>
                        <Text as='b'>
                            Accuracy of Content or Information
                        </Text>

                        <Text mt="12px">
                            1. Spelling and Grammatical Error
                        </Text>

                        <Text ml={"20px"}>
                            Any spelling and grammatical errors won't be corrected once you have proceeded to place your order.
                        </Text>

                        <Text mt="12px">
                            2. Layout and Composition.
                        </Text>

                        <Text ml={"20px"}>
                            Rearranging layout and composition cannot be revised once you have proceeded to place your order.
                        </Text>

                        <Text mt="12px">
                            3. Number of Pages
                        </Text>

                        <Text ml={"20px"}>
                            In PhotoSafe, the cover page is not included in the number of pages. Deleted pages including its layout design would be considered as an incomplete number of spreads.
                        </Text>

                        <Text mt="12px">
                            4. File Format
                        </Text>

                        <Text ml={"20px"} mb="20px">
                            Upload PNG or JPEG photos with RGB color mode. 
                        </Text>

                        <Text as='b'>
                            Print
                        </Text>
                        <Text mt="12px">
                            1. Color
                        </Text>
                        <Text ml={"20px"}>
                            We cannot guarantee that the color you see on your screen accurately portrays the true color of the print.
                        </Text>

                        <Text mt="12px">
                            2. Cutting Edge
                        </Text>

                        <Text ml={"20px"}>
                            Wonder Photo Shop shall not be liable for any cropped images outside the layout trim marks.
                        </Text>

                        <Text mt="12px">
                            3. Printing Substrate 
                        </Text>
                        <Text ml={"20px"}>
                            Our substrates vary in composition, base color, and machines. Such variations affect the way colors look when printed in different materials.
                        </Text>

                        <Text mt="12px">
                            4. Image Quality Disclaimer
                        </Text>

                        <Text ml={"20px"}>
                            The original image size, file size and image quality of your photo is the same as the uploaded photo on WPS website. Wonder Photo Shop are not responsible for low-resolution images that can cause grainy and pixelated printed output. 
                        </Text>

                    </ModalBody>

                    <ModalFooter>
                        <Button ref={disclaimerModalCancelRef} onClick={() => {
                            onDisclaimerClose();
                        }}>
                            Cancel
                        </Button>
                        <Button 
                            bg={'wps.lightbeige'}
                            onClick={() => {
                                const cartItems = encodeURIComponent(JSON.stringify(cartStore.items));
                                window.location = `${process.env.REACT_APP_SHOPIFY_URL}/cart?cartItems=${cartItems}`;
                            }}
                            ml={3}
                        >
                            Proceed Checkout
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

const SaveAndPreview = ({ isProjectSaving, setIsProjectSaving, pageStore, onPreviewOpen }) => {
    return (
        <>
            <NavbarButton isDisabled={isProjectSaving} onClick={() => {
                const clientId = localStorage.getItem('clientId');
                const projectId = localStorage.getItem('projectId');
                const projectName = localStorage.getItem('projectName');

                if (clientId && projectId && projectName) {
                    setIsProjectSaving(true);
                    pageStore.saveCurrentProgress(clientId, projectId, projectName, () => {
                        setIsProjectSaving(false);
                    });
                }
            }} bg="wps.white">
                {isProjectSaving && (<Spinner size="sm" />)}
                {!isProjectSaving && (<Text>Save</Text>)}
            </NavbarButton>
            <NavbarButton isDisabled={pageStore.option1 === null || pageStore.option2 === null} bg="wps.lightbeige" onClick={onPreviewOpen}>
                Preview
            </NavbarButton>
        </>
    );
};

const Brand = () => {
    return (
        <Image
            src="/images/wps-logo.png"
            w={{ base: "200px", lg: "250px" }}
            display={{ base: "none", sm: "block" }}
        />
    );
};

const NavbarButton = ({ isDisabled, children, bg, color = "black", onClick }) => {
    return (
        <Button
            bg={bg}
            h="full"
            borderRadius={"0px"}
            border="1px solid"
            borderColor={"wps.darkgray"}
            outline="0px solid black"
            px="30px"
            py="16px"
            _hover={{
                bg,
                outline: "1px solid black",
            }}
            color={color}
            onClick={onClick}
            isDisabled={isDisabled}
        >
            {children}
        </Button>
    );
};
