import { 
    Button, 
    Flex, 
    Icon, 
    IconButton, 
    Show, 
    Menu, 
    MenuButton, 
    MenuList, 
    Box,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    Checkbox,
    Tooltip
} from "@chakra-ui/react";
import { usePageStore } from "../../states/usePageStore";
import { usePhotoEditor } from "../../states/usePhotoEditor";
import { ReactComponent as Undo } from "./images/Undo.svg";
import { ReactComponent as Redo } from "./images/Redo.svg";
import { ReactComponent as DeletePage } from "./images/DeletePage.svg";
import { ReactComponent as AddPage } from "./images/AddPage.svg";
import { ReactComponent as AddImage } from "./images/AddImage.svg";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineZoomIn, MdModeEdit } from "react-icons/md";
import { useZoomStore } from "../../states/useZoomStore";
import { LuImagePlus, LuImageMinus, LuImageOff } from "react-icons/lu";
import { useRef } from "react";
import { MdHelpCenter } from "react-icons/md";

export const ActionButtons = ({openWalkthrough, showGrid, setShowGrid}) => {
    const pageStore = usePageStore((state) => state);
    const photoEditor = usePhotoEditor((state) => state);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const warningModalCancelRef = useRef();

    const hasValidPhotoToEdit = () => {
        let spreadIndex = pageStore.spreadSelectedIndex;
        const boxIndex = pageStore.boxSelectedIndex;

        if (pageStore.layoutType === 'photoPrint' || pageStore.layoutType === 'decoPrint') {
            spreadIndex = 0;
        }

        if (spreadIndex === null) return false;
        if (boxIndex === null) return false;
        if (pageStore.data.spreads == undefined || pageStore.data.spreads.length === 0) return false;
        if (pageStore.data.spreads[spreadIndex] == undefined || pageStore.data.spreads[spreadIndex].boxes == undefined || pageStore.data.spreads[spreadIndex].boxes.length === 0) return false;
        if (pageStore.data.spreads[spreadIndex].boxes[boxIndex] == undefined || pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo == undefined) return false;
        if (pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo.isSticker) return false;

        return true
    }

    return (
        <Flex
            position="fixed"
            top="72px"
            h="60px"
            w="100%"
            zIndex="1000"
            background="wps.white"
            justify="center"
            gap={"10px"}
            py={"8px"}
            borderBottom={"1px solid #DDDEDF"}
        >
            <Flex h="full" justify={"center"} align="center">
                <Icon
                    as={MdHelpCenter}
                    boxSize={"25px"} 
                    color={"#2F2F2F"}
                    cursor={"pointer"}
                    onClick={() => {
                        openWalkthrough();
                    }}
                    tabIndex={0}
                />
            </Flex>
            <Grid showGrid={showGrid} setShowGrid={setShowGrid} />
            <Flex style={{marginLeft: '10px'}}></Flex>
            <ScreenZoomWrapper />
            <Flex style={{marginLeft: '10px'}}></Flex>
            <Flex gap={"10px"}>
                <ActionButton
                    tooltip="Undo Action"
                    leftIcon={<Icon as={Undo} boxSize={"20px"} />}
                    isDisabled={pageStore.undoArr.length === 0}
                    onClick={() => {
                        pageStore.undo();
                    }}
                />
                <ActionButton
                    tooltip="Redo Action"
                    leftIcon={<Icon as={Redo} boxSize={"20px"} />}
                    isDisabled={pageStore.redoArr.length === 0}
                    onClick={() => {
                        pageStore.redo();
                    }}
                />
            </Flex>
            {pageStore.layoutType === "photobook" && (
                <>
                    <Show above="sm">
                        {(pageStore.option2 && pageStore.data.spreads?.length > 0 && (+pageStore.option2 / 2) >= pageStore.data.spreads.length) && (
                            <ActionButton
                                tooltip="Add Page"
                                title="Add Page"
                                leftIcon={<Icon as={AddPage} boxSize={"20px"} />}
                                onClick={() => {
                                    pageStore.addSpread();
                                    pageStore.setTextSelectedIndex(null);
                                    pageStore.setBoxSelectedIndex(null);
                                    pageStore.setSpreadSelectedIndex(null);
                                }}
                            />
                        )}

                        {pageStore.spreadSelectedIndex !== null && pageStore.spreadSelectedIndex > 0 && (
                            <ActionButton
                                tooltip="Delete Page"
                                leftIcon={
                                    <Icon
                                        as={DeletePage}
                                        boxSize={"20px"}
                                    />
                                }
                                onClick={() => {
                                    onOpen();
                                }}
                            />
                        )}
                    </Show>
                    <Show below="sm">
                        {/* <SmallScreenActionButton
                            icon={<Icon as={AddPage} boxSize={"20px"} />}
                            onClick={() => {
                                pageStore.addSpread();
                                pageStore.setTextSelectedIndex(null);
                                pageStore.setBoxSelectedIndex(null);
                                pageStore.setSpreadSelectedIndex(null);
                            }}
                        /> */}
                        {pageStore.spreadSelectedIndex !== null && (
                            <SmallScreenActionButton
                                icon={
                                    <Icon
                                        as={DeletePage}
                                        boxSize={"20px"}
                                    />
                                }
                                onClick={() => {
                                    if (
                                        pageStore.spreadSelectedIndex !==
                                        null
                                    ) {
                                        pageStore.deleteSpread();
                                        pageStore.setTextSelectedIndex(null);
                                        pageStore.setBoxSelectedIndex(null);
                                        pageStore.setSpreadSelectedIndex(null);
                                    }
                                }}
                            />
                        )}
                    </Show>

                    {pageStore.spreadSelectedIndex !== null && (
                        <>
                            <Show above="sm">
                                <ActionButton
                                    tooltip="Add Photo"
                                    leftIcon={<Icon as={LuImagePlus} boxSize={"20px"} />}
                                    onClick={() => {
                                        pageStore.addBox();
                                    }}
                                />
                                {pageStore.boxSelectedIndex !== null && (
                                    <>
                                        {(hasValidPhotoToEdit() && !photoEditor.isEditMode) && (
                                            <>
                                                <ActionButton
                                                    tooltip="Edit Photo"
                                                    leftIcon={<MdModeEdit size={18} />}
                                                    onClick={() => {
                                                        pageStore.initSelectedImagePhotoEditor();
                                                    }}
                                                />
                                                <ActionButton
                                                    tooltip="Reset Photo"
                                                    leftIcon={<LuImageOff size={18} />}
                                                    onClick={() => {
                                                        if ((pageStore.layoutType === 'photoPrint' || pageStore.layoutType === 'decoPrint') && pageStore.boxSelectedIndex !== null) {
                                                            pageStore.setPhoto(0, pageStore.boxSelectedIndex, undefined);
                                                        } else if (pageStore.spreadSelectedIndex !== null && pageStore.boxSelectedIndex !== null) {
                                                            pageStore.setPhoto(pageStore.spreadSelectedIndex, pageStore.boxSelectedIndex, undefined);
                                                        }
                                                    }}
                                                />
                                            </>
                                        )}

                                        {photoEditor.isEditMode && (
                                            <ActionButton
                                                tooltip="Done Editing"
                                                leftIcon={<MdModeEdit size={18} />}
                                                onClick={() => {
                                                    photoEditor.exitEditMode();
                                                }}
                                            />
                                        )}

                                        <ActionButton
                                            tooltip="Delete Photo"
                                            leftIcon={<Icon as={LuImageMinus} boxSize={"20px"} />}
                                            onClick={() => {
                                                pageStore.deleteSelectedPhotoBox();
                                            }}
                                        />
                                    </>
                                )}
                            </Show>
                            <Show below="sm">
                                <SmallScreenActionButton
                                    icon={<Icon as={LuImagePlus} boxSize={"20px"} />}
                                    onClick={() => {
                                        pageStore.addBox();
                                    }}
                                />
                            </Show>
                        </>
                    )}
                </>

                
            )}

            {pageStore.layoutType !== "photobook" && (
                <>
                    {pageStore.data.spreads && (
                        <ActionButton
                            isDisabled={pageStore.data.spreads.length >= 50}
                            tooltip="Add Page (Maximum 50 Pages)"
                            title="Add Page"
                            leftIcon={<Icon as={AddPage} boxSize={"20px"} />}
                            onClick={() => {
                                if (pageStore.data.spreads.length < 50) {
                                    pageStore.addSpread();
                                    pageStore.setTextSelectedIndex(null);
                                    pageStore.setBoxSelectedIndex(null);
                                    pageStore.setSpreadSelectedIndex(null);
                                }
                            }}
                        />
                    )}

                    {pageStore.data.spreads && (pageStore.spreadSelectedIndex !== null && pageStore.spreadSelectedIndex !== 0)  && (
                        <ActionButton
                            tooltip="Delete Page"
                            title="Delete Page"
                            leftIcon={
                                <Icon
                                    as={DeletePage}
                                    boxSize={"20px"}
                                />
                            }
                            onClick={() => {
                                onOpen();
                            }}
                        />
                    )}

                    {(hasValidPhotoToEdit() && !photoEditor.isEditMode) && (
                        <>
                            <ActionButton
                                tooltip="Edit Photo"
                                leftIcon={<MdModeEdit size={18} />}
                                onClick={() => {
                                    pageStore.initSelectedImagePhotoEditor();
                                }}
                            />
                            <ActionButton
                                tooltip="Reset Photo"
                                leftIcon={<LuImageOff size={18} />}
                                onClick={() => {
                                    if ((pageStore.layoutType === 'photoPrint' || pageStore.layoutType === 'decoPrint') && pageStore.boxSelectedIndex !== null) {
                                        pageStore.setPhoto(0, pageStore.boxSelectedIndex, undefined);
                                    } else if (pageStore.spreadSelectedIndex !== null && pageStore.boxSelectedIndex !== null) {
                                        pageStore.setPhoto(pageStore.spreadSelectedIndex, pageStore.boxSelectedIndex, undefined);
                                    }
                                }}
                            />
                        </>
                    )}

                    {photoEditor.isEditMode && (
                        <ActionButton
                            tooltip="Done Editing"
                            leftIcon={<MdModeEdit size={18} />}
                            onClick={() => {
                                photoEditor.exitEditMode();
                            }}
                        />
                    )}
                </>
            )}

            

            {pageStore.textSelectedIndex !== null && (
                <ActionButton
                    tooltip="Delete Text"
                    leftIcon={<Icon
                        as={DeletePage}
                        boxSize={"20px"}
                    />}
                    onClick={() => {
                        pageStore.deleteSelectedText();
                    }}
                />
            )}

            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={warningModalCancelRef}
                onClose={() => {
                    onClose();
                }}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        Delete Page
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        Deleting a page will delete your progress. Would you like to proceed?
                    </AlertDialogBody>

                    <AlertDialogFooter>
                    <Button ref={warningModalCancelRef} onClick={() => {
                        onClose();
                    }}>
                        Cancel
                    </Button>
                    <Button 
                        bg='wps.lightbeige'
                        onClick={() => {
                            if (
                                pageStore.spreadSelectedIndex !==
                                null
                            ) {
                                pageStore.deleteSpread();
                                pageStore.setTextSelectedIndex(null);
                                pageStore.setBoxSelectedIndex(null);
                                pageStore.setSpreadSelectedIndex(null);
                            }
                            onClose();
                        }}
                        ml={3}
                    >
                        Proceed
                    </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </Flex>
    );
};

const ActionButton = ({ tooltip = '', leftIcon, title, isDisabled = false, onClick }) => {
    return (
        <Tooltip hasArrow label={tooltip} placement='top'>
            <Button
                leftIcon={leftIcon}
                bg="transparent"
                px={"0px"}
                iconSpacing={"0px"}
                fontWeight={"semibold"}
                color={"wps.black"}
                isDisabled={isDisabled}
                onClick={() => {
                    if (onClick) {
                        onClick();
                    }
                }}
            >
                {title}
            </Button>
        </Tooltip>
    );
};

const SmallScreenActionButton = ({ icon, isDisabled = false, onClick }) => {
    return <IconButton icon={icon} isDisabled={isDisabled} onClick={onClick} />;
};

const ScreenZoomWrapper = () => {
    return (
        <>
            <Show above="lg">
                <ScreenZoom />
            </Show>
            <Show below="lg">
                <Menu>
                    <MenuButton>
                        <MdOutlineZoomIn size={24} />
                    </MenuButton>

                    <MenuList bg="white" border="0px" minW="unset" p={0}>
                        <Box
                            p={4}
                            border="2px solid"
                            borderColor={"wps.black"}
                            borderRadius={0}
                        >
                            <ScreenZoom />
                        </Box>
                    </MenuList>
                </Menu>
            </Show>
        </>
    );
};

const ScreenZoom = () => {
    const { zoom, incrementZoom, decrementZoom } = useZoomStore(
        (state) => state,
    );

    return (
        <Flex
            border="1px solid"
            align="center"
            marginTop={'6px'}
            marginBottom={'6px'}
            height={'34px'}
            borderColor={"wps.bluegray"}
            maxW="120px"
            bg="wps.white"
        >
            <Flex h="full" justify={"center"} align="center">
                <IconButton
                    icon={<AiOutlineMinus />}
                    bg="transparent"
                    borderRadius={"0px"}
                    h="full"
                    p="8px"
                    cursor={"pointer"}
                    tabIndex={0}
                    onClick={decrementZoom}
                />
            </Flex>
            <Box
                border="none"
                w="80px"
                textAlign={"center"}
                borderRadius={"0px"}
                outline="0px solid black"
                _focus={{
                    outline: "1px solid black",
                }}
            >{`${zoom}%`}</Box>
            <Flex h="full" justify={"center"} align="center">
                <IconButton
                    icon={<AiOutlinePlus />}
                    bg="transparent"
                    borderRadius={"0px"}
                    h="full"
                    p="8px"
                    cursor={"pointer"}
                    tabIndex={0}
                    onClick={incrementZoom}
                />
            </Flex>
        </Flex>
    );
};

const Grid = ({showGrid, setShowGrid}) => {
    return (
        <Checkbox
            isChecked={showGrid}
            onChange={(e) => setShowGrid(e.target.checked)}
        >
            Grid
        </Checkbox>
    )
};