import {
    Button,
    Checkbox,
    Flex,
    GridItem,
    Icon,
    Image,
    SimpleGrid,
    Stack,
    Tooltip
} from "@chakra-ui/react";
import { UploadPhotoButton } from "../common";
import { ReactComponent as Autofill } from "../../../../images/LeftSidebar/Autofill.svg";
import { EmptyPhotosPlaceholder } from "../common/EmptyPhotosPlaceholder";
import { useDragPhotoStore } from "../../../../states/useDragPhoto";
import { usePhotoStore } from "../../../../states/usePhotoStore";
import { useEffect, useState } from "react";
import { usePageStore } from "../../../../states/usePageStore";
import { DeletePhotoButton } from "../common/DeletePhotoButton";
import { MdOutlineWarningAmber } from "react-icons/md";

export const Photos = ({ display }) => {
    const { setDraggedPhoto } = useDragPhotoStore((state) => state);
    const photoStore = usePhotoStore((state) => state);
    const pageStore = usePageStore((state) => state);

    const [hoveringPhotoIndex, setHoveringPhotoIndex] = useState(-1);
    const [lowResPhotos, setLowResPhotos] = useState([]);

    useEffect(() => {
        photoStore.setVisiblePhotos();

        pageStore.setOnSetPhoto(() => {
            photoStore.setVisiblePhotos();
        });
    }, [])

    useEffect(() => {
        photoStore.setVisiblePhotos();
    }, [photoStore.hideUsedPhotos]);

    const handleAutofill = () => {
        let index = 0;
        const unusedPhotos = photoStore.getUnusedPhotos();
        const spreads = pageStore.data.spreads;

        for (let i = 0; i < spreads.length; i++) {
            const boxes = spreads[i].boxes || [];
            for (let j = 0; j < boxes.length; j++) {
                const box = boxes[j];
                const photo = box.photo;
                if (!photo || !photo.src) {
                    pageStore.setPhoto(i, j, unusedPhotos[index++]);
                }
            }
        }
    };

    const isLowRes = (src) => {
        return lowResPhotos.includes(src);
    }

    return (
        <Flex
            w="full"
            justify={"flex-start"}
            py="16px"
            flexDirection={"column"}
            gap="10px"
            display={display}
            marginBottom={"250px"}
        >
            <UploadPhotoButton />

            <Flex justify={"space-between"} >
                <Button
                    leftIcon={<Icon as={Autofill} boxSize={"26px"} />}
                    bg="unset"
                    p="0"
                    _hover={{}}
                    _active={{}}
                    mr="4px"
                    color={"wps.buttongray"}
                    onClick={handleAutofill}
                >
                    Autofill
                </Button>
            </Flex>
            <Checkbox
                mb={"4px"}
                isChecked={photoStore.hideUsedPhotos}
                onChange={(e) => photoStore.setHideUsedPhotos(e.target.checked)}
            >
                Hide used photos
            </Checkbox>


            {photoStore.visiblePhotos.length > 0 ? (
                <SimpleGrid columns={2} gap={2}>
                    {photoStore.visiblePhotos?.map((photo, i) => (
                        <GridItem key={photo.src} position={"relative"}>
                            <Stack position={"relative"}>
                                <button>
                                    <Image
                                        src={photo.src}
                                        objectFit={"cover"}
                                        objectPosition={"center"}
                                        h="120px"
                                        w="120px"
                                        onMouseDown={() => {
                                            setDraggedPhoto(photo.src, photo.originalSrc);
                                        }}
                                        onMouseEnter={() => {
                                            setHoveringPhotoIndex(i);
                                        }}
                                        onMouseLeave={() => {
                                            setHoveringPhotoIndex(-1)
                                        }}
                                        onLoad={(event) => {
                                            const height = event.target.naturalHeight;
                                            const width = event.target.naturalWidth;

                                            if (width <= 250 || height <= 250) {
                                                setLowResPhotos([...lowResPhotos, photo.src])
                                            }
                                        }} 
                                    />
                                </button>

                                { hoveringPhotoIndex === i && (
                                    <DeletePhotoButton i={i} file={photo.s3FileName} setHoveringPhotoIndex={setHoveringPhotoIndex} />
                                )}

                                {isLowRes(photo.src) && (
                                    <Tooltip 
                                        label="This image has a low resolution. Please ensure to use high resolution photos for better printing quality."
                                        bg="wps.darkgray"
                                    >
                                            
                                     <div
                                        style={{
                                            width: `30px`,
                                            height: `30px`,
                                            borderRadius: '100%',
                                            position: "absolute",
                                            bottom: "5px",
                                            right: "0px",
                                            cursor: "help"
                                        }}
                                    >
                                            <Icon 
                                                as={MdOutlineWarningAmber} 
                                                boxSize={"25px"} 
                                                margin={"0"} 
                                                color={"#DDDEDF"}
                                                style={{
                                                    margin: "5px 0px 0px 0px"
                                                }} 
                                            />
                                    </div>
                                    </Tooltip>
                                )}
                            </Stack>
                        </GridItem>
                    ))}
                </SimpleGrid>
            ) : (
                <EmptyPhotosPlaceholder />
            )}
        </Flex>
    );
};
