export const PHOTO_SAFE_LAYOUTS = {
    '6x8': {
        cover: {
            width: 6.5,
            height: 8,
            margins: {
                top: 1,
                left: 1,
                right: 1,
                bottom: 1,
            },
        },
        variants: {
            '20': {
                variantId: '42344620195897',
                price: 1961
            },
            '22': {
                variantId: '42344620228665',
                price: 2140
            },
            '24': {
                variantId: '42344620261433',
                price: 2320
            },
            '26': {
                variantId: '42344620294201',
                price: 2499
            },
            '28': {
                variantId: '42344620326969',
                price: 2678
            },
            '30': {
                variantId: '42344620359737',
                price: 2857
            },
            '32': {
                variantId: '42344620392505',
                price: 3036
            },
            '34': {
                variantId: '42344620425273',
                price: 3216
            },
            '36': {
                variantId: '42344620458041',
                price: 3395
            },
            '38': {
                variantId: '42344620490809',
                price: 3574
            },
            '40': {
                variantId: '42344620523577',
                price: 3753
            }
        },
        width: 6,
        height: 8,
        margins: {
            top: 1,
            left: 1,
            right: 1,
            bottom: 1,
        },
        templates: [
            {
                rows: 2,
                columns: 2,
                gap: 0.5,
                configuration: [
                    [1, 1],
                    [1, 1]
                ]
            },
            {
                rows: 3,
                columns: 3,
                gap: 0.5,
                configuration: [
                    [1, 1, 1],
                    [1, 1, 1],
                    [1, 1, 1]
                ]
            },
            {
                rows: 2,
                columns: 3,
                gap: 0.5,
                configuration: [
                    [1, 1, 1],
                    [1, 1, 1]
                ]
            },
            {
                rows: 3,
                columns: 2,
                gap: 0.5,
                configuration: [
                    [1, 1],
                    [1, 1],
                    [1, 1],
                ]
            },
            {
                rows: 3,
                columns: 1,
                gap: 0.5,
                configuration: [
                    [1],
                    [1],
                    [1]
                ]
            },
            {
                rows: 2,
                columns: 2,
                gap: 0.5,
                configuration: [
                    [1, 0],
                    [0, 1]
                ]
            }
        ]
    },
    '8x8': {
        cover: {
            width: 8.5,
            height: 8,
            margins: {
                top: 1,
                left: 1,
                right: 1,
                bottom: 1,
            },
        },
        variants: {
            '20': {
                variantId: '42344620556345',
                price: 2282
            },
            '22': {
                variantId: '42344620589113',
                price: 2492
            },
            '24': {
                variantId: '42344620621881',
                price: 2703
            },
            '26': {
                variantId: '42344620654649',
                price: 2913
            },
            '28': {
                variantId: '42344620687417',
                price: 3124
            },
            '30': {
                variantId: '42344620720185',
                price: 3335
            },
            '32': {
                variantId: '42344620752953',
                price: 3545
            },
            '34': {
                variantId: '42344620785721',
                price: 3756
            },
            '36': {
                variantId: '42344620818489',
                price: 3966
            },
            '38': {
                variantId: '42344620851257',
                price: 4177
            },
            '40': {
                variantId: '42344620884025',
                price: 4388
            },
        },
        width: 8,
        height: 8,
        margins: {
            top: 1,
            left: 1,
            right: 1,
            bottom: 1,
        },
        templates: [
            {
                rows: 2,
                columns: 2,
                gap: 0.5,
                configuration: [
                    [1, 1],
                    [1, 1]
                ]
            },
            {
                rows: 3,
                columns: 3,
                gap: 0.5,
                configuration: [
                    [1, 1, 1],
                    [1, 1, 1],
                    [1, 1, 1]
                ]
            },
            {
                rows: 2,
                columns: 3,
                gap: 0.5,
                configuration: [
                    [1, 1, 1],
                    [1, 1, 1]
                ]
            },
            {
                rows: 3,
                columns: 2,
                gap: 0.5,
                configuration: [
                    [1, 1],
                    [1, 1],
                    [1, 1],
                ]
            },
            {
                rows: 3,
                columns: 1,
                gap: 0.5,
                configuration: [
                    [1],
                    [1],
                    [1]
                ]
            },
            {
                rows: 2,
                columns: 2,
                gap: 0.5,
                configuration: [
                    [1, 0],
                    [0, 1]
                ]
            }
        ]
    },
    '8x10': {
        cover: {
            width: 8.5,
            height: 10,
            margins: {
                top: 1,
                left: 1,
                right: 1,
                bottom: 1,
            },
        },
        variants: {
            '20': {
                variantId: '42344620916793',
                price: 2472
            },
            '22': {
                variantId: '42344620949561',
                price: 2699
            },
            '24': {
                variantId: '42344620982329',
                price: 2926
            },
            '26': {
                variantId: '42344621015097',
                price: 3153
            },
            '28': {
                variantId: '42344621047865',
                price: 3380
            },
            '30': {
                variantId: '42344621080633',
                price: 3607
            },
            '32': {
                variantId: '42344621113401',
                price: 3834
            },
            '34': {
                variantId: '42344621146169',
                price: 4061
            },
            '36': {
                variantId: '42344621178937',
                price: 4288
            },
            '38': {
                variantId: '42344621211705',
                price: 4515
            },
            '40': {
                variantId: '42344621244473',
                price: 4742
            },
        },
        width: 8,
        height: 10,
        margins: {
            top: 1,
            left: 1,
            right: 1,
            bottom: 1,
        },
        templates: [
            {
                rows: 2,
                columns: 2,
                gap: 0.5,
                configuration: [
                    [1, 1],
                    [1, 1]
                ]
            },
            {
                rows: 3,
                columns: 3,
                gap: 0.5,
                configuration: [
                    [1, 1, 1],
                    [1, 1, 1],
                    [1, 1, 1]
                ]
            },
            {
                rows: 2,
                columns: 3,
                gap: 0.5,
                configuration: [
                    [1, 1, 1],
                    [1, 1, 1]
                ]
            },
            {
                rows: 3,
                columns: 2,
                gap: 0.5,
                configuration: [
                    [1, 1],
                    [1, 1],
                    [1, 1],
                ]
            },
            {
                rows: 3,
                columns: 1,
                gap: 0.5,
                configuration: [
                    [1],
                    [1],
                    [1]
                ]
            },
            {
                rows: 2,
                columns: 2,
                gap: 0.5,
                configuration: [
                    [1, 0],
                    [0, 1]
                ]
            }
        ]
    }
};