import { create } from "zustand";

export const useDragPhotoStore = create((set) => ({
    draggedPhotoSrc: null,
    draggedPhotoOriginalSrc: null,
    draggedContent: null,
    draggedFilter: 'auto',
    draggedStickerSrc: null,
    setDraggedPhoto: (imageSrc, originalSrc, filter) => set(() => ({ draggedPhotoSrc: imageSrc, draggedPhotoOriginalSrc: originalSrc, draggedFilter: filter || 'auto' })),
    setDraggedSticker: (imageSrc) => set(() => ({ draggedStickerSrc: imageSrc })),
    setDraggedContent: (draggedContent) => set(() => ({draggedContent: draggedContent}))
}));
