import { Flex, GridItem, SimpleGrid, Spinner, Image, Text } from "@chakra-ui/react";
import { SearchInput } from "../../../common";
import { useStickerStore } from "../../../../states/useStickerStore";
import { useState, useEffect } from "react";
import { useDragPhotoStore } from "../../../../states/useDragPhoto";

export const Stickers = ({ display }) => {
    const stickerStore = useStickerStore((state) => state);
    const dragPhoto = useDragPhotoStore((state) => state);
    const [failedSearch, setFailedSearch] = useState(false);

    const onSearch = (event) => stickerStore.setQuery(event.target.value)

    useEffect(() => {
        if (stickerStore.query !== stickerStore.lastQuery) {
            stickerStore.setIsSearching(true);
            setFailedSearch(false);
            let userId = `1000000${Math.floor(Math.random() * 5) + 1}`;
            userId = '10000001';

            const getData = setTimeout(() => {
                let apiEndpoint = `${process.env.REACT_APP_STIPOP_URL}/search?userId=${userId}&lang=en&pageNumber=1&limit=10&animated=N`;
                if (stickerStore.query) {
                    apiEndpoint += `&q=${stickerStore.query}`;
                    fetch(apiEndpoint, {
                        method: 'GET',
                        headers: {
                            'apikey': process.env.REACT_APP_STIPOP_API_KEY
                        }
                    })
                    .then((response) => response.json())
                    .then((data) => {
                        stickerStore.setStickers(data.body.stickerList.map((sticker) => {
                            return {
                                original: sticker.stickerImg
                            };
                        }));

                        stickerStore.setIsSearching(false);
                        stickerStore.setLastQuery(stickerStore.query)
                    })
                    .catch((err) => {
                        setFailedSearch(true);
                        stickerStore.setIsSearching(false);
                    });
                } else {
                    stickerStore.setStickers([]);
                    stickerStore.setIsSearching(false);
                }
            }, 250)
        
            return () => clearTimeout(getData);
        }
    }, [stickerStore.query]);

    return (
        <Flex
            w="full"
            justify={"flex-start"}
            py="16px"
            flexDirection={"column"}
            gap="16px"
            display={display}
            marginBottom={"250px"}
        >
            <SearchInput placeholder={"Search for Stickers"} value={stickerStore.query} onChange={onSearch} />
            <Text
                fontSize={"16px"}
                fontWeight={"700"}
                color="wps.buttongray">
                    Results
            </Text>
            {stickerStore.isSearching ? (
                <Flex 
                    style={{ height: '100%'}}
                    alignItems={"center"}
                    justify={"center"}
                    >
                    <Spinner size="xl" />
                </Flex>
                ) : (
                    stickerStore.stickers.length > 0 ? (
                    <SimpleGrid columns={2} gap={2}>
                        {stickerStore.stickers?.map((sticker, i) => (
                            <GridItem key={i}>
                                <button>
                                    <Image
                                        src={sticker.original}
                                        objectFit={"cover"}
                                        objectPosition={"center"}
                                        h="120px"
                                        w="120px"
                                        onMouseDown={() => {
                                            dragPhoto.setDraggedSticker(sticker.original);
                                        }}
                                    />
                                </button>
                            </GridItem>
                        ))}
                    </SimpleGrid>
                ) : (
                    <Text color="wps.medgray" textAlign={"center"}>
                        {failedSearch ? 'Server error while searching. Please try again later.' : 'No results yet. Input search query for stickers.'}
                    </Text>
                )
            )}
        </Flex>
    );
};
