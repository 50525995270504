export const inchesToPx = (inches) => inches * 96;

export const getAreas = (template) => {
    const allowedAreas = template.match(/[a-z]/g);

    if (!allowedAreas) {
        return [];
    }

    // Use a Set to store unique characters
    const distinctAreas = new Set(allowedAreas);

    // Convert Set back to an array and return the distinct characters
    return [...distinctAreas].sort((a, b) => a - b);
};
