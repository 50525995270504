import {
    Button,
    Flex,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useDisclosure,
    Progress
} from "@chakra-ui/react";
import { MdOutlineFileUpload } from "react-icons/md";
import { DarkButton } from "./DarkButton";
import { useRef, useState } from "react";
import { nanoid } from "nanoid";
import { useMutation, useQueryClient } from "react-query";
import { uploadPhoto } from "../../../../utils/photo-uploader";

export const UploadPhotoButton = () => {
    const clientId = localStorage.getItem('clientId');
    const [isUploading, setIsUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const { isOpen, onOpen, onClose } = useDisclosure();
    const fileInputRef = useRef();

    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        if (!isUploading) {
            e.preventDefault();
            e.stopPropagation();
            uploadFiles(e.dataTransfer.files);
        }
    };

    const hasReachedMaxSize = (files) => {
        const MAX_SIZE = 32 * 1024 * 1024; // 32 MB in bytes

        for (const file of files) {
            if (file.size > MAX_SIZE) {
                return true;
            }
        }

        return false;
    };

    const handleFileUpload = async (files) => {
        let progress = 0;
        setUploadProgress(0);
        if (hasReachedMaxSize(files)) {
            return Promise.reject("A file has reached the 32MB limit");
        }
        setIsUploading(true);

        const filenames = [];
        setUploadProgress(20);
        progress = 20;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const key = `${clientId}/photos/${nanoid()}`;
            let fileType = `webp`;
            if (file.type === 'image/png') {
                fileType = 'png';
            }

            try {
                await uploadPhoto(file, key, fileType).then((data) => {
                    const newProgress = progress + (80 / files.length);
                    progress = newProgress;
                    setUploadProgress(newProgress);
                    return data;
                });

                filenames.push(`${key}.${fileType}`);
            } catch(ex) {
                
            }
        }

        return filenames;
    };

    const {
        data,
        error,
        mutate: uploadFiles,
    } = useMutation({
        mutationFn: async (files) => {
            const filenames = await handleFileUpload(files);
            setUploadProgress(100);
            const data = filenames.map((filename) => ({
                    src: `${process.env.REACT_APP_S3_BUCKET_URL}/${filename}`,
                    s3FileName: filename,
                })
            );

            setTimeout(() => {
                onClose();
                setUploadProgress(0);
                setIsUploading(false);
            }, 50)

            return data;
        },
        onSuccess: (filenames) => {
            queryClient.invalidateQueries("photos");
        },
    });

    const queryClient = useQueryClient();

    return (
        <>
            <Button
                bg="wps.darkgray"
                color="wps.white"
                w="full"
                fontSize={"13px"}
                fontWeight={"medium"}
                borderRadius={0}
                outline="0px solid black"
                _hover={{
                    outline: "1px solid black",
                }}
                _active={{}}
                px="30px"
                py="8px"
                onClick={onOpen}
            >
                Upload photos
            </Button>
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent
                    maxW="1065px"
                    m="45px"
                    borderRadius={0}
                    h="full"
                    maxH="500px"
                >
                    <ModalHeader
                        fontFamily="Bebas Neue, sans-serif"
                        fontSize={"23px"}
                        fontWeight={"normal"}
                    >
                        {"UPLOAD PHOTOS"}
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody p="45px">
                        <Flex
                            justify={"center"}
                            align="center"
                            border="2px dashed"
                            borderColor={"wps.lightgray"}
                            px={4}
                            h="80%"
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            opacity={isUploading ? '50%' : '100%'}
                        >
                            <Flex
                                w="full"
                                h="full"
                                justify="center"
                                align="center"
                                flexDirection={"column"}
                                gap={"14px"}
                                maxW="285px"
                                maxH={"185px"}
                            >
                                <Icon
                                    as={MdOutlineFileUpload}
                                    boxSize={"71px"}
                                    fill="wps.medgray"
                                />
                                <DarkButton
                                    text={`Upload photos`}
                                    onClick={() => fileInputRef.current.click()}
                                />
                                <Input
                                    ref={fileInputRef}
                                    hidden
                                    type="file"
                                    multiple
                                    accept="image/*"
                                    onChange={(e) =>
                                        uploadFiles(e.target.files)
                                    }
                                    disabled={isUploading}
                                />
                                <Text textAlign="center" color="wps.medgray">
                                    or drag and drop photos here.
                                </Text>
                                
                                <Text textAlign="center" fontSize="12px" color="wps.medgray">
                                    For better print quality, upload PNG or JPEG photos with RGB color mode. 
                                </Text>
                            </Flex>
                        </Flex>

                        { isUploading && 
                            (<Progress marginTop="8px" height='32px' value={uploadProgress} />)
                        }
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};