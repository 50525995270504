import {
    Box,
    Flex,
    Radio,
    RadioGroup,
    SimpleGrid,
    Stack,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    useDisclosure,
    Button,
    Text
} from "@chakra-ui/react";
import { usePageStore } from "../../../../states/usePageStore";
import PhotoPrintLayout1 from "./images/photoprint/layout1.png";
import DecoPrintLayout1 from "./images/decoprint/layout1.png";
import PhotobookLayout1 from "./images/photobook/layout1.png";
import { Select } from "../../../common";
import { DECO_PRINT_LAYOUTS } from '../../../../configs/deco-print-layouts';
import { PHOTO_PRINT_LAYOUTS } from '../../../../configs/photo-print-layouts';
import { PHOTO_SAFE_LAYOUTS } from '../../../../configs/photo-safe-layouts';
import { useEffect, useRef, useState } from "react";
import { useProjectStore } from "../../../../states/useProjectStore";

export const Layouts = ({ display }) => {
    const pageStore = usePageStore();
    const projectStore = useProjectStore();
    const [alertHeader, setAlertHeader] = useState('');
    const [alertBody, setAlertBody]  = useState('');

    const [option1Values, setOption1Values] = useState({
        decoPrint: [],
        photoPrint: [],
        photobook: []
    });

    const [option2Values, setOption2Values] = useState({
        decoPrint: {},
        photoPrint: {},
        photobook: {}
    });

    const [layoutVariantIdMap, setLayoutVariantIdMap] = useState({
        photoPrint: {},
        decoPrint: {},
        photobook: {}
    });

    // photoprint: option1 - paper type, option2 - size
    // decoprint: option1 - type, option2 - size
    // photobook: option1 - size, option2 - number of pages
    // const [option1, setOption1] = useState();
    // const [option2, setOption2] = useState();
    const [changingLayoutType, setChangingLayoutType] = useState();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const warningModalCancelRef = useRef();

    const getPrice = function() {
        const option1 = pageStore.option1;
        const option2 = pageStore.option2;

        let layouts = PHOTO_SAFE_LAYOUTS;
        let price;

        if (pageStore.layoutType === 'decoPrint') {
            layouts = DECO_PRINT_LAYOUTS;
        } else if (pageStore.layoutType === 'photoPrint') {
            layouts = PHOTO_PRINT_LAYOUTS
        }

        if (pageStore.layoutType === 'photobook') {
            price = layouts[option1].variants[option2].price;
        } else {
            price = layouts[option1][option2].price;
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'PHP',
        });

        return formatter.format(price);
    }

    const getMinOrder = function() {
        const option1 = pageStore.option1;
        const option2 = pageStore.option2;

        let layouts = PHOTO_SAFE_LAYOUTS;
        let minOrder;

        if (pageStore.layoutType === 'decoPrint') {
            layouts = DECO_PRINT_LAYOUTS;
        } else if (pageStore.layoutType === 'photoPrint') {
            layouts = PHOTO_PRINT_LAYOUTS
        }

        if (pageStore.layoutType === 'photobook') {
            minOrder = layouts[option1].variants[option2].minOrder;
        } else {
            minOrder = layouts[option1][option2].minOrder;
        }

        return minOrder;
    }

    const getIsDoubleLayout = function() {
        const option1 = pageStore.option1;
        const option2 = pageStore.option2;

        let layouts = PHOTO_SAFE_LAYOUTS;
        let isDoubleLayout;

        if (pageStore.layoutType === 'decoPrint') {
            layouts = DECO_PRINT_LAYOUTS;
        } else if (pageStore.layoutType === 'photoPrint') {
            layouts = PHOTO_PRINT_LAYOUTS
        }

        if (pageStore.layoutType === 'photobook') {
            isDoubleLayout = layouts[option1].variants[option2].isDoubleLayout || false;
        } else {
            isDoubleLayout = layouts[option1][option2].isDoubleLayout || false;
        }

        return isDoubleLayout;
    }

    const resetAlert = function() {
        setAlertHeader('');
        setAlertBody('');
    }

    useEffect(() => {
        setLayoutVariantIdMap({
            decoPrint: DECO_PRINT_LAYOUTS,
            photoPrint: PHOTO_PRINT_LAYOUTS,
            photobook: PHOTO_SAFE_LAYOUTS
        });

        const decoPrintOption1 = Object.keys(DECO_PRINT_LAYOUTS);
        const photoPrintOption1 = Object.keys(PHOTO_PRINT_LAYOUTS);
        const photobookOption1 = Object.keys(PHOTO_SAFE_LAYOUTS);

        const option1Values = {
            decoPrint: decoPrintOption1,
            photoPrint: photoPrintOption1,
            photobook: photobookOption1
        };

        setOption1Values(option1Values);

        const decoPrintOption2Values = {};
        decoPrintOption1.forEach((opt) => {
            decoPrintOption2Values[opt] = Object.keys(DECO_PRINT_LAYOUTS[opt]);
        });
        
        const photoPrintOption2Values = {};
        photoPrintOption1.forEach((opt) => {
            photoPrintOption2Values[opt] = Object.keys(PHOTO_PRINT_LAYOUTS[opt]);
        });

        const photobookOption2Values= {};
        const photobookOption2Arr = [];
        for (let i = 20; i <= 40; i += 2) {
            photobookOption2Arr.push(`${i}`);
        }

        photobookOption1.forEach((opt) => {
            photobookOption2Values[opt] = photobookOption2Arr;
        });

        const option2Values = {
            decoPrint: decoPrintOption2Values,
            photoPrint: photoPrintOption2Values,
            photobook: photobookOption2Values
        }
        setOption2Values(option2Values);
    }, []);

    useEffect(() => {
        pageStore.setOption1(null);
        pageStore.setOption2(null);
    }, [pageStore.layoutType]);

    useEffect(() => {
        if (pageStore.layoutType === 'photobook') {
            pageStore.setPhotobookTemplate();
            pageStore.setNumberOfPages();
        }
    }, [pageStore.option1]);

    useEffect(() => {
        if (pageStore.layoutType === 'photobook') {
            pageStore.setNumberOfPages();
        } else {
            pageStore.setTemplate();
        }

        setTimeout(() => {
            const e = document.getElementById('canvas-0');
            if (e) {
                e.scrollIntoView({
                    behavior: 'auto',
                    block: 'center',
                    inline: 'center'
                });
            }
        }, 100);
    }, [pageStore.option2]);

    const getPlaceholderText = (option, layoutType) => {
        if (option === 'option1') {
            if (layoutType === 'photoPrint') return 'Select Paper Type';
            if (layoutType === 'decoPrint') return 'Select Type';
            if (layoutType === 'photobook') return 'Select Size';
        } else if (option === 'option2') {
            if (layoutType === 'photoPrint') return 'Select Size';
            if (layoutType === 'decoPrint') return 'Select Size';
            if (layoutType === 'photobook') return 'Select Number of Pages';
        }
        return '';
    }

    const checkForLayoutNotice = (op1, op2) => {
        const option1 = op1 || pageStore.option1;
        const option2 = op2 || pageStore.option2;
        if (option2) {
            let layouts = PHOTO_SAFE_LAYOUTS;
            let minOrder;
            let isDoubleLayout;

            if (pageStore.layoutType === 'decoPrint') {
                layouts = DECO_PRINT_LAYOUTS;
            } else if (pageStore.layoutType === 'photoPrint') {
                layouts = PHOTO_PRINT_LAYOUTS
            }

            if (pageStore.layoutType === 'photobook') {
                minOrder = layouts[option1].variants[option2].minOrder;
                isDoubleLayout = layouts[option1].variants[option2].isDoubleLayout;
            } else {
                minOrder = layouts[option1][option2].minOrder;
                isDoubleLayout = layouts[option1][option2].isDoubleLayout;
            }

            if (minOrder || isDoubleLayout) {
                setAlertHeader('Layout Notice');
                let body = '';
                if (minOrder) {
                    body += `This order has a minimum order of ${minOrder} pcs.\n`;
                }

                if (isDoubleLayout) {
                    body += `This is a double layout print product.`;
                }
                setAlertBody(body);
                onOpen();
            }
        }
    }

    return (
        <>
            <Flex
                w="full"
                justify={"flex-start"}
                flexDirection={"column"}
                display={display}
                zIndex={"2"}
                marginBottom={"250px"}
            >
                <Flex
                    w="full"
                    justify={"flex-start"}
                    borderBottom="1px solid"
                    borderColor={"wps.lightgray"}
                    py="16px"
                >
                    <RadioGroup
                        onChange={(value) => {
                            setChangingLayoutType(value);
                            setAlertHeader('Changing Layout Type');
                            setAlertBody('Changing layout type will delete your current progress for your current layout type. Would you like to proceed?');
                            onOpen(); 
                        }}
                        value={pageStore.layoutType}
                    >
                        <Stack gap="10px">
                            <Radio value="photoPrint">Photo Print</Radio>
                            <Radio value="decoPrint">Deco Print</Radio>
                            <Radio value="photobook">Photo Safe</Radio>
                        </Stack>
                    </RadioGroup>
                </Flex>

                { pageStore.option1 && pageStore.option2 && (
                     <Flex
                        w="full"
                        justify={"flex-start"}
                        borderBottom="1px solid"
                        borderColor={"wps.lightgray"}
                        py="8px"
                        direction={"column"}
                    >
                        <Text
                            fontSize={"16px"}
                            fontWeight={"700"}
                            color="wps.buttongray">
                                PRICE: {getPrice()} 

                                {getMinOrder() ? ` (Min order: ${getMinOrder()} pcs)` : ''}
                        </Text>

                        { getIsDoubleLayout() && (
                            <Text
                            fontSize={"14px"}
                            fontWeight={"500"}
                            color="wps.buttongray">
                                This is a double layout print product.
                            </Text>
                        )}
                    </Flex>
                )}
                <Flex
                    w="full"
                    justify={"flex-start"}
                    borderBottom="1px solid"
                    borderColor={"wps.lightgray"}
                    py="16px"
                    flexDirection={"column"}
                    gap="16px"
                >
                    <Select
                        options={option1Values[pageStore.layoutType]}
                        p={2}
                        border="1px solid black"
                        borderRadius={0}
                        w="full"
                        selectedValue={pageStore.option1}
                        setSelectedValue={(value) => {
                            pageStore.setOption1(value);
                            pageStore.setOption2(option2Values[pageStore.layoutType][value][0]);
                            checkForLayoutNotice(value, option2Values[pageStore.layoutType][value][0]);
                        }}
                        placeholder={getPlaceholderText('option1', pageStore.layoutType)}
                    />

                    <Select
                        options={option2Values[pageStore.layoutType][pageStore.option1] || []}
                        p={2}
                        border="1px solid black"
                        borderRadius={0}
                        w="full"
                        selectedValue={pageStore.option2}
                        setSelectedValue={(value) => {
                            pageStore.setOption2(value);
                            checkForLayoutNotice(null, value);
                        }}
                        placeholder={getPlaceholderText('option2', pageStore.layoutType)}
                        isDisabled={!pageStore.option1}
                    />
                    
                </Flex>
                {(pageStore.layoutType === "photobook" && pageStore.option1 ) && <PhotobookOptions pageStore={pageStore}/>}
                {(pageStore.layoutType !== "photobook" ) && <Text mt="16px"> You can set the quantity of each page before adding to cart. </Text>}
                {(pageStore.layoutType !== "photobook" ) && <Text mt="16px"> To order a different layout, create a new project and set your desired layout. </Text>}

            </Flex>
            <AlertDialog
                isOpen={isOpen}
                leastDestructiveRef={warningModalCancelRef}
                onClose={() => {
                    setChangingLayoutType(null);
                    resetAlert();
                    onClose();
                }}
            >
                <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                        { alertHeader }
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        { alertBody }
                    </AlertDialogBody>

                    { alertHeader == 'Layout Notice' && 
                        <AlertDialogFooter>
                            <Button bg='wps.lightbeige' onClick={() => {
                                resetAlert();
                                onClose();
                            }}>
                                OK
                            </Button>
                        </AlertDialogFooter>
                    }
                    { alertHeader == 'Changing Layout Type' && <AlertDialogFooter>
                        <Button ref={warningModalCancelRef} onClick={() => {
                            setChangingLayoutType(null);
                            resetAlert();
                            onClose();
                        }}>
                            Cancel
                        </Button>
                        <Button 
                            bg='wps.lightbeige'
                            onClick={() => {
                                pageStore.setLayoutType(changingLayoutType);
                                localStorage.setItem("currentLayoutType", changingLayoutType);
                                const projectId = localStorage.getItem('projectId');
                                projectStore.setProjectProp(projectId, 'layoutType', changingLayoutType);
                                setChangingLayoutType(null);
                                resetAlert();
                                onClose();
                            }}
                            ml={3}
                        >
                            Proceed
                        </Button>
                    </AlertDialogFooter>}
                </AlertDialogContent>
                </AlertDialogOverlay>
            </AlertDialog>
        </>
    );
};

const PhotobookOptions = ({ pageStore }) => {
    const layoutTemplates = pageStore.layoutTemplates.photobook[pageStore.option1];

    return LayoutItems({ layoutTemplates, photobookLayoutIndex: pageStore.photobookLayoutIndex, pageStore });
};

const LayoutItems = ({ layoutTemplates, photobookLayoutIndex, pageStore }) => {
    const [isHoveringProject, setIsHoveringProject] = useState(null);

    return (
        <Flex py="16px" flexDirection={"column"} gap="20px">
        <SimpleGrid columns={2} gap={2}>
            {layoutTemplates.map((layout, i) => (
                <>
                    <Flex flexDirection={"column"}>
                        <Box
                            height={120}
                            width={120}
                            w="full"
                            backgroundImage={`url(${layout.image})`}
                            backgroundPosition="center"
                            backgroundSize="auto 100%"
                            backgroundRepeat="no-repeat"
                            outline={"1px solid"}
                            outlineColor={"wps.lightgray"}
                            cursor="pointer"
                            position="relative"
                            onMouseEnter={() => {
                                setIsHoveringProject(i);
                            }}
                            onMouseLeave={() => {
                                setIsHoveringProject(null)
                            }}
                            onClick={() => {
                                pageStore.setPhotobookLayoutIndex(i, pageStore.spreadSelectedIndex)
                            }}
                        >   
                            <Box 
                                position="relative"
                                height={`${layout.preview.height}px`}
                                width={`${layout.preview.width}px`}
                                margin="0 auto"
                            >
                                {layout.preview.boxes.map((box, j) => (

                                    <Box
                                        key={j}
                                        position="absolute"
                                        background="wps.lightgray"
                                        opacity="50%"
                                        top={`${box.y + layout.preview.margins.top}px`}
                                        left={`${box.x + layout.preview.margins.left }px`}
                                        h={`${box.height}px`}
                                        w={`${box.width}px`}
                                        cursor={"pointer"}
                                    >
                                    </Box>
                                ))}
                            </Box>

                            {isHoveringProject === i && (
                                <Flex
                                    width="100%"
                                    position="absolute"
                                    bottom="5px"
                                    height="30px"
                                >
                                    <Button
                                        bg="wps.darkgray"
                                        color="wps.white"
                                        height="30px"                                        
                                        
                                        marginLeft="auto"
                                        marginRight="auto"
                                        paddingX="12px"
                                        borderRadius={0}
                                        _hover={{
                                            bg: "wps.darkgray",
                                            border: "1px solid black",
                                        }}
                                        onClick={() => {
                                            pageStore.setPhotobookLayoutIndex(i, 'all');
                                        }}
                                    >
                                        <Text> Apply to All</Text>
                                    </Button>
                                </Flex>
                            )}
                        </Box>
                    </Flex>
                </>
            ))}
        </SimpleGrid>
    </Flex>
    )
}
