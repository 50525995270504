import { Icon } from "@chakra-ui/react";
import AWS from "aws-sdk";
import { ReactComponent as DeletePage } from "../../../Canvas/images/DeletePage.svg";
import { useMutation, useQueryClient } from "react-query";
import { usePhotoStore } from "../../../../states/usePhotoStore";

export const DeletePhotoButton = ({ i, file, setHoveringPhotoIndex }) => {
    const photoStore = usePhotoStore((state) => state)

    const handleDeleteFile = async (i, file) => {

        const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
        const REGION = process.env.REACT_APP_REGION;

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
        });
        const s3 = new AWS.S3({
            params: { Bucket: S3_BUCKET },
            region: REGION,
        });

        const params = {
            Bucket: S3_BUCKET,
            Key: file,
        };

        await s3.deleteObject(params).promise();
        photoStore.deletePhoto(i);
    };

    const {
        data,
        error,
        mutate: deleteFile,
    } = useMutation({
        mutationFn: async ({i, file}) => {
            await handleDeleteFile(i, file)
        },
        onSuccess: (filenames) => {
            queryClient.invalidateQueries("photos");
        },
    });

    const queryClient = useQueryClient();


    return (
        <button
            style={{
                width: `30px`,
                height: `30px`,
                background: `#DDDEDF`,
                borderRadius: '100%',
                position: "absolute",
                top: "5px",
                right: "5px",
            }}
            onClick={() => {
                deleteFile({ i, file });
            }}
            onMouseEnter={() => {
                setHoveringPhotoIndex(i);
            }}
            onMouseLeave={() => {
                setHoveringPhotoIndex(-1)
            }}
        >
            <Icon 
                as={DeletePage} 
                boxSize={"14px"} 
                margin={"0"} 
                color={"#ffffff"}
                style={{
                    margin: "5px 0px 0px 0px"
                }} />
        </button>
    )
}