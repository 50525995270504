import {
    Flex,
    Text,
    Slider,
    SliderTrack,
    SliderFilledTrack,
    SliderThumb,
    Tooltip,
} from "@chakra-ui/react";
import { colors } from "../../../styles";
import { useState } from "react";

export const PhotoEditorSlider = ({ label, onChange, defaultValue, value, min, max, unit, width }) => {
    const [showTooltip, setShowTooltip] = useState(false)

    return (
        <Flex 
            direction={'column'} 
            marginBottom={'4px'}
            {...(width
                ? {
                    width: `${width}px`
                }
            : {})}
        >
            <Text flexGrow={1}>{label}</Text>
            <Slider 
                defaultValue={defaultValue}
                value={value}
                min={min}
                max={max}
                onChange={onChange}
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
            >
                <SliderTrack>
                    <SliderFilledTrack bg={colors.colors.wps.darkgray} />
                </SliderTrack>
                <Tooltip
                    hasArrow
                    bg={colors.colors.wps.darkgray}
                    color='white'
                    placement='top'
                    isOpen={showTooltip}
                    label={`${value}${unit || '%'}`}
                >
                    <SliderThumb />
                </Tooltip>
            </Slider>
        </Flex>
    )
};