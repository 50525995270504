import { Flex, Icon } from "@chakra-ui/react";
import { Tab } from "./Tab";
import Layouts from "../../../images/LeftSidebar/Layouts.svg";
import Photos from "../../../images/LeftSidebar/Photo.svg";
import Background from "../../../images/LeftSidebar/Background.svg";
import Stickers from "../../../images/LeftSidebar/Sticker.svg";
import Text from "../../../images/LeftSidebar/Text.svg";

export const Tabs = ({
    activeTab,
    setActiveTab,
    setShowTabDetails,
    previousActiveTab,
    ...props
}) => {
    const handleSetActiveTab = (tab) => {
        setActiveTab(tab);
        setShowTabDetails(true);
        previousActiveTab.current = tab;
    };

    return (
        <Flex
            bg="wps.lightgray"
            h={{ base: "calc(100vh - 60px)", lg: "calc(100vh - 72px)" }} // this might change for mobile responsiveness (ios)
            overflowY={"auto"}
            overflowX="hidden"
            flexDirection={"column"}
            {...props}
        >
            <Tab
                icon={Layouts}
                name="Layouts"
                imageStyles={{
                    width: "24px",
                    height: "24px",
                    marginBottom: "2px",
                }}
                isFirstTab
                isActive={activeTab === "Layouts"}
                setActiveTab={() => {
                    handleSetActiveTab("Layouts");
                }}
            />
            <Tab
                icon={Photos}
                name="Photos"
                isActive={activeTab === "Photos"}
                setActiveTab={() => {
                    handleSetActiveTab("Photos");
                }}
            />
            <Tab
                icon={Background}
                name="Bckgrds"
                isActive={activeTab === "Backgrounds"}
                setActiveTab={() => {
                    handleSetActiveTab("Backgrounds");
                }}
            />
            <Tab
                icon={Stickers}
                name="Stickers"
                isActive={activeTab === "Stickers"}
                setActiveTab={() => {
                    handleSetActiveTab("Stickers");
                }}
            />
            <Tab
                icon={Text}
                name="Text"
                isActive={activeTab === "Text"}
                setActiveTab={() => {
                    handleSetActiveTab("Text");
                }}
            />
            <Flex h="full" borderRight="1px solid" borderColor="wps.lightgray"></Flex>
        </Flex>
    );
};
