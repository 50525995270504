import { GridItem, IconButton, SimpleGrid } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
    AiOutlineAlignCenter,
    AiOutlineAlignLeft,
    AiOutlineAlignRight,
    AiOutlineBold,
    AiOutlineItalic,
} from "react-icons/ai";
import { BsJustify } from "react-icons/bs";

export const TextFormatters = ({ pageStore }) => {
    const [textIsBold, setTextIsBold] = useState(false);
    const [textIsItalic, setTextIsItalic] = useState(false);
    const [alignment, setAlignment] = useState("left"); // Possible values are 'left' | 'center' | 'right' | 'justify

    // useEffect(() => {
    //     if (pageStore.data.spreads[pageStore.spreadSelectedIndex || 0].texts[pageStore.textSelectedIndex]?.fontWeight === 'bold') {
    //         setTextIsBold(true)
    //     } else {
    //         setTextIsBold(false);
    //     }
    // }, [pageStore.data.spreads[pageStore.spreadSelectedIndex || 0].texts[pageStore.textSelectedIndex]?.fontWeight]);

    // useEffect(() => {
    //     if (pageStore.data.spreads[pageStore.spreadSelectedIndex].texts[pageStore.textSelectedIndex]?.fontStyle === 'italic') {
    //         setTextIsItalic(true)
    //     } else {
    //         setTextIsItalic(false);
    //     }
    // }, [pageStore.data.spreads[pageStore.spreadSelectedIndex].texts[pageStore.textSelectedIndex]?.fontStyle]);

    return (
        <SimpleGrid columns={4} rows={2} gap={2}>
            <GridItem>
                <TextFormatter
                    icon={<AiOutlineBold />}
                    isActive={pageStore.data.spreads[pageStore.spreadSelectedIndex]?.texts[pageStore.textSelectedIndex]?.fontWeight === 'bold'}
                    onClick={() => {
                        pageStore.setFontWeight(pageStore.data.spreads[pageStore.spreadSelectedIndex]?.texts[pageStore.textSelectedIndex]?.fontWeight === 'bold' ? 'normal' : 'bold');
                    }}
                />
            </GridItem>
            <GridItem>
                <TextFormatter
                    icon={<AiOutlineItalic />}
                    isActive={pageStore.data.spreads[pageStore.spreadSelectedIndex]?.texts[pageStore.textSelectedIndex]?.fontStyle === 'italic'}
                    onClick={() => {
                        pageStore.setFontStyle(pageStore.data.spreads[pageStore.spreadSelectedIndex]?.texts[pageStore.textSelectedIndex]?.fontStyle === 'italic' ? 'normal' : 'italic');
                    }}
                />
            </GridItem>
            <GridItem>
                <TextFormatter
                    icon={<AiOutlineAlignLeft />}
                    isActive={pageStore.data.spreads[pageStore.spreadSelectedIndex]?.texts[pageStore.textSelectedIndex]?.textAlign === 'left'}
                    onClick={() => {
                        pageStore.setTextAlign('left');
                    }}
                />
            </GridItem>
            <GridItem>
                <TextFormatter
                    icon={<AiOutlineAlignCenter />}
                    isActive={pageStore.data.spreads[pageStore.spreadSelectedIndex]?.texts[pageStore.textSelectedIndex]?.textAlign === 'center'}
                    onClick={() => {
                        pageStore.setTextAlign('center');
                    }}
                />
            </GridItem>
            <GridItem>
                <TextFormatter
                    icon={<AiOutlineAlignRight />}
                    isActive={pageStore.data.spreads[pageStore.spreadSelectedIndex]?.texts[pageStore.textSelectedIndex]?.textAlign === 'right'}
                    onClick={() => {
                        pageStore.setTextAlign('right')
                    }}
                />
            </GridItem>
            <GridItem>
                <TextFormatter
                    icon={<BsJustify />}
                    isActive={pageStore.data.spreads[pageStore.spreadSelectedIndex]?.texts[pageStore.textSelectedIndex]?.textAlign === 'justify'}
                    onClick={() => {
                        pageStore.setTextAlign('justify')
                    }}
                />
            </GridItem>
        </SimpleGrid>
    );
};

const TextFormatter = ({ icon, isActive, onClick }) => {
    return (
        <IconButton
            icon={icon}
            cursor={"pointer"}
            w="46px"
            h="46px"
            bg={isActive ? "wps.gray" : "wps.smoke"}
            sx={{
                svg: {
                    fill: isActive ? "wps.white" : "unset",
                },
            }}
            onClick={onClick}
            _hover={{ bg: isActive ? "wps.gray" : "gray.200" }}
            tabIndex={"0"}
        />
    );
};
