import { usePageStore } from "../../../states/usePageStore";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    VStack,
    Button,
    Text,
    Spinner,
    Flex,
    IconButton,
    Box,
    Checkbox,
    Icon
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import AWS from 'aws-sdk';
import { nanoid } from 'nanoid';
import JSZip from 'jszip';
import { cover } from 'intrinsic-scale';
import { PHOTO_PRINT_LAYOUTS } from "../../../configs/photo-print-layouts";
import { PHOTO_SAFE_LAYOUTS } from "../../../configs/photo-safe-layouts";
import { DECO_PRINT_LAYOUTS } from "../../../configs/deco-print-layouts";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { useCartStore } from "../../../states/useCartStore";
import { useProjectStore } from "../../../states/useProjectStore";
import cloneDeep from "lodash.clonedeep";
import { MdOutlineWarningAmber } from "react-icons/md";

export const PreviewModal = ({ setShowCart, isOpen, onClose }) => {
    const pageStore = usePageStore((state) => state);
    const cartStore = useCartStore((state) => state);
    const projectStore = useProjectStore((state) => state);
    const [isLoading, setIsLoading] = useState(false);
    const [isExporting, setIsExporting] = useState(false);
    const [spreadImages, setSpreadImages] = useState([]);
    const [isFullSize, setIsFullSize] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [multiQuantity, setMultiQuantity] = useState([]);
    const [isDoubleLayout, setIsDoubleLayout] = useState(false);
    
    useEffect(() => {
        const SCALE_FACTOR = {
            photobook: 2,
            decoPrint: 1,
            photoPrint: 10
        };

        if (isOpen) {
            setSpreadImages([]);
            const option1 = pageStore.option1;
            const option2 = pageStore.option2;

            let layouts = PHOTO_SAFE_LAYOUTS;
            let isDoubleLayout = false;

            const data = pageStore.data;
            const spreads = data.spreads;

            if (pageStore.layoutType === 'decoPrint') {
                layouts = DECO_PRINT_LAYOUTS;
                const multiQuantity = Array(spreads.length).fill(1);
                setMultiQuantity(multiQuantity);
            } else if (pageStore.layoutType === 'photoPrint') {
                layouts = PHOTO_PRINT_LAYOUTS
                const multiQuantity = Array(spreads.length).fill(1);
                setMultiQuantity(multiQuantity);
            }

            if (pageStore.layoutType === 'photobook') {
                isDoubleLayout = layouts[option1].variants[option2].isDoubleLayout || false;
            } else {
                isDoubleLayout = layouts[option1][option2].isDoubleLayout || isDoubleLayout;
            }

            setQuantity(1);

            setIsDoubleLayout(isDoubleLayout);

            const processSpreads = async() => {
                setIsLoading(true);
                const data = pageStore.data;

                const canvasWidth = data.width * SCALE_FACTOR[pageStore.layoutType];
                const canvasHeight = data.height * SCALE_FACTOR[pageStore.layoutType];
                const canvasMargins = data.margins;
                const spreads = data.spreads;

                const spreadImgs = [];

                for (let i = 0; i < spreads.length; i++) {
                    const spread = spreads[i];
                    const imageLoadPromises = [];
                    const texts = [];

                    for (let j = 0; j < spread.boxes.length; j++) {
                        const box = spread.boxes[j];
                        const photo = box.photo;

                        if (photo && photo.src && !box.deleted) { // disregard boxes with no photo
                            imageLoadPromises.push(new Promise((resolve) => {
                                const filter = getCSSFilter(photo);
                                const border = getBorder(photo);
                                const boxShadow = getBoxShadow(photo);
                                const isSticker = photo.isSticker || false;
                                
                                const img = document.createElement('img');
                                img.src = photo.editedOriginalSrc || photo.originalSrc;
                                img.crossOrigin = 'anonymous';

                                img.onload = () => resolve({
                                    img: img,
                                    filter: filter,
                                    border: border,
                                    boxShadow: boxShadow,
                                    isSticker: isSticker,
                                    opacity: photo.filter?.opacity || 100,
                                    zIndex: box.zIndex !== undefined ? box.zIndex : 1,
                                    x: (box.x + canvasMargins.left) * SCALE_FACTOR[pageStore.layoutType],
                                    y: (box.y + canvasMargins.top) * SCALE_FACTOR[pageStore.layoutType],
                                    width: (box.width) * SCALE_FACTOR[pageStore.layoutType],
                                    height: (box.height) * SCALE_FACTOR[pageStore.layoutType]
                                });
                            }));
                        }
                    }

                    for (let j = 0; j < spread.texts.length; j++) {
                        const text = spread.texts[j];
                        if (!text.deleted) {
                            texts.push({
                                text: text.text,
                                filter: {
                                    fontSize: text.fontSize, 
                                    fontColor: text.fontColor, 
                                    textAlign: text.textAlign,
                                    fontFamily: text.fontFamily,
                                    fontStyle: text.fontStyle,
                                    fontWeight: text.fontWeight,
                                },
                                zIndex: text.zIndex !== undefined ? text.zIndex : 1,
                                x: (text.x + canvasMargins.left) * SCALE_FACTOR[pageStore.layoutType],
                                y: (text.y + canvasMargins.top) * SCALE_FACTOR[pageStore.layoutType],
                                width: (text.width) * SCALE_FACTOR[pageStore.layoutType],
                                height: (text.height) * SCALE_FACTOR[pageStore.layoutType]
                            });
                        }
                    }

                    const images = await Promise.all(imageLoadPromises);
                    const contentArr = [...images, ...texts];
                    contentArr.sort((a, b) => a.zIndex - b.zIndex);

                    const spreadCanvas = document.createElement('canvas');
                    const spreadCanvasContext = spreadCanvas.getContext('2d');
                    spreadCanvas.width = spreads[i].width ? spreads[i].width * SCALE_FACTOR[pageStore.layoutType] : canvasWidth;
                    spreadCanvas.height = spreads[i].height ? spreads[i].height * SCALE_FACTOR[pageStore.layoutType] : canvasHeight;

                    let initialWidth = spreadCanvas.width;
                    if (isDoubleLayout) {
                        spreadCanvas.width = 2 * spreadCanvas.width;
                    }

                    spreadCanvasContext.imageSmoothingEnabled = true;
                    spreadCanvasContext.webkitImageSmoothingEnabled = true;

                    spreadCanvasContext.fillStyle = spread.backgroundColor ? spread.backgroundColor : '#FFFFFF';
                    spreadCanvasContext.fillRect(0, 0, spreadCanvas.width, spreadCanvas.height);

                    if (spread.background) {
                        const photo = spread.background;
                        const filter = getCSSFilter(photo);

                        const background = document.createElement('img');
                        background.src = photo.editedOriginalSrc || photo.src;
                        background.crossOrigin = 'anonymous';

                        const backgroundImage = await new Promise((resolve) => {
                            if (!photo.src) {
                                resolve({
                                    img: background,
                                    filter: filter,
                                    opacity: photo.filter?.opacity || 100,
                                    x: (0) * SCALE_FACTOR[pageStore.layoutType],
                                    y: (0) * SCALE_FACTOR[pageStore.layoutType],
                                    width: (spreadCanvas.width),
                                    height: (spreadCanvas.height)
                                });
                            } else {
                                background.onload = () => resolve({
                                    img: background,
                                    filter: filter,
                                    opacity: photo.filter?.opacity || 100,
                                    x: (0) * SCALE_FACTOR[pageStore.layoutType],
                                    y: (0) * SCALE_FACTOR[pageStore.layoutType],
                                    width: (spreadCanvas.width),
                                    height: (spreadCanvas.height)
                                });
                            }
                        })
                        
                        const backgroundCanvas = document.createElement('canvas');
                        const backgroundCanvasContext = backgroundCanvas.getContext('2d');

                        const coverOutput = cover(spreadCanvas.width, spreadCanvas.height, backgroundImage.img.naturalWidth, backgroundImage.img.naturalHeight);

                        backgroundCanvas.width = spreadCanvas.width;
                        backgroundCanvas.height = spreadCanvas.height;
                        
                        backgroundCanvasContext.globalCompositeOperation = "destination-atop";
                        backgroundCanvasContext.globalAlpha = 1;
                        backgroundCanvasContext.fillStyle = spread.backgroundColor ? spread.backgroundColor : '#FFFFFF';
                        backgroundCanvasContext.fillRect(0, 0, backgroundImage.width, backgroundImage.height);

                        backgroundCanvasContext.filter = backgroundImage.filter;
                        backgroundCanvasContext.imageSmoothingEnabled = true;
                        backgroundCanvasContext.webkitImageSmoothingEnabled = true               

                        backgroundCanvasContext.globalCompositeOperation = "source-over"
                        if (photo.src && backgroundImage.opacity !== 100) {
                            backgroundCanvasContext.globalAlpha = (backgroundImage.opacity / 100) + 20;
                        }

                        backgroundCanvasContext.drawImage(background, coverOutput.x, coverOutput.y, coverOutput.width, coverOutput.height);

                        const processedBackground = new Image();
                        processedBackground.src = backgroundCanvas.toDataURL('image/jpeg');

                        await new Promise((resolve) => {
                            processedBackground.onload = () => {
                                spreadCanvasContext.drawImage(processedBackground, 0, 0, backgroundCanvas.width, backgroundCanvas.height, backgroundImage.x, backgroundImage.y, backgroundImage.width, backgroundImage.height);                                
                                resolve();
                            }
                        });
                    }


                    for (let j = 0; j < contentArr.length; j++) {
                        const content = contentArr[j];
                        if (content.img) {
                            const { img, filter, border, boxShadow, isSticker, opacity, x, y, width, height } = content;

                            if (img) {
                                if (!isSticker) {
                                    const imageCanvas = document.createElement('canvas');
                                    const imageCanvasContext = imageCanvas.getContext('2d');
        
                                    const coverOutput = cover(width, height, img.naturalWidth, img.naturalHeight);
        
                                    imageCanvas.width = width;
                                    imageCanvas.height = height;
                                    imageCanvasContext.filter = filter;
                                    imageCanvasContext.imageSmoothingEnabled = true;
                                    imageCanvasContext.webkitImageSmoothingEnabled = true

                                    if (opacity !== 100) {
                                        imageCanvasContext.globalCompositeOperation = "lighter"
                                        imageCanvasContext.globalAlpha = opacity / 100;
                                    }
                                    // imageCanvasContext.fillStyle = spread.backgroundColor ? spread.backgroundColor : '#FFFFFF';
                                    // imageCanvasContext.fillRect(0, 0, imageCanvas.width, imageCanvas.height);
                                    imageCanvasContext.drawImage(img, coverOutput.x, coverOutput.y, coverOutput.width, coverOutput.height)
                                    
                                    imageCanvasContext.lineWidth = border.lineWidth * 2 * SCALE_FACTOR[pageStore.layoutType];
                                    imageCanvasContext.strokeStyle = border.strokeStyle;
                                    if (border.lineWidth) {
                                        imageCanvasContext.strokeRect(0, 0, imageCanvas.width, imageCanvas.height);
                                    }

                                    const processedImage = await new Promise((resolve) => {
                                        const pi = new Image();
                                        pi.src = imageCanvas.toDataURL('image/png');
        
                                        pi.onload = () => {
                                            resolve(pi);
                                        }
                                    });

                                    spreadCanvasContext.shadowOffsetX = boxShadow.shadowOffsetX * SCALE_FACTOR[pageStore.layoutType];
                                    spreadCanvasContext.shadowOffsetY = boxShadow.shadowOffsetY * SCALE_FACTOR[pageStore.layoutType];
                                    spreadCanvasContext.shadowColor = boxShadow.shadowColor;
                                    spreadCanvasContext.drawImage(processedImage, 0, 0, imageCanvas.width, imageCanvas.height, x, y, width, height);

                                    if (isDoubleLayout) {
                                        spreadCanvasContext.drawImage(processedImage, 0, 0, imageCanvas.width, imageCanvas.height, x + initialWidth, y, width, height);
                                    }
                                } else {
                                    spreadCanvasContext.drawImage(img, x, y, width, height);

                                    if (isDoubleLayout) {
                                        spreadCanvasContext.drawImage(img, x + initialWidth, y, width, height);
                                    }

                                }
                            }
                        } else if (content.text) {
                            const { text, filter, x, y, width, height } = content;

                            let font =  '';
                            if (filter.fontStyle === 'italic') font = 'italic '
                            if (filter.fontWeight === 'bold') font += 'bold '

                            font += `${filter.fontSize * SCALE_FACTOR[pageStore.layoutType]}px ${filter.fontFamily}`;

                            spreadCanvasContext.font = font;
                            spreadCanvasContext.fillStyle = filter.fontColor;
                            spreadCanvasContext.textAlign = "center";;
                            let textOutput = text.replaceAll('<br>','').replaceAll('<div>','').replaceAll('</div>','');

                            spreadCanvasContext.fillText(textOutput, x + (width / 2), y + (height / 1.35));

                            if (isDoubleLayout) {
                                spreadCanvasContext.fillText(textOutput, (x + (width / 2)) + initialWidth, y + (height / 1.35));
                            }
                        }
                    }

                    if (isDoubleLayout) {
                        spreadCanvasContext.lineWidth = 1;
                        spreadCanvasContext.beginPath();
                        spreadCanvasContext.moveTo(initialWidth, 0);
                        spreadCanvasContext.lineTo(initialWidth, canvasHeight);
                        spreadCanvasContext.stroke();
                    }

                    const spreadImg = new Image();
                    spreadImg.src = spreadCanvas.toDataURL('image/jpeg');
                    spreadImgs.push(spreadImg);

                    setSpreadImages(spreadImgs);
                }

                setIsLoading(false);
            }

            processSpreads();
        }
    }, [isOpen])
    const printRef = useRef();

    const getCSSFilter = (photo) => {
        if (photo && photo.filter && photo.filter !== 'auto') {
            const filter = photo.filter;
            return `brightness(${filter.brightness}%) saturate(${filter.saturation}%) contrast(${filter.contrast}%) opacity(${filter.opacity}%) sepia(${filter.sepia}%) grayscale(${filter.grayscale}%)`;
        } else {
            return 'auto';
        }
    }

    const getBorder = (photo) => {
        if (photo && photo.filter && photo.filter.borderWidth) {
            return {
                lineWidth: photo.filter.borderWidth,
                strokeStyle: photo.filter.borderColor
            }
        } else {
            return {
                lineWidth: 0,
                strokeStyle: '#000000'
            }
        }
    }

    const getBoxShadow = (photo) => {
        if (photo && photo.filter && photo.filter.boxShadow) {
            return {
                shadowOffsetX: photo.filter.boxShadow,
                shadowOffsetY: photo.filter.boxShadow,
                shadowColor: photo.filter.boxShadowColor
            }
        } else {
            return {
                shadowOffsetX: 0,
                shadowOffsetY: 0,
                shadowColor: '#000000'
            };
        }
    }

    const handleDownloadPdf = async () => {
        if (pageStore.option1 !== null && pageStore.option2 !== null) {
            if (!isExporting) {
                setIsExporting(true);
                
                try {
                    const clientId = localStorage.getItem('clientId');
                    const orderId = nanoid();

                    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
                    const REGION = process.env.REACT_APP_REGION;

                    AWS.config.update({
                        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
                        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
                    });
                    const s3 = new AWS.S3({
                        params: { Bucket: S3_BUCKET },
                        region: REGION,
                    });

                    const zip = new JSZip();

                    const id = `${clientId}/${orderId}`;
                    const thumbnailFile = `exports/${id}/thumbnail.jpeg`;

                    for (let i = 0; i < spreadImages.length; i++) {
                        let fileName = ``;

                        if (pageStore.layoutType === 'photobook') {
                            fileName = `spread-${i+1}-pages-${(i+1) + i}&${((i+1) + i + 1)}-${pageStore.option1}.jpeg`;
                        }

                        if (pageStore.layoutType !== 'photobook') {
                            fileName = `order-${orderId}/${i+1} - ${pageStore.layoutType} - ${pageStore.option1.replace('/','-')} - ${pageStore.option2.replace('/','-')} - QTY ${multiQuantity[i]}.jpeg`;
                        }

                        const spreadImg = spreadImages[i];

                        if (i === 0) {
                            function dataURItoBlob(dataURI) {
                                var binary = atob(dataURI.split(',')[1]);
                                var array = [];
                                for(var i = 0; i < binary.length; i++) {
                                    array.push(binary.charCodeAt(i));
                                }
                                return new Blob([new Uint8Array(array)], {type: 'image/jpeg'});
                            }
                            const params = {
                                Bucket: S3_BUCKET,
                                Key: thumbnailFile,
                                Body: dataURItoBlob(spreadImg.src),
                            };
        
                            await s3.putObject(params)
                                .on("httpUploadProgress", (evt) => {
                                    // console.log(
                                    //     "Uploading " +
                                    //         parseInt((evt.loaded * 100) / evt.total) +
                                    //         "%",
                                    // );
                                })
                                .promise();
                        }

                        zip.file(fileName, spreadImg.src.substr(spreadImg.src.indexOf(',')+1), {base64: true});
                    }

                    const content = await zip.generateAsync({ type: 'blob' });

                    const s3FileName = `exports/${id}/print-output-${orderId}.zip`;
                    const params = {
                        Bucket: S3_BUCKET,
                        Key: s3FileName,
                        Body: content,
                    };

                    await s3.putObject(params)
                        .on("httpUploadProgress", (evt) => {
                            // console.log(
                            //     "Uploading " +
                            //         parseInt((evt.loaded * 100) / evt.total) +
                            //         "%",
                            // );
                        })
                        .promise();

                    setIsExporting(false);
                    
                    const option1 = pageStore.option1;
                    const option2 = pageStore.option2;

                    let layouts = PHOTO_SAFE_LAYOUTS;
                    let variantId;
                    let minOrder = 1;

                    if (pageStore.layoutType === 'decoPrint') {
                        layouts = DECO_PRINT_LAYOUTS;
                    } else if (pageStore.layoutType === 'photoPrint') {
                        layouts = PHOTO_PRINT_LAYOUTS
                    }

                    if (pageStore.layoutType === 'photobook') {
                        variantId = layouts[option1].variants[option2].variantId;
                        minOrder = layouts[option1].variants[option2].minOrder || 1;
                    } else {
                        variantId = layouts[option1][option2].variantId;
                        minOrder = layouts[option1][option2].minOrder || 1;
                    }

                    if (variantId) {
                        const s3Url = `${process.env.REACT_APP_S3_BUCKET_URL}/${s3FileName}`;
                        const thumbnailImg = `${process.env.REACT_APP_S3_BUCKET_URL}/${thumbnailFile}`;
                        const projectId = localStorage.getItem('projectId');
                        const project = projectStore.projects.find((p) => p.projectId === projectId)
                        const projectName = project ? project.projectName : 'Unnamed Project';
                        let qty = quantity;
                        if (pageStore.layoutType !== 'photobook') {
                            qty = multiQuantity.reduce((a, b) => a + b, 0);
                        }
                        const cartItem = {
                            projectId: projectId,
                            projectName: projectName,
                            id: variantId,
                            link: s3Url,
                            image: thumbnailImg,
                            quantity: qty,
                            minOrder: minOrder,
                            price: getPrice()
                        };
                        cartStore.addItem(cartItem);
                        onClose();
                        setShowCart(true);
                    }
                } catch(ex) {
                    setIsExporting(false);
                }
            }
        }
    };

    const getPrice = function() {
        let price = 0;

        if (pageStore.option1 && pageStore.option2) {
            const option1 = pageStore.option1;
            const option2 = pageStore.option2;

            let layouts = PHOTO_SAFE_LAYOUTS;

            if (pageStore.layoutType === 'decoPrint') {
                layouts = DECO_PRINT_LAYOUTS;
            } else if (pageStore.layoutType === 'photoPrint') {
                layouts = PHOTO_PRINT_LAYOUTS
            }

            if (pageStore.layoutType === 'photobook') {
                price = layouts[option1].variants[option2].price;
            } else {
                price = layouts[option1][option2].price;
            }
        }

        const formatter = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'PHP',
        });

        let qty = quantity;
        if (pageStore.layoutType !== 'photobook') {
            qty = multiQuantity.reduce((a, b) => a + b, 0);
        }

        return formatter.format(price * qty);
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={"full"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader display={'flex'} flexDirection={'column'} >
                    <Flex gap={'12px'} flexDirection={'row'}> 
                        <Text> Preview </Text>
                        <Checkbox
                            mb={"2px"}
                            isChecked={isFullSize}
                            onChange={(e) => {setIsFullSize(e.target.checked)}}
                        >
                            Detailed View
                        </Checkbox>
                        <Text flexGrow={1}> </Text>
                        <Text> Total Price: {getPrice()} </Text>
                        {pageStore.layoutType === 'photobook' && (<Quantity quantity={quantity} setQuantity={setQuantity}></Quantity>)}
                        {pageStore.layoutType !== 'photobook' && (<Text>(Total Qty: {multiQuantity.reduce((a, b) => a + b, 0)})</Text>)}
                        <Button 
                            bg="wps.darkgray"
                            color="wps.white"
                            mr={5}
                            w="full"
                            fontSize={"13px"}
                            fontWeight={"medium"}
                            borderRadius={0}
                            outline="0px solid black"
                            _hover={{
                                outline: "1px solid black",
                            }}
                            _active={{}}
                            px="30px"
                            py="4px"
                            h={'34px'}
                            onClick={() => {
                                handleDownloadPdf();
                            }}
                            width={100}
                            disabled={isExporting || isLoading }
                        >
                            {isExporting || isLoading ? 
                                <Spinner
                                    size="sm"
                                /> 
                                : <Text>Add to Cart</Text>
                            }
                        </Button>
                        <ModalCloseButton mt={2} />
                    </Flex>

                    { pageStore.layoutType === 'photobook' && (
                        <Flex mt="14px" pt="12px" borderTop="1px solid black" alignItems={"center"}>
                            <Icon 
                                as={MdOutlineWarningAmber} 
                                boxSize={"18px"} 
                                margin={"0"} 
                                color={"#2F2F2F"}
                                marginRight={"6px"}
                            />
                            <Text fontSize={"12px"}>
                                Photo Safe products have margins on all sides. Images outside the layout trim marks will be cropped. 
                            </Text>
                        </Flex>
                    )}
                </ModalHeader>
               
                <ModalBody bg="blackAlpha.500">
                    <div ref={printRef}>
                        {isLoading && (
                            <Flex justifyContent={"center"} alignItems={"center"} marginTop="45px" marginBottom="45px">
                                <Spinner size="xl"/> 
                            </Flex>)
                        }

                        {spreadImages.length > 0 && (<VStack 
                            transform={`scale(${isFullSize ? 100 : 40}%)`}
                            transformOrigin={"50% 0%"}
                            ref={printRef} 
                            gap={4} 
                            marginBottom={8} 
                            marginTop={8}
                        >
                            {spreadImages.map((image, i) => (
                                <>
                                    <Flex>
                                        <img src={image.src} height="auto" />

                                        { !isFullSize && pageStore.layoutType !== 'photobook' && (
                                            <Flex 
                                                position={"absolute"}
                                                right="-280px"
                                                transform={`scale(200%)`}
                                                transformOrigin={"50% 0%"}
                                                flexDirection={"column"}
                                                background={"wps.white"}
                                                borderBottom={"2px solid"}
                                                borderColor="wps.lightgray"
                                                padding="16px"
                                            >
                                                <Text fontWeight="bold" fontSize="18px" marginBottom={"4px"}> Quantity: </Text>
                                                <Quantity quantity={multiQuantity[i]} setQuantity={(newQuantity) => {
                                                    const mq = cloneDeep(multiQuantity);
                                                    mq[i] = newQuantity;
                                                    setMultiQuantity(mq);
                                                }}></Quantity>
                                            </Flex>
                                        )}
                                    </Flex>
                                </>
                            ))}
                        </VStack>)}

                        
                    </div>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

const Quantity = ({quantity, setQuantity}) => {
    return (
        <Flex
            border="1px solid"
            align="center"
            height={'34px'}
            borderColor={"wps.bluegray"}
            maxW="100px"
            bg="wps.white"
        >
            <Flex h="full" justify={"center"} align="center">
                <IconButton
                    icon={<AiOutlineMinus />}
                    bg="transparent"
                    borderRadius={"0px"}
                    h="full"
                    p="8px"
                    cursor={"pointer"}
                    tabIndex={0}
                    onClick={() => {
                        setQuantity(quantity > 1 ? quantity - 1 : 1);
                    }}
                />
            </Flex>
            <Box
                border="none"
                w="80px"
                textAlign={"center"}
                borderRadius={"0px"}
                outline="0px solid black"
                _focus={{
                    outline: "1px solid black",
                }}
            >{`${quantity}`}</Box>
            <Flex h="full" justify={"center"} align="center">
                <IconButton
                    icon={<AiOutlinePlus />}
                    bg="transparent"
                    borderRadius={"0px"}
                    h="full"
                    p="8px"
                    cursor={"pointer"}
                    tabIndex={0}
                    onClick={() => {
                        setQuantity(quantity === 100 ? quantity : quantity + 1);
                    }}
                />
            </Flex>
        </Flex>
    );
};