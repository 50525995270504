import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "./App.css";
import { Layout } from "./components/Layout";
import { colors } from "./styles";
import { fonts } from "./styles/fonts";

import "@fontsource/quicksand/400.css";
import "@fontsource/quicksand/500.css";
import "@fontsource/quicksand/600.css";
import "@fontsource/quicksand/700.css";
import "@fontsource/bebas-neue/400.css";
import '@fontsource/allura';
import '@fontsource/great-vibes';
import '@fontsource/pacifico';
import '@fontsource/lobster';
import '@fontsource/cookie';
import '@fontsource/roboto';
import '@fontsource/cinzel-decorative';
import '@fontsource/amatic-sc';
import '@fontsource/nanum-myeongjo';
import './fonts/fonts.css'

import { useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { radioTheme } from "./styles/radio";
import { checkboxTheme } from "./styles/checkbox";
import { inputTheme } from "./styles/input";
import { QueryClient, QueryClientProvider } from "react-query";
import { nanoid } from "nanoid";
import { usePageStore } from "./states/usePageStore";
import { useProjectStore } from "./states/useProjectStore";
import { inchesToPx } from "./utils/page";
import { loadProgress } from "./utils/progress-saver";

import { DECO_PRINT_LAYOUTS } from './configs/deco-print-layouts';
import { PHOTO_PRINT_LAYOUTS } from './configs/photo-print-layouts';
import { PHOTO_SAFE_LAYOUTS } from './configs/photo-safe-layouts';

const theme = extendTheme(colors, fonts, {
    components: {
        Radio: radioTheme,
        Checkbox: checkboxTheme,
        Input: inputTheme,
        Progress: {
            baseStyle: {
              filledTrack: {
                bg: '#2F2F2F'
              }
            }
        }
    },
});

const queryClient = new QueryClient();

function App() {
    const [searchParams] = useSearchParams();
    const shopifyApiUrl = searchParams.get("shopifyAPIUrl");
    const productId = searchParams.get("productId");
    const currentLayoutType = searchParams.get("currentLayoutType");
    const returnUrl = searchParams.get("returnUrl");
    const urlClientId = searchParams.get("clientId");
    const option1 = searchParams.get("option1");
    const option2 = searchParams.get("option2");

    const pageStore = usePageStore((state) => state);
    const projectStore = useProjectStore((state) => state);

    let backgroundSave;

    useEffect(() => {
        if (shopifyApiUrl) {
            localStorage.setItem("shopifyAPIUrl", shopifyApiUrl);
        }
    }, [shopifyApiUrl]);

    useEffect(() => {
        if (productId) {
            localStorage.setItem("productId", productId);
        }
    }, [productId]);

    useEffect(() => {
        if (currentLayoutType) {
            pageStore.setLayoutType(currentLayoutType);
            projectStore.setPostCreateProjectFunc(() => {
                if (option1) {
                    pageStore.setOption1(option1);
                    if (option2) pageStore.setOption2(option2);
                }
            });
        }
    }, [currentLayoutType]);

    useEffect(() => {
        if (returnUrl) {
            localStorage.setItem("returnUrl", returnUrl);
        }
    }, [returnUrl]);

    const [isClientIdSet, setIsClientId] = useState(false);

    useEffect(() => {
        document.title = 'Wonder Photo Shop - Photo Layouting Platform';

        document.addEventListener('keydown', function(event){
            if (event.code === 'Delete') {
                pageStore.deleteSelectedPhotoBox();
            }
        });
        
        if (isClientIdSet) {
            return;
        }
        let clientId = urlClientId ?? localStorage.getItem('clientId') ?? nanoid();
        localStorage.setItem('clientId', clientId);

        if (urlClientId || !localStorage.getItem('clientId')) {
            localStorage.removeItem('projectId');
        }

        setIsClientId(true);
    }, []);

    useEffect(() => {
        generateTemplates(pageStore);

        if (!searchParams.get("currentLayoutType")) {
            loadProgress(localStorage.getItem('clientId'), pageStore, localStorage.getItem('projectId')); 
        } else {
            if (localStorage.getItem('projectId')) {
                localStorage.removeItem('projectId');
            }
        }

        if (backgroundSave) clearInterval(backgroundSave);

        backgroundSave = setInterval(() => {
            if (isClientIdSet) {
                const clientId = localStorage.getItem('clientId');
                const projectId = localStorage.getItem('projectId');
                const projectName = localStorage.getItem('projectName');

                if (clientId && projectId && projectName) {
                    pageStore.saveCurrentProgress(clientId, projectId, projectName);
                }
            }
        }, 30000);
    }, [isClientIdSet]);

    return (
        <QueryClientProvider client={queryClient}>
            <ChakraProvider theme={theme}>
                {isClientIdSet && <Layout />}
            </ChakraProvider>
        </QueryClientProvider>
    );
}

const generateTemplates = (pageStore) => {
    const generatePrintTemplate = (widthInches, heightInches, marginsInches) => {
        const widthPx = inchesToPx(widthInches);
        const heightPx = inchesToPx(heightInches);
        const marginsPx = {
            top: inchesToPx(marginsInches.top),
            left: inchesToPx(marginsInches.left),
            right: inchesToPx(marginsInches.right),
            bottom: inchesToPx(marginsInches.bottom),
        };
    
        return {
            width: widthPx,
            height: heightPx,
            margins: marginsPx,
            spreads: [
                {
                    backgroundColor: '#FFFFFF',
                    boxes: [
                        {
                            x: 0,
                            y: 0,
                            width: widthPx - marginsPx.left - marginsPx.right,
                            height: heightPx - marginsPx.top - marginsPx.left 
                        }
                    ],
                    texts: []
                }
            ]
        }
    };
    
    // options { rows, columns, gap, configuration } -- per page
    const generatePhotobookTemplate = (widthInches, heightInches, marginsInches, coverWidthInches, coverHeightInches, options) => {
        const widthPx = inchesToPx(widthInches);
        const heightPx = inchesToPx(heightInches);
        const marginsPx = {
            top: inchesToPx(marginsInches.top),
            left: inchesToPx(marginsInches.left),
            right: inchesToPx(marginsInches.right),
            bottom: inchesToPx(marginsInches.bottom),
        };

        const rows = options.rows;
        const columns = options.columns;
        const gap = inchesToPx(options.gap);
        const configuration = options.configuration;

        const actualWidth = widthPx - marginsPx.left - marginsPx.right;
        const actualHeight = heightPx - marginsPx.top - marginsPx.bottom;

        const boxHeight = (actualHeight - (gap * (rows - 1))) / rows;
        const boxWidth = (actualWidth - (gap * (columns - 2))) / (columns);

        const boxes = [];
        const coverBoxes = [];

        const coverWidthPx = inchesToPx(coverWidthInches);
        const coverHeightPx = inchesToPx(coverHeightInches);
        const coverGap = inchesToPx(0.5);
        const coverActualWidth = coverWidthPx - marginsPx.left - marginsPx.right
        const coverActualHeight = coverHeightPx - marginsPx.top - marginsPx.bottom;

        coverBoxes.push(
        {
            x: coverWidthPx - coverGap,
            y: 0,
            width: coverActualWidth + coverGap,
            height: coverActualHeight,
        },
        {
            x: 0,
            y: 0,
            width: ((coverActualWidth - coverGap) / 2) + (coverGap / 2),
            height: ((coverActualHeight - coverGap) / 2),
        },
        {
            x: ((coverActualWidth - coverGap) / 2) + coverGap + (coverGap / 2),
            y: 0,
            width: ((coverActualWidth - coverGap) / 2) + (coverGap / 2),
            height: ((coverActualHeight - coverGap) / 2),
        },
        {
            x: 0,
            y: ((coverActualHeight - coverGap) / 2) + coverGap,
            width: ((coverActualWidth - coverGap) / 2) + (coverGap / 2),
            height: ((coverActualHeight - coverGap) / 2),
        },
        {
            x: ((coverActualWidth - coverGap) / 2) + coverGap + (coverGap / 2),
            y: ((coverActualHeight - coverGap) / 2) + coverGap,
            width: ((coverActualWidth - coverGap) / 2) + (coverGap / 2),
            height: ((coverActualHeight - coverGap) / 2),
        })


        // PREVIEW
        const boxesPreview = [];
        let heightPreview = 120;
        let widthPreview = 120 * (widthPx / heightPx);

        let marginsPreview = {
            top: 120 * (marginsPx.top / heightPx),
            left: 120 * (marginsPx.left / heightPx),
            right: 120 * (marginsPx.right / heightPx),
            bottom: 120 * (marginsPx.bottom / heightPx),
        };

        let gapPreview = 120 * (gap / heightPx);

        const isLandscape = widthPx > heightPx;
        if (isLandscape) {
            widthPreview = 120;
            heightPreview = 120 * (heightPx / widthPx);
            marginsPreview = {
                top: 120 * (marginsPx.top / widthPx),
                left: 120 * (marginsPx.left / widthPx),
                right: 120 * (marginsPx.right / widthPx),
                bottom: 120 * (marginsPx.bottom / widthPx),
            };
            gapPreview = 120 * (gap / widthPx);
        }

        const actualPreviewWidth = widthPreview - marginsPreview.left - marginsPreview.right;
        const actualPreviewHeight = heightPreview - marginsPreview.top - marginsPreview.left;

        const boxPreviewHeight = (actualPreviewHeight - (gapPreview * (rows - 1))) / rows;
        const boxPreviewWidth = (actualPreviewWidth - (gapPreview * (columns - 1))) / (columns);

        // config [1, 1, 1, 1] or [1, 2, 1]
        for (let i = 0; i < configuration.length; i++) {
            const rowConfig = configuration[i];
            for (let j = 0; j < rowConfig.length; j++) {
                const curr = rowConfig[j];
                if (curr === 1) {
                    const box = {
                        x: (boxWidth * j) + (j === 0 ? 0 : gap * j),
                        y: (boxHeight * i) + (i === 0 ? 0 : gap * i),
                        width: boxWidth,
                        height: boxHeight
                    }

                    const box2 = {
                        x: box.x + widthPx - gap,
                        y: box.y,
                        width: boxWidth,
                        height: boxHeight
                    }

                    boxes.push(box);
                    boxes.push(box2);

                    const boxPreview = {
                        x: (boxPreviewWidth * j) + (j === 0 ? 0 : gapPreview * j),
                        y: (boxPreviewHeight * i) + (i === 0 ? 0 : gapPreview * i),
                        width: boxPreviewWidth,
                        height: boxPreviewHeight
                    }

                    boxesPreview.push(boxPreview);
                }
            }
        }        

        return {
            width: widthPx * 2,
            height: heightPx,
            margins: marginsPx,
            spreads: [
                {
                    width: coverWidthPx * 2,
                    height: coverHeightPx,
                    backgroundColor: '#FFFFFF',
                    boxes: coverBoxes,
                    texts: []
                },
                {
                    backgroundColor: '#FFFFFF',
                    boxes: boxes,
                    texts: []
                },
            ],
            preview: {
                width: widthPreview,
                height: heightPreview,
                margins: marginsPreview,
                backgroundColor: '#FFFFFF',
                boxes: boxesPreview
            }
        }
    }

    const decoPrintLayoutTemplates = {}
    Object.keys(DECO_PRINT_LAYOUTS).map((opt1) => {
        const opt1Value = DECO_PRINT_LAYOUTS[opt1];
        decoPrintLayoutTemplates[opt1] = {}
        return Object.keys(opt1Value).map((opt2) => {
            const layoutTemplate = opt1Value[opt2];
            decoPrintLayoutTemplates[opt1][opt2] = generatePrintTemplate(layoutTemplate.width, layoutTemplate.height, layoutTemplate.margins);
        });
    });

    const photoPrintLayoutTemplates = {}
    Object.keys(PHOTO_PRINT_LAYOUTS).map((opt1) => {
        const opt1Value = PHOTO_PRINT_LAYOUTS[opt1];
        photoPrintLayoutTemplates[opt1] = {}
        return Object.keys(opt1Value).map((opt2) => {
            const layoutTemplate = opt1Value[opt2];
            photoPrintLayoutTemplates[opt1][opt2] = generatePrintTemplate(layoutTemplate.width, layoutTemplate.height, layoutTemplate.margins);
        });
    });

    const photoBookLayoutTemplates = {}

    Object.keys(PHOTO_SAFE_LAYOUTS).map((opt1) => {
        const option1 = PHOTO_SAFE_LAYOUTS[opt1];
        if (option1) {
            const width = option1.width;
            const height = option1.height;
            const margins = option1.margins;
            const templates = option1.templates;
            const coverWidth = option1.cover.width;
            const coverHeight = option1.cover.height;

            photoBookLayoutTemplates[opt1] = [];

            if (templates) {
                templates.forEach((options) => {
                    const photobookTemplate = generatePhotobookTemplate(width, height, margins, coverWidth, coverHeight, options)
                    photoBookLayoutTemplates[opt1].push(photobookTemplate)
                });
            }
        } else {
            photoBookLayoutTemplates[opt1] = [];
        }
    });

    const layoutTemplates = {
        photobook: photoBookLayoutTemplates,
        decoPrint: decoPrintLayoutTemplates,
        photoPrint: photoPrintLayoutTemplates
    }

    pageStore.setLayoutTemplates(layoutTemplates);
}

export default App;
