const AWS = require('aws-sdk');
const cloneDeep = require('lodash.clonedeep');

export const saveProgress = async (clientId, pageStore, projectId, projectName, isNewProject) => {
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
    const REGION = process.env.REACT_APP_REGION;

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    });
    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });

    const data = cloneDeep(pageStore.data);

    const s3FileName = `progress/${clientId}/${projectId}.json`;
    const params = {
        Bucket: S3_BUCKET,
        Key: s3FileName,
        Body: JSON.stringify({
            projectId: projectId,
            projectName: projectName,
            template: isNewProject ? {} : pageStore.template,
            data: isNewProject ? {} : data,
            option1: isNewProject ? null : pageStore.option1,
            option2: isNewProject ? null : pageStore.option2,
            photobookLayoutIndex: pageStore.photobookLayoutIndex,
            layoutType: pageStore.layoutType,
            lastSavedDate: new Date().getTime()
        }),
        ContentType: 'application/json',
    };

    await s3.putObject(params)
        .on("httpUploadProgress", (evt) => {
        })
        .promise();
};

export const loadProgress = async (clientId, pageStore, projectId) => {
    await fetch(`${process.env.REACT_APP_S3_BUCKET_URL}/progress/${clientId}/${projectId}.json`)
    .then((res) => {
        return res.json()
    }).then((res) => {
        localStorage.setItem('clientId', clientId);
        localStorage.setItem('projectId', projectId);
        localStorage.setItem('projectName', res.projectName || 'Unnamed Project');

        pageStore.setLayoutType(res.layoutType);

        setTimeout(() => {
            pageStore.setOption1(res.option1);
            pageStore.setOption2(res.option2);
        }, 20);

        setTimeout(() => {
            if (res.layoutType === 'photobook') pageStore.loadPhotobookLayoutIndex(res.photobookLayoutIndex);
        }, 40);

        setTimeout(() => {
            pageStore.loadTemplate(res.template);
            pageStore.loadData(res.data);
        }, 60);
    }).catch((ex) =>{
        
    });
};

export const deleteProgress = async (key, projectStore) => {
    const S3_BUCKET = process.env.REACT_APP_S3_BUCKET;
    const REGION = process.env.REACT_APP_REGION;

    AWS.config.update({
        accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
    });
    const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
    });
    const params = {
        Bucket: S3_BUCKET,
        Key: key,
    };

    await s3.deleteObject(params).promise();
    projectStore.deleteProject(key);
}