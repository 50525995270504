import { create } from "zustand";

export const usePhotoEditor = create((set) => ({
    isEditMode: false,
    onDoneEditing: null,
    onFilter: null,
    cropper: null,
    outputImage: '',
    initBackgroundEdit: null,
    setInitBackgroundEdit: (initBackgroundEdit) => set(() => ({ initBackgroundEdit: initBackgroundEdit })),
    enterEditMode: (photo, onDoneEditing, onFilter) => set((state) => {
        if (state.isEditMode) {
            state.exitEditMode();
        }

        return {
            isEditMode: true,
            onDoneEditing: onDoneEditing,
            onFilter: onFilter,
            brightness: photo.filter?.brightness || photo.filter?.brightness === 0 ? photo.filter?.brightness : 100,
            saturation: photo.filter?.saturation || photo.filter?.saturation === 0? photo.filter?.saturation : 100,
            contrast: photo.filter?.contrast || photo.filter?.contrast === 0 ? photo.filter?.contrast : 100,
            opacity: photo.filter?.opacity || photo.filter?.opacity === 0 ? photo.filter?.opacity : 100,
            sepia: photo.filter?.sepia ? photo.filter?.sepia : 0,
            grayscale: photo.filter?.grayscale ? photo.filter?.grayscale : 0,
            borderWidth: photo.filter?.borderWidth ? photo.filter?.borderWidth : 0,
        }
    }),
    setCropper: (cropper) => set(() => ({
        cropper: cropper
    })),
    setOutputImage: (outputImage) => set(() => ({ outputImage: outputImage })),
    exitEditMode: () => set((state) => {
        if (state.onDoneEditing) {
            state.onDoneEditing(state.cropper);
        }

        if (state.cropper) {
            state.cropper.destroy();
        }

        return {
            isEditMode: false,
            onDoneEditing: null,
            onFilter: null,
            cropper: null,
            outputImage: '',
            brightness: 100,
            contrast: 100,
            saturation: 100,
            opacity: 100,
            sepia: 0,
            grayscale: 0,
            scaleX: 1,
            scaleY: 1,
            borderWidth: 0,
        }
    }),
    filter: '',
    brightness: 100, // 0 - 200
    setFilter: () => set((state) => {
        if (state.onFilter) {
            state.onFilter({
                brightness: state.brightness,
                saturation: state.saturation,
                contrast: state.contrast,
                opacity: state.opacity,
                sepia: state.sepia,
                grayscale: state.grayscale,
                borderWidth: state.borderWidth,
                borderColor: state.borderColor,
                boxShadow: state.boxShadow,
                boxShadowColor: state.boxShadowColor,
            })
        }
        const filter = `brightness(${state.brightness}%) saturate(${state.saturation}%) contrast(${state.contrast}%) opacity(${state.opacity}%) sepia(${state.sepia}%) grayscale(${state.grayscale}%)`;
        if (document.querySelector('.cropper-crop-box')) {
            document.querySelector('.cropper-crop-box').style.filter = filter;
            document.querySelector('.cropper-crop-box').style.border = `${state.borderWidth}px solid ${state.borderColor}`;
            document.querySelector('.cropper-crop-box').style['box-shadow'] = `${state.boxShadow}px ${state.boxShadow}px ${state.boxShadowColor}`;
        }
        return { filter: filter }
    }),
    rotateLeft: () => set((state) => { 
        state.cropper.rotate(-90);
        return { }
    }),
    rotateRight: () => set((state) => { 
        state.cropper.rotate(90);
        return { }
    }),
    scaleX: 1,
    transformHorizontal: () => set((state) => { 
        const scaleX = state.scaleX === 1 ? -1 : 1;
        state.cropper.scaleX(scaleX);
        return { scaleX: scaleX }
    }),
    scaleY: 1,
    transformVertical: () => set((state) => {
        const scaleY = state.scaleY === 1 ? -1 : 1;
        state.cropper.scaleY(scaleY);
        return { scaleY: scaleY }
    }),
    zoom: 0,
    zoomIn: () => set((state) => {
        const zoom = state.zoom + 0.1;
        state.cropper.zoom(+0.1);
        return { zoom: zoom }
    }),
    zoomOut: () => set((state) => {
        const zoom = state.zoom - 0.1;
        state.cropper.zoom(-0.1);
        return { zoom: zoom }
    }),
    setBrightness: (brightness) => set(() => ({ brightness: brightness })),
    saturation: 100, // 0 - 200
    setSaturation: (saturation) => set(() => ({ saturation: saturation })),
    contrast: 100, // 0 - 200
    setContrast: (contrast) => set(() => ({ contrast: contrast })),
    opacity: 100, // 0 - 100
    setOpacity: (opacity) => set(() => ({ opacity: opacity })),
    sepia: 0, // 0 - 100
    setSepia: (sepia) => set(() => ({ sepia: sepia })),
    grayscale: 0, // 0 - 100,
    setGrayscale: (grayscale) => set(() => ({ grayscale: grayscale })),
    borderWidth: 0,
    borderColor: '#000000',
    setBorderWidth: (borderWidth) => set(() => ({ borderWidth: borderWidth })),
    setBorderColor: (borderColor) => set(() => ({ borderColor: borderColor })),
    boxShadow: 0,
    boxShadowColor: '#000000',
    setBoxShadow: (boxShadow) => set(() => ({ boxShadow: boxShadow })),
    setBoxShadowColor: (boxShadowColor) => set(() => ({ boxShadowColor: boxShadowColor })),
}));
