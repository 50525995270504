import { create } from "zustand";
import { usePageStore } from "./usePageStore";

export const usePhotoStore = create(
    (set, get) => ({
        allPhotos: [],
        setAllPhotos: (photos) => set(() => ({ allPhotos: photos })),
        visiblePhotos: [],
        setVisiblePhotos: () => set((state) => {
            let photos = state.allPhotos;
            if (state.hideUsedPhotos) {
                photos = state.getUnusedPhotos();
            }
            return { visiblePhotos: photos }
        }),
        deletePhoto: (i) => set((state) => {
            state.allPhotos.splice(i, 1);
            return { allPhotos: state.allPhotos }
        }),
        hideUsedPhotos: false,
        setHideUsedPhotos: (hideUsedPhotos) => set(() => ({ hideUsedPhotos: hideUsedPhotos })),
        getUnusedPhotos: () => {
            const spreads = usePageStore.getState().data.spreads;
            let unusedPhotos = [...get().allPhotos];

            for (const spread of spreads) {
                const boxes = spread.boxes || [];
                for (const box of boxes) {
                    const photo = box.photo;
                    if (photo && photo.src) {
                        const photoSrc = photo.originalSrc;
                        unusedPhotos = [...unusedPhotos].filter((p) => p.originalSrc !== photoSrc);
                    }
                }
            }

            return unusedPhotos;
        }
    })
);
