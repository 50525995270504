import { create } from "zustand";

export const useCartStore = create(
    (set, get) => ({
        items: [],
        setItems: (items) => set(() => ({ items: items })),
        addItem: (item) => set((state) => {
            state.items = state.items.filter((i) => {
                return item.projectId != i.projectId;
            })
            state.items.unshift(item);
            return { items: state.items }
        }),
        deleteItem: (projectId) => set((state) => {
            state.items = state.items.filter((item) => {
                return item.projectId != projectId;
            })
            return { items: state.items }
        })
    })
);
