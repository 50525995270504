import { Grid, GridItem } from "@chakra-ui/react";
import { useState } from "react";
import { Navbar } from "../Navbar";
import { LeftSidebar } from "../LeftSidebar";
import { Workspace } from "../Workspace";
import { RightSidebar } from "../RightSidebar";

export const Layout = () => {
    const [showLeftTabDetails, setShowLeftTabDetails] = useState(true);
    const [showRightSidebar, setShowRightSidebar] = useState(false);
    const [leftSideBarActiveTab, setLeftSideBarActiveTab] = useState("Layouts");
    const [isProjectSaving, setIsProjectSaving] = useState(false);

    return (
        <Grid
            templateAreas={`
            'navbar navbar navbar'
            'left-sidebar workspace right-sidebar'
        `}
            templateRows={{ base: "60px auto", lg: "72px auto" }}
            templateColumns={`${showLeftTabDetails ? "351px" : "83px"} auto ${
                showRightSidebar ? "183px" : "36px"
            }`}
            h="100vh"
            w="full"
            overflowY="hidden"
        >
            <GridItem area="navbar">
                <Navbar isProjectSaving={isProjectSaving} setIsProjectSaving={setIsProjectSaving} />
            </GridItem>
            <GridItem area="left-sidebar">
                <LeftSidebar
                    showTabDetails={showLeftTabDetails}
                    setShowTabDetails={setShowLeftTabDetails}
                    activeTab={leftSideBarActiveTab}
                    setActiveTab={setLeftSideBarActiveTab}
                />
            </GridItem>
            <GridItem area="workspace">
                <Workspace setShowRightSidebar={setShowRightSidebar} isProjectSaving={isProjectSaving}/>
            </GridItem>
            <GridItem area="right-sidebar">
                <RightSidebar
                    showRightSidebar={showRightSidebar}
                    setShowRightSidebar={setShowRightSidebar}
                />
            </GridItem>
        </Grid>
    );
};
