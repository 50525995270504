import { Flex, Icon, Text, VStack } from "@chakra-ui/react";
import { MdOutlineFileUpload } from "react-icons/md";

export const EmptyPhotosPlaceholder = () => {
    const handleDragOver = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        e.stopPropagation();
    };

    const handleDrop = (e) => {
        e.preventDefault();
        e.stopPropagation();
        handleFileUpload(e.dataTransfer.files);
    };

    const handleFileUpload = (files) => {
    };
    return (
        <Flex
            justify={"center"}
            align="center"
            border="2px dashed"
            borderColor={"wps.lightgray"}
            px={4}
            py="20px"
            h="full"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
        >
            <Flex
                w="full"
                h="full"
                justify="center"
                align="center"
                flexDirection={"column"}
                gap={"14px"}
                maxW="285px"
                maxH={"185px"}
            >
                <Icon
                    as={MdOutlineFileUpload}
                    boxSize={"71px"}
                    fill="wps.medgray"
                />
                <VStack>
                    <Text
                        color="wps.medgray"
                        fontWeight={"bold"}
                        textAlign={"center"}
                    >
                        Upload photos
                    </Text>
                    <Text color="wps.medgray" textAlign={"center"}>
                        Select multiple photos from your computer and drag them
                        here
                    </Text>
                </VStack>
            </Flex>
        </Flex>
    );
};
