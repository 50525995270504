import { Button, Image, Text } from "@chakra-ui/react";

export const Tab = ({
    icon,
    name,
    imageStyles,
    isActive = false,
    setActiveTab,
    isFirstTab = false,
}) => {
    return (
        <Button
            borderRadius={0}
            flexDirection={"column"}
            justify={"center"}
            align={"center"}
            w="full"
            cursor={"pointer"}
            bg={isActive ? "wps.white" : "transparent"}
            borderColor={"wps.white"}
            minH="73px"
            {...(isActive
                ? {
                      borderTop: isFirstTab ? "0px" : "1px solid #DDDEDF",
                      borderBottom: "1px solid #DDDEDF",
                  }
                : { borderRight: "1px solid #DDDEDF" })}
            tabIndex={"0"}
            onClick={setActiveTab}
        >
            <Image src={icon} height={"33px"} width="33px" {...imageStyles} />
            <Text
                fontWeight={"bold"}
                fontSize={"11px"}
                textAlign={"center"}
                textTransform={"uppercase"}
            >
                {name}
            </Text>
        </Button>
    );
};
