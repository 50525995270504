import {
    Button,
    Flex,
    Spinner,
    Text,
    useDisclosure,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    IconButton,
    InputGroup,
    Input,
    Icon,
    Image
} from "@chakra-ui/react";
import { useEffect, useState, useRef } from "react";
import { usePageStore } from "../../../../states/usePageStore";
import { useProjectStore } from "../../../../states/useProjectStore";
import { nanoid } from "nanoid";
import { saveProgress, loadProgress, deleteProgress } from "../../../../utils/progress-saver";
import { AiOutlinePlus } from "react-icons/ai";
import { MdModeEdit } from "react-icons/md";
import AWS from "aws-sdk";
import { useQuery } from "react-query";
import { ReactComponent as DeletePage } from "../../../Canvas/images/DeletePage.svg";
import PhotoSafeThumbnail from "../../../../images/Projects/PhotoSafe.webp"
import PhotoPrintThumbnail from "../../../../images/Projects/PhotoPrint.webp"
import DecoPrintThumbnail from "../../../../images/Projects/DecoPrint.webp"
import CloseBottomBar from "../../../../images/Projects/CloseBottomBar.svg";
import OpenBottomBar from "../../../../images/Projects/OpenBottomBar.svg";

export const Projects = ({ display, isProjectSaving, showCreateProjects }) => {
    const pageStore = usePageStore((state) => state);
    const projectStore = useProjectStore((state) => state);
    const [currentProjectId, setCurrentProjectId] = useState('');
    const [projectIdForDelete, setProjectIdForDelete] = useState(null);
    const [projectIdForUpdate, setProjectIdForUpdate] = useState(null);
    const [createProjectName, setCreateProjectName] = useState('');
    const [updateProjectName, setUpdateProjectName] = useState('');
    const [isSaving, setIsSaving] = useState(false);
    const [displayProjects, setDisplayProjects] = useState(true);
    const [isLoadingProject, setIsLoadingProject] = useState(true);
    const [isHoveringProject, setIsHoveringProject] = useState(null);

    const [modalType, setModalType] = useState("create");
    const { isOpen, onOpen, onClose } = useDisclosure();
    const warningModalCancelRef = useRef();

    const fetchProjectsInDirectory = async () => {
        const clientId = localStorage.getItem('clientId');
        const projectId = localStorage.getItem('projectId');
        if (projectId) {
            setCurrentProjectId(projectId);
        }

        AWS.config.update({
            accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID,
            secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY,
            region: process.env.REACT_APP_REGION,
        });

        const s3 = new AWS.S3();

        const params = {
            Bucket: process.env.REACT_APP_S3_BUCKET,
            Prefix: `progress/${clientId}`,
        };

        try {
            const data = await s3.listObjectsV2(params).promise();
            const fileList = data.Contents;
            const loadedFileList = [];
            for(let i = 0; i < fileList.length; i++) {
                const file = fileList[i];
                const src = `${process.env.REACT_APP_S3_BUCKET_URL}/${file.Key}`;
                await fetch(src)
                .then((res) => {
                    return res.json();
                }).then((res) => {
                    loadedFileList.push({
                        src: src,
                        s3FileName: file.Key,
                        projectId: res.projectId,
                        projectName: res.projectName || 'Unnamed Project',
                        lastSavedDate: res.lastSavedDate,
                        layoutType: res.layoutType
                    })
                });
            }
            loadedFileList.sort((a, b) => Number(b.lastSavedDate) - Number(a.lastSavedDate))
            return loadedFileList;
        } catch (error) {
            console.error("Error fetching files:", error);
            return [];
        }
    };

    const { refetch } = useQuery({
        queryKey: ["projects", localStorage.getItem('clientId')],
        queryFn: fetchProjectsInDirectory,
        onSuccess: (fetchProjects) => {
            projectStore.setProjects(fetchProjects)
            setIsLoadingProject(false);
        },
        initialData: [],
        cacheTime: 0,
        refetchOnWindowFocus: false,
    });

    const saveProject = async (projectId, projectName, isNewProject) => {
        await saveProgress(localStorage.getItem('clientId'), pageStore, projectId, projectName, isNewProject)
        .then(() => {
            if (refetch) refetch();
            return;
        });
    }

    const deleteProject = async () => {
        if (projectIdForDelete) {
            const currentProjectId = localStorage.getItem('projectId');
            const project = projectStore.projects.find((project) => (project.projectId === projectIdForDelete));
            deleteProgress(project.s3FileName, projectStore);

            if (currentProjectId) {
                localStorage.removeItem('projectId');
                if (currentProjectId === projectIdForDelete) {
                    pageStore.refreshLayout();
                    if (pageStore.layoutType === 'photobook') {
                        pageStore.setNumberOfPages();
                    } else {
                        pageStore.setTemplate();
                    }

                    let remainingProjects = [...projectStore.projects];
                    remainingProjects.filter((project) => (project.projectId !== projectIdForDelete))
                    if (remainingProjects.length > 0) {
                        const newCurrentProjectId = remainingProjects[0].projectId;
                        localStorage.setItem('projectId', newCurrentProjectId);
                        setCurrentProjectId(newCurrentProjectId);
                        loadProgress(localStorage.getItem('clientId'), pageStore, localStorage.getItem('projectId')); 
                    }
                }
            }

            setProjectIdForDelete(null);
        }
    }
    useEffect(() => {
        if (!localStorage.getItem('projectId') && localStorage.getItem('walkthrough')) {
            setModalType('create');
            onOpen(); 
        }
        setCurrentProjectId(localStorage.getItem('projectId') || '');
    }, []);

    useEffect(() => {
        if (!localStorage.getItem('projectId')) {
            setCurrentProjectId('');
            setModalType('create');
            onOpen();
        }
    }, showCreateProjects)

    return (
        <>           
        <AlertDialog
            isOpen={isOpen}
            leastDestructiveRef={warningModalCancelRef}
            onClose={() => {
                onClose();
                setModalType('create');
            }}
            closeOnEsc={false}
            closeOnOverlayClick={false}
        >
            <AlertDialogOverlay>
            <AlertDialogContent>
                <AlertDialogHeader fontSize='lg' fontWeight='bold'>
                    { modalType === 'create' && (<>Create New Project</>)}
                    { modalType === 'delete' && (<>Delete Project</>)}
                    { modalType === 'update' && (<>Update Project</>)}
                </AlertDialogHeader>

                <AlertDialogBody>
                    { modalType === 'create' && (
                        <InputGroup>
                            <Input
                                value={createProjectName}
                                onChange={(event) => {
                                    setCreateProjectName(event.target.value);
                                }}
                                placeholder={"Project Name"} />
                        </InputGroup>
                    )}

                    { modalType === 'delete' && (
                        <Text> Are you sure you want to delete this project? </Text>
                    )}

                    { modalType === 'update' && (
                        <InputGroup>
                            <Input
                                value={updateProjectName}
                                onChange={(event) => {
                                    setUpdateProjectName(event.target.value);
                                }}
                                placeholder={"Project Name"} />
                        </InputGroup>
                    )}
                </AlertDialogBody>

                <AlertDialogFooter>
                    { (modalType !== 'create' || projectStore.projects.length > 0) && (
                        <Button ref={warningModalCancelRef} onClick={() => {
                            onClose();
                            setModalType('create');
                            setCreateProjectName('');
                            setProjectIdForDelete(null);

                            if (!localStorage.getItem('projectId')) {
                                if (projectStore.projects.length > 0) {
                                    const newCurrentProjectId = projectStore.projects[0].projectId;
                                    localStorage.setItem('projectId', newCurrentProjectId);
                                    setCurrentProjectId(newCurrentProjectId);
                                    loadProgress(localStorage.getItem('clientId'), pageStore, localStorage.getItem('projectId')); 
                                }
                            }
                        }}>
                            Cancel
                        </Button>
                    )}
                    <Button 
                        {...(modalType === 'create' || modalType === 'update' ? {bg: 'wps.lightbeige'} : {})}
                        {...(modalType === 'delete' ? {bg: 'wps.red', color: 'wps.white'} : {})}
                        onClick={() => {
                            if (modalType === 'create') {
                                const projectId = nanoid();
                                localStorage.setItem('projectId', projectId);
                                localStorage.setItem('projectName', createProjectName);
                                pageStore.setOption1(null);
                                pageStore.setOption2(null);
                                pageStore.setTemplate({});
                                pageStore.loadData({});
                                saveProject(projectId, createProjectName, true);
                                setDisplayProjects(true);
                                onClose();
                                setCreateProjectName('');
                                if (projectStore.postCreateProjectFunc) {
                                    projectStore.postCreateProjectFunc();
                                    projectStore.setPostCreateProjectFunc(null);
                                }
                            } else if (modalType === 'update') {
                                const projectId = localStorage.getItem('projectId');
                                localStorage.setItem('projectName', updateProjectName);
                                saveProject(projectId, updateProjectName, false);
                                setDisplayProjects(true);
                                onClose();
                                setProjectIdForUpdate(null);
                                setUpdateProjectName('');
                            } else if (modalType === 'delete') {
                                deleteProject();
                                onClose();
                                setProjectIdForDelete(null);
                            }
                        }}
                        ml={3}
                    >
                        Proceed
                    </Button>
                </AlertDialogFooter>
            </AlertDialogContent>
            </AlertDialogOverlay>
        </AlertDialog>
        
        <Flex
            position="fixed"
            bottom="0px"
            h={displayProjects ? "200px" : "60px"}
            minW="100%"
            zIndex="1003"
            background="wps.white"
            gap={"10px"}
            borderTop={"1px solid #DDDEDF"}
        >
             <Button
                position={"absolute"}
                right="48%"
                bg="unset"
                h="auto"
                minH="17px"
                marginTop="-15px"
                onClick={() => {
                    setDisplayProjects(!displayProjects);
                }}
                _hover={{}}
                _active={{}}
                zIndex={1001}
            >
                <Image src={displayProjects ? CloseBottomBar : OpenBottomBar} />
            </Button>
            
            <Flex
                w="full"
                p="16px"
                flexDirection={"column"}
                gap="10px"
                h="full"
                justifyContent={"flex-start"}
            >
                <Text
                    fontSize={"16px"}
                    fontWeight={"700"}
                    color="wps.buttongray">
                        Projects
                </Text>

                {displayProjects && (
                    <Flex flexDirection={"column"} flexWrap={"wrap"} overflowX={"auto"} gap={"16px"} height={"100%"}>
                        <Flex
                            w="150px"
                            flexDirection={"column"}
                            height={"100%"}
                            gap={"4px"}
                        >
                            <Flex
                                border="1px solid black"
                                flexGrow={"1"}
                            >
                                <IconButton
                                    icon={<AiOutlinePlus />}
                                    bg="transparent"
                                    borderRadius={"0px"}
                                    h="full"
                                    p="8px"
                                    w="full"
                                    cursor={"pointer"}
                                    tabIndex={0}
                                    onClick={() => {
                                        setModalType('create');
                                        onOpen(); 
                                    }}
                                />
                            </Flex>
                            <Flex justifyContent={"center"}>
                                <Text
                                    fontSize={"14px"}
                                    fontWeight={"700"}
                                    color="wps.buttongray"
                                    textAlign={"center"}
                                >
                                    Create New
                                </Text>
                            </Flex>
                        </Flex>

                        {projectStore.projects?.map((project, i) => (
                            <Flex
                                w="150px"
                                flexDirection={"column"}
                                height={"100%"}
                                gap={"4px"}
                                position={"relative"}
                                onMouseEnter={() => {
                                    setIsHoveringProject(project.projectId);
                                }}
                                onMouseLeave={() => {
                                    setIsHoveringProject(null)
                                }}
                            >

                                {isHoveringProject === project.projectId && (
                                <>
                                    <button
                                        style={{
                                            width: `30px`,
                                            height: `30px`,
                                            background: `#DDDEDF`,
                                            borderRadius: '100%',
                                            position: "absolute",
                                            top: "5px",
                                            right: "5px",
                                        }}
                                        onClick={() => {
                                            setProjectIdForDelete(project.projectId);
                                            setModalType('delete');
                                            onOpen(); 
                                        }}
                                    >
                                        <Icon 
                                            as={DeletePage} 
                                            boxSize={"14px"} 
                                            margin={"0"} 
                                            color={"#ffffff"}
                                            style={{
                                                margin: "5px 0px 0px 0px"
                                            }} />
                                    </button>

                                    <button
                                        style={{
                                            width: `30px`,
                                            height: `30px`,
                                            background: `#DDDEDF`,
                                            borderRadius: '100%',
                                            position: "absolute",
                                            top: "5px",
                                            right: "40px",
                                        }}
                                        onClick={() => {
                                            setProjectIdForUpdate(project.projectId);
                                            setUpdateProjectName(project.projectName);
                                            setModalType('update');
                                            onOpen(); 
                                        }}
                                    >
                                        <Icon 
                                            as={MdModeEdit} 
                                            boxSize={"14px"} 
                                            margin={"0"} 
                                            color={"#2F2F2F"}
                                            style={{
                                                margin: "5px 0px 0px 0px"
                                            }} />
                                    </button>
                                </>
                                )}
                                <Flex
                                    border={project.projectId === currentProjectId ? "3px solid #DDCCB2" : "1px solid black"}
                                    flexGrow={"1"}
                                    cursor={"pointer"}
                                    onClick={() => {
                                        if (project.projectId !== localStorage.getItem('projectId')) {
                                            localStorage.setItem('projectId', project.projectId);
                                            localStorage.setItem('projectName', project.projectName);
                                            setCurrentProjectId(project.projectId);
                                            loadProgress(localStorage.getItem('clientId'), pageStore, project.projectId);
                                        }
                                    }}
                                    justifyContent={"center"}
                                    alignItems={"center"}
                                    backgroundSize={"cover"}
                                    backgroundPosition={"center"}
                                    {...(project.layoutType === 'photobook' ? {
                                        backgroundImage: `url("${PhotoSafeThumbnail}")`
                                        } : {})
                                    }
                                    {...(project.layoutType === 'photoPrint' ? {
                                        backgroundImage: `url("${PhotoPrintThumbnail}")`
                                        } : {})
                                    }
                                    {...(project.layoutType === 'decoPrint' ? {
                                        backgroundImage: `url("${DecoPrintThumbnail}")`
                                        } : {})
                                    }
                                    
                                >
                                    {project.projectId === currentProjectId && isProjectSaving && (<Spinner
                                        size="xl"
                                    />)}
                                </Flex>
                                <Flex justifyContent={"center"}>
                                    <Text
                                        fontSize={"14px"}
                                        fontWeight={"700"}
                                        color="wps.buttongray"
                                        textAlign={"center"}
                                    >
                                        {project.projectName}
                                    </Text>
                                </Flex>
                            </Flex>
                        ))}

                        {isLoadingProject && (<Flex alignItems={"center"}>
                            <Spinner
                                size="xl"
                            />
                        </Flex>)}
                    </Flex>
                )}
            </Flex>
        </Flex>
    </>

    );
};
