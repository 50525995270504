import { Box, Flex, Spinner, Text } from "@chakra-ui/react";
import { usePageStore } from "../../../states/usePageStore";
import { useDragPhotoStore } from "../../../states/useDragPhoto";
import { useDragBackground } from "../../../states/useDragBackground";
import { useRef, useState, useEffect } from "react";
import { usePhotoEditor } from "../../../states/usePhotoEditor";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";
import { useZoomStore } from "../../../states/useZoomStore";
import { CONSTANTS } from "../../../configs/constants";
import PhotoPlaceholder from "../images/PhotoPlaceholder.png";
import { uploadPhoto } from "../../../utils/photo-uploader";
import { nanoid } from "nanoid";

export const Area = ({
    isEditable = true,
    spreadIndex,
    boxIndex,
    x,
    y,
    width,
    height,
    margins,
    canvasHeight,
    setShowRightSidebar,
    showGrid
}) => {
    const pageStore = usePageStore((state) => state);
    const photoEditor = usePhotoEditor((state) => state);
    const {
        draggedPhotoSrc,
        draggedPhotoOriginalSrc,
        draggedFilter,
        setDraggedPhoto,
        draggedStickerSrc
    } = useDragPhotoStore((state) => state);
    const dragBackground = useDragBackground((state) => state);
    const zoomStore = useZoomStore((state) => state);
    const [outlineSize, setOutlineSize] = useState(8);

    const boxRef = useRef();
    const outlineRef = useRef();
    const emptyPlaceholderRef = useRef();
    const northResizer = useRef();
    const southResizer = useRef();
    const westResizer = useRef();
    const eastResizer = useRef();
    const imageRef = useRef();

    const [isImageLoading, setIsImageLoading] = useState(false);
    const [editingImage, setEditingImage] = useState({});
    const [isEditMode, setIsEditMode] = useState(false);
    const [cropper, setCropper] = useState(null);
    const [outputImage, setOutputImage] = useState('');
    const [cropperData, setCropperData] = useState(false);
    const [filter, setFilter] = useState({});

    useEffect(() => {
        const photo = pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo;
        if (photo) {
            if (photo.filter) {
                setFilter(photo.filter);   
            } 
        }
    }, []);

    useEffect(() => {
        let dividend = 50;
        if (zoomStore.zoom <= 10) dividend = 30;

        setOutlineSize(8 / (zoomStore.zoom / dividend));
    }, [zoomStore.zoom]);

    useEffect(() => {
        const photo = pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo;
        if (photo) {
            pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo.cropperData = cropperData;
        }
    }, [cropperData])

    useEffect(() => {
        const photo = pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo;

        if (photo) {
            pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo = {
                originalSrc: photo.originalSrc || photo.src,
                editedOriginalSrc: photo.editedOriginalSrc,
                src: outputImage !== "" ? outputImage : (photo.src !== "" ? photo.src : photo.originalSrc),
                filter: photo.filter,
                cropperData: photo.cropperData,
                isSticker: photo.isSticker
            };
        }
    }, [outputImage]);

    useEffect(() => {
        const photo = pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo;

        if (photo) {
            pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo.filter = filter;
        }
    }, [filter]);

    let saveBoxTimeout;

    const saveBox = () => {
        if (saveBoxTimeout) {
            clearTimeout(saveBoxTimeout);
        }

        saveBoxTimeout = setTimeout(() => {
            const element = boxRef.current;
            if (element) {
                const box = pageStore.data.spreads[spreadIndex].boxes[boxIndex];

                const x = element.offsetLeft - margins.left;
                const y =
                    element.offsetTop -
                    margins.top -
                    (canvasHeight + CONSTANTS.CANVAS_SPACING) * spreadIndex;
                const width = element.offsetWidth;
                const height = element.offsetHeight;

                if (
                    box.x !== x ||
                    box.y !== y ||
                    box.width !== width ||
                    box.height !== height
                ) {
                    pageStore.setBox(
                        spreadIndex,
                        boxIndex,
                        x,
                        y,
                        element.offsetWidth,
                        element.offsetHeight,
                    );
                }
            }
        }, 500);
    }

    const handleSetPhoto = () => {
        if (pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo && pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo.isSticker) {
            setDraggedPhoto(null);
            return;
        }

        pageStore.setPhoto(spreadIndex, boxIndex, {
            src: draggedPhotoSrc,
            originalSrc: draggedPhotoOriginalSrc,
            filter: draggedFilter,
        });
        setDraggedPhoto(null);
    };

    const handleResize = (event, direction) => {
        event = event || window.event;
        event.preventDefault();

        let pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;

        pos3 = event.clientX;
        pos4 = event.clientY;

        const element = boxRef.current;
        const outlineElement = outlineRef.current;
        const emptyPlaceholderElement = emptyPlaceholderRef.current;

        const scaleFactor = 1 / (zoomStore.zoom / 100);

        handleBoxSelect(event);

        let newTop;
        let newHeight;

        let newLeft;
        let newWidth;

        const resizeFromNorth = (e) => {
            e = e || window.event;
            e.preventDefault();

            const newPos2 = pos4 - e.clientY;
            const newPos4 = e.clientY;

            pos2 = Math.round(newPos2 * scaleFactor);
            pos4 = newPos4;
            newTop = (element.offsetTop - (pos2));
            newHeight = (element.offsetHeight + (pos2));

            element.style.top =  `${newTop}px`;
            element.style.height =  `${newHeight}px`;

            outlineElement.style.top =  `${newTop}px`;
            outlineElement.style.height =  `${newHeight}px`;

            emptyPlaceholderElement.style.top =  `${newTop}px`;
            emptyPlaceholderElement.style.height =  `${newHeight}px`;

            northResizer.current.style.top =  `${newTop - outlineSize}px`;
            eastResizer.current.style.top = `${newTop - outlineSize}px`;
            westResizer.current.style.top = `${newTop - outlineSize}px`;
            southResizer.current.style.top = `${newTop + newHeight}px`;

            eastResizer.current.style.height = `${newHeight + (2 * outlineSize)}px`;
            westResizer.current.style.height = `${newHeight + (2 * outlineSize)}px`;
        };

        const resizeFromSouth = (e) => {
            e = e || window.event;
            e.preventDefault();

            const newPos2 = pos4 - e.clientY;
            const newPos4 = e.clientY;

            pos2 = Math.round(newPos2 * scaleFactor);
            pos4 = newPos4;
            newHeight = (element.offsetHeight - pos2);

            element.style.height =  `${newHeight}px`;
            outlineElement.style.height =  `${newHeight}px`;
            emptyPlaceholderElement.style.height =  `${newHeight}px`;

            southResizer.current.style.top =  `${element.offsetTop + newHeight}px`;
            eastResizer.current.style.height = `${newHeight + (2 * outlineSize)}px`;
            westResizer.current.style.height = `${newHeight + (2 * outlineSize)}px`;
        };

        const resizeFromWest = (e) => {
            e = e || window.event;
            e.preventDefault();

            const newPos1 = pos3 - e.clientX;
            const newPos3 = e.clientX;

            pos1 = Math.round(newPos1 * scaleFactor);
            pos3 = newPos3;
            newLeft = (element.offsetLeft - pos1);
            newWidth = (element.offsetWidth + Math.round(pos1));
            
            element.style.left = `${newLeft}px`;
            element.style.width =  `${newWidth}px`;

            outlineElement.style.left = `${newLeft}px`;
            outlineElement.style.width =  `${newWidth}px`;

            emptyPlaceholderElement.style.left = `${newLeft}px`;
            emptyPlaceholderElement.style.width =  `${newWidth}px`;

            westResizer.current.style.left =  `${newLeft - outlineSize}px`;
            northResizer.current.style.left =  `${newLeft - outlineSize}px`;
            southResizer.current.style.left =  `${newLeft - outlineSize}px`;
            eastResizer.current.style.left = `${newLeft + newWidth}px`;

            northResizer.current.style.width =  `${newWidth + (2 * outlineSize)}px`;
            southResizer.current.style.width =  `${newWidth + (2 * outlineSize)}px`;
        };

        const resizeFromEast = (e) => {
            e = e || window.event;
            e.preventDefault();

            const newPos1 = pos3 - e.clientX;
            const newPos3 = e.clientX;

            pos1 = Math.round(newPos1 * scaleFactor);
            pos3 = newPos3;
            newWidth = (element.offsetWidth - pos1);
            
            element.style.width =  `${newWidth}px`;
            outlineElement.style.width =  `${newWidth}px`;
            emptyPlaceholderElement.style.width =  `${newWidth}px`;

            eastResizer.current.style.left =  `${element.offsetLeft + newWidth}px`;
            northResizer.current.style.width =  `${newWidth + (2 * outlineSize)}px`;
            southResizer.current.style.width =  `${newWidth + (2 * outlineSize)}px`;
        };

        if (direction === 'north') {
            document.onmousemove = (e) => {
                resizeFromNorth(e);
            }
        } else if (direction === 'south') {
            document.onmousemove = (e) => {
                resizeFromSouth(e);
            }
        } else if (direction === 'west') {
            document.onmousemove = (e) => {
                resizeFromWest(e);

                saveBox();
            }
        } else if (direction === 'east') {
            document.onmousemove = (e) => {
                resizeFromEast(e);
            }
        } else if (direction === 'northwest') {
            document.onmousemove = (e) => {
                resizeFromNorth(e);
                resizeFromWest(e);
            }
        } else if (direction === 'northeast') {
            document.onmousemove = (e) => {
                resizeFromNorth(e);
                resizeFromEast(e);
            }
        } else if (direction === 'southwest') {
            document.onmousemove = (e) => {
                resizeFromSouth(e);
                resizeFromWest(e);
            }
        } else if (direction === 'southeast') {
            document.onmousemove = (e) => {
                resizeFromSouth(e);
                resizeFromEast(e);
            };
        }

        document.onmouseup = (e) => {
            if (showGrid) {
                const min = ((canvasHeight + CONSTANTS.CANVAS_SPACING) * spreadIndex);

                if (newTop) {
                    const topCheck = ((newTop - min) % 48);
                    if (topCheck <= 12 || topCheck > 36) {
                        if (topCheck <= 12 ) {
                            const topAdjustment = topCheck - 1;
                            newTop -= topAdjustment;
                            newHeight -= topAdjustment;
                        } else if (topCheck > 36) {
                            const topAdjustment = (48 - topCheck) + 1;
                            newTop += topAdjustment;
                            newHeight += topAdjustment;
                        }

                        element.style.top =  `${newTop}px`;
                        element.style.height =  `${newHeight}px`;

                        outlineElement.style.top =  `${newTop}px`;
                        outlineElement.style.height =  `${newHeight}px`;

                        emptyPlaceholderElement.style.top =  `${newTop}px`;
                        emptyPlaceholderElement.style.height =  `${newHeight}px`;

                        northResizer.current.style.top =  `${newTop - outlineSize}px`;
                        eastResizer.current.style.top = `${newTop - outlineSize}px`;
                        westResizer.current.style.top = `${newTop - outlineSize}px`;
                        southResizer.current.style.top = `${newTop + newHeight}px`;

                        eastResizer.current.style.height = `${newHeight + (2 * outlineSize)}px`;
                        westResizer.current.style.height = `${newHeight + (2 * outlineSize)}px`;
                    }
                } else if (newHeight) {
                    const topCheck = (((element.offsetTop + newHeight) - min) % 48);
                    if (topCheck <= 12 || topCheck > 36) {
                        if (topCheck <= 12 ) {
                            const topAdjustment = topCheck;
                            newHeight -= topAdjustment;
                        } else if (topCheck > 36) {
                            const topAdjustment = (48 - topCheck);
                            newHeight += topAdjustment;
                        }

                        element.style.height =  `${newHeight}px`;
                        outlineElement.style.height =  `${newHeight}px`;
                        emptyPlaceholderElement.style.height =  `${newHeight}px`;
            
                        southResizer.current.style.top =  `${element.offsetTop + newHeight}px`;
                        eastResizer.current.style.height = `${newHeight + (2 * outlineSize)}px`;
                        westResizer.current.style.height = `${newHeight + (2 * outlineSize)}px`;
                    }
                }

                if (newLeft) {
                    const leftCheck = (newLeft % 48);
                    if (leftCheck <= 12 || leftCheck > 36) {
                        if (leftCheck <= 12 ) {
                            const leftAdjustment = leftCheck - 1;
                            newLeft -= leftAdjustment;
                            newWidth -= leftAdjustment;
                        } else if (leftCheck > 36) {
                            const leftAdjustment = (48 - leftCheck) + 1;
                            newLeft += leftAdjustment;
                            newWidth += leftAdjustment;
                        }
                        element.style.left = `${newLeft}px`;
                        element.style.width =  `${newWidth}px`;

                        outlineElement.style.left = `${newLeft}px`;
                        outlineElement.style.width =  `${newWidth}px`;

                        emptyPlaceholderElement.style.left = `${newLeft}px`;
                        emptyPlaceholderElement.style.width =  `${newWidth}px`;

                        westResizer.current.style.left =  `${newLeft - outlineSize}px`;
                        northResizer.current.style.left =  `${newLeft - outlineSize}px`;
                        southResizer.current.style.left =  `${newLeft - outlineSize}px`;
                        eastResizer.current.style.left = `${newLeft + newWidth}px`;

                        northResizer.current.style.width =  `${newWidth + (2 * outlineSize)}px`;
                        southResizer.current.style.width =  `${newWidth + (2 * outlineSize)}px`;
                    }
                } else if (newWidth) {
                    const leftCheck = ((element.offsetLeft + newWidth) % 48);
                    if (leftCheck <= 12 || leftCheck > 36) {
                        if (leftCheck <= 12 ) {
                            const leftAdjustment = leftCheck;
                            newWidth -= leftAdjustment;
                        } else if (leftCheck > 36) {
                            const leftAdjustment = (48 - leftCheck);
                            newWidth += leftAdjustment;
                        }

                        element.style.width =  `${newWidth}px`;
                        outlineElement.style.width =  `${newWidth}px`;
                        emptyPlaceholderElement.style.width =  `${newWidth}px`;

                        eastResizer.current.style.left =  `${element.offsetLeft + newWidth}px`;
                        northResizer.current.style.width =  `${newWidth + (2 * outlineSize)}px`;
                        southResizer.current.style.width =  `${newWidth + (2 * outlineSize)}px`;
                    }
                }
            }

            saveBox();

            document.onmouseup = null;
            document.onmousemove = null;
        };
    };

    const handleResizeMobile = (event, el, direction) => {
        let touch = event.touches[0];

        let pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;

        pos3 = touch.pageX;
        pos4 = touch.pageY;

        const element = el.current;

        if (direction === "north") {
            document.ontouchmove = (e) => {
                touch = e.touches[0];

                const newPos2 = pos4 - touch.pageY;
                const newPos4 = touch.pageY;

                pos2 = newPos2;
                pos4 = newPos4;
                const newTop = element.offsetTop - pos2;
                const newHeight = element.offsetHeight + pos2;

                element.style.top = `${newTop}px`;
                element.style.height = `${newHeight}px`;

                northResizer.current.style.top = `${newTop}px`;
                eastResizer.current.style.top = `${newTop}px`;
                westResizer.current.style.top = `${newTop}px`;
                southResizer.current.style.top = `${newTop + newHeight - 5}px`;

                eastResizer.current.style.height = `${newHeight}px`;
                westResizer.current.style.height = `${newHeight}px`;
            };
        } else if (direction === "south") {
            document.ontouchmove = (e) => {
                touch = e.touches[0];

                const newPos2 = pos4 - touch.pageY;
                const newPos4 = touch.pageY;

                pos2 = newPos2;
                pos4 = newPos4;
                const newHeight = element.offsetHeight - pos2;

                element.style.height = `${newHeight}px`;

                southResizer.current.style.top = `${
                    element.offsetTop + newHeight - 5
                }px`;
                eastResizer.current.style.height = `${newHeight}px`;
                westResizer.current.style.height = `${newHeight}px`;
            };
        } else if (direction === "west") {
            document.ontouchmove = (e) => {
                touch = e.touches[0];

                const newPos1 = pos3 - touch.pageX;
                const newPos3 = touch.pageX;

                pos1 = newPos1;
                pos3 = newPos3;
                const newLeft = element.offsetLeft - pos1;
                const newWidth = element.offsetWidth + pos1;

                element.style.left = `${newLeft}px`;
                element.style.width = `${newWidth}px`;

                westResizer.current.style.left = `${newLeft}px`;
                northResizer.current.style.left = `${newLeft}px`;
                southResizer.current.style.left = `${newLeft}px`;
                eastResizer.current.style.left = `${newLeft + newWidth - 5}px`;

                northResizer.current.style.width = `${newWidth}px`;
                southResizer.current.style.width = `${newWidth}px`;
            };
        } else if (direction === "east") {
            document.ontouchmove = (e) => {
                touch = e.touches[0];

                const newPos1 = pos3 - touch.pageX;
                const newPos3 = touch.pageX;

                pos1 = newPos1;
                pos3 = newPos3;
                const newWidth = element.offsetWidth - pos1;

                element.style.width = `${newWidth}px`;
                eastResizer.current.style.left = `${
                    element.offsetLeft + newWidth - 5
                }px`;
                northResizer.current.style.width = `${newWidth}px`;
                southResizer.current.style.width = `${newWidth}px`;
            };
        }

        document.ontouchend = (e) => {
            document.ontouchend = null;
            document.ontouchmove = null;
            document.querySelector("#workspace").style.overflow = "scroll";
            document.body.style.overflow = "scroll";
        };
    };

    const handleDrag = (event) => {
        event = event || window.event;
        event.preventDefault();

        let pos1 = 0,
            pos2 = 0,
            pos3 = 0,
            pos4 = 0;

        pos3 = event.clientX;
        pos4 = event.clientY;

        const element = boxRef.current;
        const outlineElement = outlineRef.current;
        const emptyPlaceholderElement = emptyPlaceholderRef.current;

        let newLeft;
        let newTop;

        document.onmousemove = (e) => {
            e = e || window.event;
            e.preventDefault();

            const newPos1 = pos3 - e.clientX;
            const newPos2 = pos4 - e.clientY;
            const newPos3 = e.clientX;
            const newPos4 = e.clientY;

            const scaleFactor = 1 / (zoomStore.zoom / 100);

            pos2 = Math.round(newPos2 * scaleFactor);
            pos4 = newPos4;
            newTop = (element.offsetTop - pos2);
            element.style.top = `${newTop}px`;
            outlineElement.style.top = `${newTop}px`;
            emptyPlaceholderElement.style.top = `${newTop}px`;
            
            pos1 = Math.round(newPos1 * scaleFactor);
            pos3 = newPos3;
            newLeft = element.offsetLeft - pos1;
    
            element.style.left = `${newLeft}px`;
            outlineElement.style.left = `${newLeft}px`;
            emptyPlaceholderElement.style.left = `${newLeft}px`;

            northResizer.current.style.top = `${newTop - outlineSize}px`;
            northResizer.current.style.left = `${newLeft - outlineSize}px`;
            westResizer.current.style.top = `${newTop - outlineSize}px`;
            westResizer.current.style.left = `${newLeft - outlineSize}px`;
            eastResizer.current.style.top = `${newTop - outlineSize}px`;
            eastResizer.current.style.left = `${newLeft + element.offsetWidth}px`;
            southResizer.current.style.top = `${newTop + element.offsetHeight}px`;
            southResizer.current.style.left = `${newLeft - outlineSize}px`;
        }

        document.onmouseup = (e) => {
            const min = ((canvasHeight + CONSTANTS.CANVAS_SPACING) * spreadIndex);
            const max = ((canvasHeight + CONSTANTS.CANVAS_SPACING) * (spreadIndex + 1));

            if (showGrid) {
                const leftCheck = (newLeft % 48);
                if (leftCheck <= 12 || leftCheck > 36) {
                    if (leftCheck <= 12 ) {
                        newLeft -= leftCheck - 1;
                    } else if (leftCheck > 36) {
                        newLeft += (48 - leftCheck) + 1;
                    }
                    element.style.left = `${newLeft}px`;
                    outlineElement.style.left = `${newLeft}px`;
                    emptyPlaceholderElement.style.left = `${newLeft}px`;

                    northResizer.current.style.left = `${newLeft - outlineSize}px`;
                    westResizer.current.style.left = `${newLeft - outlineSize}px`;
                    eastResizer.current.style.left = `${newLeft + element.offsetWidth}px`;
                    southResizer.current.style.left = `${newLeft - outlineSize}px`;
                }

                const topCheck = ((newTop - min) % 48);
                if (topCheck <= 12 || topCheck > 36) {
                    if (topCheck <= 12 ) {
                        newTop -= topCheck -1;
                    } else if (topCheck > 36) {
                        newTop += (48 - topCheck) + 1;
                    }
                    element.style.top = `${newTop}px`;
                    outlineElement.style.top = `${newTop}px`;
                    emptyPlaceholderElement.style.top = `${newTop}px`;

                    northResizer.current.style.top = `${newTop - outlineSize}px`;
                    westResizer.current.style.top = `${newTop - outlineSize}px`;
                    eastResizer.current.style.top = `${newTop - outlineSize}px`;
                    southResizer.current.style.top = `${newTop + element.offsetHeight}px`;
                }
            }

            if (element.offsetTop >= min && element.offsetTop < max) {
            } else {
                let destSpread = Math.floor((element.offsetTop + CONSTANTS.CANVAS_SPACING) / (canvasHeight + CONSTANTS.CANVAS_SPACING));

                if (destSpread < 0) {
                    destSpread = 0;
                }
                if (destSpread !== spreadIndex) {
                    pageStore.transferContentToAnotherPage('boxes', spreadIndex, destSpread, boxIndex, element.offsetLeft - margins.left, (element.offsetTop - ((canvasHeight + CONSTANTS.CANVAS_SPACING) * destSpread) - margins.top));
                }
            }

            saveBox();

            document.onmouseup = null;
            document.onmousemove = null;
        };

        document.ontouchend = (e) => {
            document.ontouchend = null;
            document.ontouchmove = null;
            document.onmousemove = null;
            document.querySelector("#workspace").style.overflow = "scroll";
            document.body.style.overflow = "scroll";
        };
    };

    const handlePhotoClick = (event) => {
        event = event || window.event;
        event.preventDefault();

        const photo = pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo;
        if (photo.isSticker) return;
            
        if (photo && photo.src) {
            setEditingImage(photo);  
            setIsEditMode(true);
            photoEditor.enterEditMode(
                photo,
                (cropper) => {
                    setIsImageLoading(true);
                    const canvas = cropper.getCroppedCanvas();
                    setCropperData(cropper.getData());

                    canvas.toBlob(function (blob) { 
                        const file = blob;
                        const clientId = localStorage.getItem('clientId');
                        const key = `edited/${clientId}/photos/${nanoid()}`;
                        const fileType = `webp`;

                        uploadPhoto(file, key, fileType).then((data) => {
                            const editedPhoto = {
                                src: `${process.env.REACT_APP_S3_BUCKET_URL}/${data.s3Key}`,
                                editedOriginalSrc: `${process.env.REACT_APP_S3_BUCKET_URL}/${data.originalS3Key}`,
                            };
                            pageStore.setEditedPhoto(spreadIndex, boxIndex, editedPhoto);
                            setOutputImage(editedPhoto.src);
                            setIsImageLoading(false);
                        });
                    });

                    setEditingImage({});
                    setIsEditMode(false);
                    setCropper(null);
                },
                (filter) => {
                    pageStore.setUndoReset();
                    setFilter(filter);
                },
            );

            setTimeout(() => {
                const cropper = new Cropper(imageRef.current, {
                    viewMode: 1,
                    dragMode: "move",
                    autoCropArea: 1,
                    aspectRatio: width / height,
                    rotatable: true,
                    data: photo.cropperData ? photo.cropperData : {},
                    crop: () => {
                        photoEditor.setFilter();
                    },
                });

                setCropper(cropper);
                photoEditor.setCropper(cropper);
                setShowRightSidebar(true);
            }, 100);
        }
    };

    const handleBoxSelect = (event) => {
        pageStore.setBoxSelectedIndex(boxIndex);
        pageStore.setTextSelectedIndex(null);
        pageStore.setInitSelectedImagePhotoEditor(() => {
            if (handlePhotoClick) {
                handlePhotoClick(event);
            }
        });
    }

    const getCSSFilter = () => {
        const photo = pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo;
        if (photo?.filter) {
            const filter = photo.filter;
            return `brightness(${filter.brightness}%) saturate(${filter.saturation}%) contrast(${filter.contrast}%) opacity(${filter.opacity}%) sepia(${filter.sepia}%) grayscale(${filter.grayscale}%)`;
        } else {
            return `auto`;
        }
    };

    const getBorder = () => {
        const photo = pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo;
        if (photo?.filter && photo.filter.borderWidth) {
            return `${photo.filter.borderWidth}px solid ${photo.filter.borderColor}`;
        } else {
            return `0px solid #000000`;
        }
    }; 

    const getBoxShadow = () => {
        const photo = pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo;
        if (photo?.filter && photo.filter.boxShadow) {
            return `${photo.filter.boxShadow}px ${photo.filter.boxShadow}px ${photo.filter.boxShadowColor}`;
        } else {
            return `0px 0px #000000`;
        }
    }; 

    const getZIndex = () => {
        return pageStore.data.spreads[spreadIndex].boxes[boxIndex].zIndex !==
            undefined
            ? pageStore.data.spreads[spreadIndex].boxes[boxIndex].zIndex
            : 1;
    };

    const isHighlighted = () => {
        return (
            (pageStore.spreadSelectedIndex === spreadIndex && pageStore.boxSelectedIndex === boxIndex) ||
            (pageStore.layoutType !== 'photobook' && pageStore.spreadSelectedIndex === spreadIndex)
        );
    };

    const hasImage = () => {
        return (
            spreadIndex !== undefined &&
            boxIndex !== undefined &&
            pageStore.data.spreads[spreadIndex].boxes[boxIndex]?.photo?.src
        );
    };

    return (
        <>
            <div
                style={{
                    display: isEditMode ? "block" : "none",
                    height: cropper?.ready ? "0%" : "100%",
                }}
            >
                <Flex
                    style={{
                        height: `${height}px`,
                        width: `${width}px`,
                        top: `${y}px`,
                        left: `${x}px`,
                        position: "absolute",
                    }}
                    alignItems={"center"}
                    justify={"center"}
                >
                    <Spinner
                        size="xl"
                        style={{
                            display: cropper?.ready ? "none" : "block",
                        }}
                    />
                </Flex>
                <div
                    style={{
                        visibility: cropper?.ready ? "visible" : "hidden",
                        height: `${height}px`,
                        width: `${width}px`,
                        top: `${y}px`,
                        left: `${x}px`,
                        position: "absolute",
                        zIndex: 1005
                    }}
                >
                    <img
                        ref={imageRef}
                        style={{ height: `${height}px`, width: `${width}px` }}
                        src={editingImage.originalSrc || editingImage.src}
                    />
                    <img
                        style={{ visibility: "hidden", position: "absolute" }}
                        src={outputImage}
                    />

                    <div style={{ marginBottom: "12px" }}></div>
                </div>
            </div>

            {(!isEditMode && !isImageLoading) && (
                <>
                    <Box
                        ref={northResizer}
                        position={"absolute"}
                        height={`${outlineSize}px`}
                        zIndex={getZIndex()+1}
                        width={width + (2 * outlineSize)}
                        top={`${y - outlineSize}px`}
                        left={`${x - outlineSize}px`}
                        cursor={"ns-resize"}
                        onMouseDown={(event) => {
                            if (handleResize) {
                                handleResize(event, 'north');
                            }
                        }}
                        onTouchStart={(event) => {
                            if (handleResizeMobile) {
                                document.querySelector(
                                    "#workspace",
                                ).style.overflow = "hidden";
                                document.body.style.overflow = "hidden";
                                handleResizeMobile(event, boxRef, "north");
                            }
                        }}
                    >
                    </Box>

                    <Box
                        position={"absolute"}
                        height={`${2 * outlineSize}px`}
                        zIndex={3}
                        width={`${2 * outlineSize}px`}
                        top={`${y - outlineSize}px`}
                        left={`${x - outlineSize}px`}
                        cursor={"nwse-resize"}
                        onMouseDown={(event) => {
                            if (handleResize) {
                                handleResize(event, 'northwest');
                            }
                        }}
                    >
                    </Box>

                    <Box
                        position={"absolute"}
                        height={`${2 * outlineSize}px`}
                        zIndex={3}
                        width={`${2 * outlineSize}px`}
                        top={`${y - outlineSize}px`}
                        left={`${x + width - outlineSize}px`}
                        cursor={"nesw-resize"}
                        onMouseDown={(event) => {
                            if (handleResize) {
                                handleResize(event, 'northeast');
                            }
                        }}
                    >
                    </Box>

                    <Box
                        ref={westResizer}
                        position={"absolute"}
                        height={height + (2 * outlineSize)}
                        zIndex={getZIndex()+1}
                        width={`${outlineSize}px`}
                        top={`${y - outlineSize}px`}
                        left={`${x - outlineSize}px`}
                        cursor={"ew-resize"}
                        onMouseDown={(event) => {
                            if (handleResize) {
                                handleResize(event, 'west');
                            }
                        }}
                        onTouchStart={(event) => {
                            if (handleResizeMobile) {
                                document.querySelector(
                                    "#workspace",
                                ).style.overflow = "hidden";
                                document.body.style.overflow = "hidden";
                                handleResizeMobile(event, boxRef, "west");
                            }
                        }}
                    >
                    </Box>

                    <Box
                        outline={
                            isHighlighted()
                                ? `${outlineSize}px solid`
                                : hasImage()
                                ? "0px solid"
                                : "2px solid"
                        }
                        outlineColor={
                            isHighlighted() ? "wps.lightbeige" : "wps.lightgray"
                        }
                        position="absolute"
                        ref={outlineRef}
                        zIndex={getZIndex()}
                        top={`${y}px`}
                        left={`${x}px`}
                        h={height}
                        w={width}
                    >
                    </Box>

                    <Box
                        position="absolute"
                        ref={emptyPlaceholderRef}
                        zIndex={getZIndex()}
                        {...(!hasImage()
                            ? {
                                background: 'wps.lightgray',
                                backgroundImage: `url(${PhotoPlaceholder})`,
                                backgroundPosition: 'center',
                                backgroundSize: '70% auto',
                                backgroundRepeat: 'no-repeat',
                                opacity: '50%'
                            }
                        : {})}
                        
                        top={`${y}px`}
                        left={`${x}px`}
                        h={height}
                        w={width}
                    >
                    </Box>

                    <Box
                        position="absolute"
                        cursor="move"
                        ref={boxRef}
                        zIndex={getZIndex()}
                        className={`${boxIndex}`}
                        filter={getCSSFilter()}
                        border={getBorder()}
                        boxShadow={getBoxShadow()}
                        top={`${y}px`}
                        left={`${x}px`}
                        h={height}
                        w={width}
                        onDoubleClick={(event) => {
                            if (handlePhotoClick) {
                                handlePhotoClick(event);
                            }
                        }}
                        onTouchStart={(event) => {
                            document.querySelector(
                                "#workspace",
                            ).style.overflow = "hidden";
                            document.body.style.overflow = "hidden";
                            if (handleDrag) {
                                handleDrag(event, boxRef);
                            }
                        }}
                        onMouseDown={(event) => {
                            if (handleDrag && (pageStore.layoutType === 'photobook' || pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo?.isSticker)) {
                                handleDrag(event);
                            }

                            if (handleBoxSelect) {
                                handleBoxSelect(event);
                            }
                        }}
                        {...(isEditable
                            ? {
                                  onDragOver: (e) => e.preventDefault(),
                                  onDrop: () => {
                                      if (
                                          !dragBackground.draggedBackgroundSrc && !draggedStickerSrc
                                      ) {
                                          handleSetPhoto();
                                      }
                                  },
                              }
                            : {})}
                        {...(hasImage()
                            ? {
                                backgroundImage: pageStore.data.spreads[spreadIndex].boxes[boxIndex].photo.src,
                                backgroundPosition: "center",
                                backgroundSize: "cover",
                            }
                        : {})}
                    >
                    </Box>

                    <Box
                        ref={eastResizer}
                        position={"absolute"}
                        height={height + (2 * outlineSize)}
                        zIndex={getZIndex()+1}
                        width={`${outlineSize}px`}
                        top={`${y - outlineSize}px`}
                        left={`${x + width}px`}
                        cursor={"ew-resize"}
                        onMouseDown={(event) => {
                            if (handleResize) {
                                handleResize(event, 'east');
                            }
                        }}
                        onTouchStart={(event) => {
                            if (handleResizeMobile) {
                                document.querySelector(
                                    "#workspace",
                                ).style.overflow = "hidden";
                                document.body.style.overflow = "hidden";
                                handleResizeMobile(event, boxRef, "east");
                            }
                        }}
                    >
                    </Box>

                    <Box
                        position={"absolute"}
                        height={`${2 * outlineSize}px`}
                        zIndex={3}
                        width={`${2 * outlineSize}px`}
                        top={`${y + height - outlineSize}px`}
                        left={`${x - outlineSize}px`}
                        cursor={"nesw-resize"}
                        onMouseDown={(event) => {
                            if (handleResize) {
                                handleResize(event, 'southwest');
                            }
                        }}
                    >
                    </Box>

                    <Box
                        position={"absolute"}
                        height={`${2 * outlineSize}px`}
                        zIndex={3}
                        width={`${2 * outlineSize}px`}
                        top={`${y + height - outlineSize}px`}
                        left={`${x + width - outlineSize}px`}
                        cursor={"nwse-resize"}
                        onMouseDown={(event) => {
                            if (handleResize) {
                                handleResize(event, 'southeast');
                            }
                        }}
                    >
                    </Box>

                    <Box
                        ref={southResizer}
                        position={"absolute"}
                        zIndex={getZIndex()+1}
                        width={width + (2 * outlineSize)}
                        height={`${outlineSize}px`}
                        top={`${y + height}px`}
                        left={`${x - outlineSize}px`}
                        cursor={"ns-resize"}
                        onMouseDown={(event) => {
                            if (handleResize) {
                                handleResize(event, 'south');
                            }
                        }}
                        onTouchStart={(event) => {
                            if (handleResizeMobile) {
                                document.querySelector(
                                    "#workspace",
                                ).style.overflow = "hidden";
                                document.body.style.overflow = "hidden";
                                handleResizeMobile(event, boxRef, "south");
                            }
                        }}
                    >
                    </Box>
                </>
            )}

            {(!isEditMode && isImageLoading) && (
                <Flex
                    style={{
                        height: `${height}px`,
                        width: `${width}px`,
                        top: `${y}px`,
                        left: `${x}px`,
                        position: "absolute",
                    }}
                    alignItems={"center"}
                    justify={"center"}
                >
                    <Spinner
                        size="xl"
                        style={{
                            display: "block"
                        }}
                    />
                </Flex>
            )}
        </>
    );
};
