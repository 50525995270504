import { Flex, Text, useDisclosure } from "@chakra-ui/react";
import { Canvas } from "../Canvas";
import { ActionButtons } from "../Canvas/ActionButtons";
import { Projects } from "../LeftSidebar/TabDetails/Projects/Projects";
import { useRef, useState, useEffect } from "react";
import { useZoomStore } from "../../states/useZoomStore";
import { usePageStore } from "../../states/usePageStore";
import { CONSTANTS } from "../../configs/constants";
import { Walkthrough } from "../Walkthrough";

export const Workspace = ({ setShowRightSidebar, isProjectSaving }) => {
    const { zoom } = useZoomStore((state) => state);
    const workspaceRef = useRef();
    const pageStore = usePageStore((state) => state);
    const [outlineSize, setOutlineSize] = useState(16);
    const [showGrid, setShowGrid] = useState(false);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [showCreateProjects, setShowCreateProjects] = useState(0);

    useEffect(() => {
        if (!localStorage.getItem('walkthrough')) {
            onOpen();
            localStorage.setItem('walkthrough', true);
        }
    }, []);

    useEffect(() => {
        let dividend = 50;
        if (zoom <= 10) dividend = 30;

        setOutlineSize( 16 / (zoom / dividend));
    }, [zoom]);

    return (
        <Flex
            w="calc(200vw)"
            id="workspace"
            overflow="scroll"
            bg="wps.lightergray"
            minH="100%" 
            flexDirection={"column"}
            gap={2}
            position={"absolute"}
            top={"73px"}
            left={"0"}
            py={"36px"}
        >
            <ActionButtons openWalkthrough={onOpen} showGrid={showGrid} setShowGrid={setShowGrid} />
            <Flex marginTop="60px" flex={1}>
                <Flex
                    justify="center"
                    align="center"
                    w="full"
                    px={4}
                    ref={workspaceRef}
                    flexDirection={"column"}
                    onMouseDown={() => {
                        pageStore.setTextSelectedIndex(null);
                        pageStore.setBoxSelectedIndex(null);
                        pageStore.setSpreadSelectedIndex(null);
                        pageStore.setInitSelectedImagePhotoEditor(null);
                    }}
                >
                        <Flex
                            transform={`scale(${zoom}%)`}
                            transformOrigin={"50% 0%"}
                            flexDirection={"column"}
                        >
                            {pageStore.data?.spreads?.map((spread, i) => (
                                <>
                                    <Canvas spreadIndex={i} setShowRightSidebar={setShowRightSidebar} showGrid={showGrid} />
                                    <Flex 
                                        direction={"row"}
                                        height={`${CONSTANTS.CANVAS_SPACING}px`}
                                        justifyContent={"space-between"}
                                        alignItems={"top"}
                                        paddingTop={"6px"}
                                        paddingLeft={"6px"}
                                        paddingRight={"6px"}
                                        {...( i > 0 ? {width: `${pageStore.template.width}px`} : {})}
                                    >
                                        { (pageStore.layoutType === 'photobook' && !showGrid)  && (
                                            <>
                                            { i === 0 && (
                                                <>
                                                <Text fontSize={"24px"}> BACK COVER </Text>
                                                <Text fontSize={"24px"}> FRONT COVER </Text>
                                                </>
                                            )}
                                            { i > 0 && (
                                                <>
                                                <Text fontSize={"24px"}> {(i) + (i-1)} </Text>
                                                <Text fontSize={"24px"}> {(i) + i} </Text>
                                                </>
                                            )}
                                           </>
                                        )}
                                    </Flex>
                                </>
                            ))}
                        </Flex>
                </Flex>
            </Flex>
            <Projects isProjectSaving={isProjectSaving} showCreateProjects={showCreateProjects}></Projects>
            <Walkthrough isOpen={isOpen} onClose={() => {
                onClose();
                setShowCreateProjects(showCreateProjects+1);
            }}></Walkthrough>
        </Flex>
    );
};